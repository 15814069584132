/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.page--sitemap li > a {
  font-size: 14px;
  line-height: 24px;
}

.page--sitemap h2 {
  margin-top: 30px;
}