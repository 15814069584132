/* Global page, HTML5 setup, style reset */
html, body, p, h1, h2, h3, h4, h5, h6, form { padding:0; margin:0; }
img { border:none; }
article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary { display: block; }
audio, canvas, video { display: inline-block; }
datalist, template { display:none; }

*
{
  box-sizing: border-box;
}

