/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
html.pensioen3daagse .siteheader-menubar__content {
  padding-left: 210px;
}

@media (max-width: 879px) {
  .siteheader-identity--p3d {
    display: flex;
    align-items: center;
    width: 100%;
  }
  .siteheader-identity--logo-p3d img {
    display: block;
    height: 44px;
  }
  .siteheader-identity--logo-wig img {
    display: block;
    width: 110px;
  }
  .siteheader-identity--logo-p3d {
    order: 1;
  }
  .siteheader__p3d-dates {
    margin-left: 5%;
    order: 2;
  }
  .siteheader-identity__initiative {
    margin-left: auto;
    order: 3;
  }
  .siteheader-identity--p3d__label {
    color: #333333;
    font: 500 10px var(--rtd-heading-fontfamily);
    text-align: right;
  }
  .siteheader__p3d-dates {
    font: 600 19px/26px "TypoPRO Dosis";
    color: var(--color-purple);
    white-space: nowrap;
  }
  .siteheader__p3d-dates__day {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-left: 3px;
    margin-right: 3px;
    text-align: center;
    background-color: var(--color-purple);
    color: #FFFFFF;
    font-size: 18px;
    border-radius: 50%;
  }
  .siteheader__p3d-dates__day:first-child {
    margin-left: 0;
  }
}
@media (max-width: 450px) {
  .siteheader__p3d-dates {
    display: none;
  }
}
@media (min-width: 880px) {
  html.site--p3d .siteheader-menubar + .pageheader-bottomrow {
    margin-top: 80px;
  }
  html.site--p3d .siteheader-menubar + .pageheader {
    margin-top: 85px;
  }
  html.site--p3d .siteheader-menubar + .body__content {
    margin-top: 80px;
  }
  html.pensioen3daagse .siteheader-top__content {
    align-self: start;
  }
  .siteheader-identity--p3d {
    position: absolute;
    text-align: center;
    border: 1px solid #D2D2D2;
    border-radius: 0 0 8px 8px;
    top: -8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  }
  .siteheader-identity--p3d {
    width: 175px;
    padding: 18px 0 15px 0;
  }
  .siteheader-identity--logo-p3d img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
  }
  .siteheader-identity--logo-wig img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 150px;
  }
  .siteheader-top____right__initiative {
    margin-top: 15px;
  }
  .siteheader-top____right__initiative__label {
    text-decoration: none;
    text-align: right;
    font: 400 14px TypoPRO Dosis, Dosis, Arial;
    color: var(--color-grey-text-small);
  }
  .siteheader-top____right__initiative__link img {
    width: 195px;
  }
  .siteheader-identity--p3d__label {
    color: var(--color-purple);
    font: 500 13px var(--rtd-heading-fontfamily);
    margin-top: 10px;
    margin-bottom: 2px;
  }
  .siteheader__p3d-dates {
    position: absolute;
    left: 192px;
    bottom: 16px;
    font: 600 19px/26px "TypoPRO Dosis";
    color: var(--color-purple);
    white-space: nowrap;
  }
  .siteheader__p3d-dates__day {
    display: inline-block;
    width: 26px;
    height: 26px;
    margin-left: 3px;
    margin-right: 3px;
    text-align: center;
    background-color: var(--color-purple);
    color: #FFFFFF;
    font-size: 18px;
    border-radius: 50%;
  }
  .siteheader__p3d-dates__day:first-child {
    margin-left: 0;
  }
}