@import url(/.publisher/sd/minfin_wig/shared/assets/fontawesome5/css/all.css);
@import url(/.minfin_wig/fonts/@typopro/web-open-sans/typopro-opensans.css);
@import url(/.minfin_wig/fonts/@typopro/web-dosis/typopro-dosis.css);
@import url("../../../shared/web/fontawesome5-cssvars.css");

/*@import url(/.publisher/sd/minfin_wig/shared/fontawesome5-cssvars.css);*/

/*@import url("../../../shared/web/fontawesome5-cssvars.css"); - This works in RTD but not in the assetpack */


html
{
  --pagegrid-contentarea-width:     970px;
  --pagegrid-contentarea-xl-width: 1175px;
  --pagegrid-contentarea-width-full: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2); /* contentarea + sidepadding */

  --page-whitespace-top:         30px;
  --page-whitespace-bottom:      40px;
  /*$sidepadding: 15px;*/

  --site-transitions-duration: 0.2s;
  --button-transition-duration: 0.2s;



  --themecolor: var(--color-green);

  --color-green-highcontrast: #0A8539; /* green to use as background color when there is white text smaller than about 18.66px over it */

  --color-bg-green-highcontrast: #088638; /* also the color used for the footer */

  --color-green-border: #0db24c;

  --color-panel-background: #F5F5F5;






  --fontfamily-heading: "TypoPRO Dosis", Arial;
  --fontfamily-heading-offset: 0;

  --rtd-heading-fontfamily: var(--fontfamily-heading);
/*  --rtd-heading-fontweight: 500;*/
  --rtd-text-fontfamily:    Arial, "Sans-Serif";

  --rtd-text-color:         #333333;
  --rtd-heading-color:      var(--color-purple);

  --rtd-text-font: 16px/26px var(--rtd-text-fontfamily);
/*  --rtd-intro-font: 22px/28px var(--rtd-heading-fontfamily);*/
  --rtd-intro-font: 500 18px/28px var(--rtd-heading-fontfamily);
  --rtd-quote-font: 500 24px/32px var(--rtd-heading-fontfamily);

  --rtd-h1-font:   bold 30px/40px var(--rtd-heading-fontfamily);
  --rtd-h2-font:   bold 24px/28px var(--rtd-heading-fontfamily);
  --rtd-h3-font:   bold 22px/26px var(--rtd-heading-fontfamily);
  --rtd-h4-font:   bold 20px/26px var(--rtd-heading-fontfamily); /* checklist__item__title */

  --rtd-link-color: var(--color-green-small);


  --p3dtool-results-heading-font: var(--rtd-h4-font);



  --contentarea-width: 970px;
  --sidepadding: 15px;



  --widget-background: #FFFFFF;
  --widget-border-radius: 8px;

  --widget-border-color:             #D2D2D2;
  --widget-border-highlighted-color: #0DB24C; /* color for any widget with a border */

  --widget-box-shadow:  -1px 2px 3px rgba(0,0,0,0.15);
  /*
  Geldlessen 2025 redesign
  --widget-box-shadow:               3px 4px 4px 0 rgba(0,0,0,0.10);
  */

  --widget-box-highlighted-shadow:   0 0.5px 4px rgba(0, 0, 0, 0.35);

  --widget-title-color:        var(--color-purple);

  --widget-icon-space:         43px; /* amount of space to reserve for icons (for widgets such as checklist, links & downloads to keep their icons & titles aligned) */
  --widget-sidebar-icon-space: 26px; /* amount of space to reserve for icons (for widgets such as checklist, links & downloads to keep their icons & titles aligned) */

  --widget-padding-h: 13px;
  --widget-padding-v: 20px;
  --widget-padding-top: 20px;
  --widget-padding-bottom: 20px;

  --widget-icon-space:         43px; /* amount of space to reserve for icons (for widgets such as checklist, links & downloads to keep their icons & titles aligned) */
  --widget-sidebar-icon-space: 26px; /* amount of space to reserve for icons (for widgets such as checklist, links & downloads to keep their icons & titles aligned) */



  /* Accordion/FAQ widget */
  --widget-accordion-line-color:        #DADADA;
  --widget-accordion-items-title-color: var(--color-green);
  --widget-accordion-items-title-font:  600 20px/26px "TypoPRO Dosis", "Dosis", Arial;
  --widget-accordion-icon-color:        #641C76;

  /* Checklist widget */
  --widget-checklist-number-color:     var(--color-green);
  --widget-checklist-number-font:      700 17px/20px "TypoPRO Dosis", "Dosis", Arial;
  --widget-checklist-item-color:       #641C76;
  /*--widget-checklist-item-font:        700 20px/26px "TypoPRO Dosis", "Dosis", Arial;*/
  --widget-checklist-item-font:        var(--rtd-h4-font);

  /* Downloads widget embedded in the page content */
  --widget-download-item-padding-v:    6px;
  --widget-download-icon-color:        var(--color-green);
  --widget-download-icon-fontsize:     22px;
  --widget-download-title-color:       var(--color-green);
  --widget-download-title-font:        500 20px/26px var(--fontfamily-heading);
  --widget-download-description-color: #333333;
  --widget-download-description-font:  15px/26px var(--rtd-text-fontfamily);

  /* Downloads widget in the sidebar */
  --widget-sidebar-download-item-padding-v: 7px;
  --widget-sidebar-download-title-font:     600 16px/20px "TypoPRO Dosis", "Dosis", Arial;

  /* "Table of contents" widget */
  --widget-tableofcontents-header-color:      var(--color-green-large-on-light-background);
  --widget-tableofcontents-header-font:       var(--rtd-h2-font);
  --widget-tableofcontents-item-bullet-color: var(--button-color); /* #25A22F */
  --widget-tableofcontents-item-bullet-size:  16px;
  --widget-tableofcontents-item-color:        #333333;
/*  --widget-tableofcontents-item-font:         500 17px/28px "TypoPRO Dosis", "Dosis", Arial;*/
/*  --widget-tableofcontents-item-font:         500 16px/24px "TypoPRO Dosis", "Dosis", Arial;*/
  --widget-tableofcontents-item-font:         500 15.5px/21px "TypoPRO Dosis", "Dosis", Arial;
  --widget-tableofcontents-item-padding-v:    3px;

  /* bold 18.7px is required to make "ARC Toolkit" happy */
  --greenwhite-min-font:        bold 18.7px/20px "TypoPRO Dosis Buttons", "Dosis", Arial;

  /* was 16px/20px var(--fontfamily-heading); */
  --component-pagination-font:  var(--greenwhite-min-font);

  /*--menubar-mobile-font: 500 16px/20px "TypoPRO Dosis", "Dosis", Arial;*/
  --menubar-mobile-font:        var(--greenwhite-min-font);


  /************************************************************
   *
   *  Page components / Buttons
   */

  --button-borderradius:   8px;
  --button-color:          #13A538; /* #25A22F; */
  --button-color-hover:    #0B923E;
  /*--button-contrastcolor:     #FFFFFF;*/
  --button-fontfamily:     "TypoPRO Dosis Buttons", "Dosis", Arial;
  --button-font:           bold 20px/22px "TypoPRO Dosis Buttons", "Dosis", Arial;
  --button-padding:        5px 20px 5px 20px;
  --button-height:         40px;
  --button-disabled-backgroundcolor: #AAAAAA;
  --button-disabled-textcolor:       #666666;
  /*--button-text-alignment-tweak:  4px; / * because of Officina font */

  /* MinFin specific button additions */
  --button-icon-font:          900 13px "Font Awesome 5 Pro";
  --button-icon-backward:      var(--fa-arrow-left);
  --button-icon-forward:       var(--fa-arrow-right);
  --button-icon-search:        var(--fa-search);

  --textbutton-icon-font:      900 18px "Font Awesome 5 Pro";
  --textbutton-icon-backward:  var(--fa-chevron-left);
  --textbutton-icon-forward:   var(--fa-chevron-right);





  /************************************************************
   *
   *  Page components / Share bar
   */

  /*--component-sharepage-button-background:  linear-gradient(180deg, #FFFFFF 0%, #E7F7ED 100%);*/
  --component-sharepage-button-background:  #FFFFFF;
  --component-sharepage-button-color:       var(--color-green); /*#28A532;*/
  --component-sharepage-heading-color:      var(--color-green);
  --component-sharepage-heading-font:       20px/26px var(--fontfamily-heading);


  /* Footer */
  --footer-panel-background:                var(--color-green-background-highcontrast);



  /* top level layout */
  --whitespace-footer-content:           100px;

  /* contentarea layout */
  --rtd-spacing-paragraph:                15px;
  /*--rtd-spacing-intro-after:              30px;*/
  /*--rtd-spacing-h2-after:                 15px;*/
  --rtd-spacing-widget-before:            35px;
  --rtd-spacing-widget-after:             35px;
  /*--rtd-spacing-widget-with-title-before: 90px;*/
  /*--rtd-spacing-widget-opaque-before:     50px;*/
  /*--rtd-spacing-widget-opaque-after:      50px;*/
}

@media (max-width: 639px)
{
  html
  {
    --rtd-h1-font:    bold 24px/26px "TypoPRO Dosis", Arial;
    --rtd-h2-font:    bold 21px/24px "TypoPRO Dosis Menu", 'TypoPRO Dosis', Arial;
    --rtd-h3-font:     600 18px/24px "TypoPRO Dosis", 'TypoPRO Dosis', Arial;
    --rtd-h4-font:     600 17px/26px "TypoPRO Dosis", 'TypoPRO Dosis', Arial;

    --rtd-intro-font: 17px/24px var(--rtd-heading-fontfamily);
    --rtd-text-font:  16px/24px var(--rtd-text-fontfamily);

    --rtd-quote-font: var(--rtd-text-font);

    --p3dtool-results-heading-font: 600 19px/22px "TypoPRO Dosis", 'TypoPRO Dosis', Arial;;
  }
}

@media (max-width: $minwidth-layout-tablet - 1px)
{
  :root
  {
    --sidepadding: 10px;
    --pagegrid-gutteroutside-mobile: 10px; /* used by components-fo/filteredoverview/teaserbar.scss */
  }
}



:where( html.wh-widgetpreview
      , .widgetsgrid
/*      , .body__widgetsbottom*/
      , .widgets_column)
      :where(.widget:not(.widget-debugwidget))
{
  border: 1px solid var(--widget-border-color);
  border-radius: var(--widget-border-radius);
  background-color: #FFFFFF;
}


/* Widget grids with a white or light background */
:where(html.wh-widgetpreview
      , .widgetsgrid--transparent
/*      , .body__widgetsbottom*/
      , .widgets_column)
      :where(.widget:not(.widget-debugwidget))
{
  box-shadow: var(--widget-box-shadow);
}




html
{
  font: 12px/20px Arial, Sans-Serif;
  background-color: #FFFFFF;
  color: #333333;
}


a
{
  color: var(--rtd-link-color);
  outline-offset: var(--actionbutton-outline-offset);
}

h1
{
  font: var(--rtd-h1-font);
  color: var(--rtd-heading-color);
}

  h2
, .wh-popup-body .title
{
  font: var(--rtd-h2-font);
  color: var(--rtd-heading-color);
}

h3
{
  font: var(--rtd-h3-font);
  color: var(--rtd-heading-color);
}

h4
{
  font: var(--rtd-h4-font);
  color: var(--rtd-heading-color);
}

blockquote.quote
{
  display: flex;

  font: var(--rtd-quote-font);
  color: var(--rtd-heading-color);

  padding: 0;
}

blockquote.quote::before
{
  flex:  none;
  content:  "";
  border-left:  1px solid #641C76;
  margin-left:  40px; /* sidepadding + extra space */
  margin-right: 15px;
}


  html
, .wh-rtd-embeddedobject /* otherwise Webhare will override our html font-size */
, p.normal
, ol.ordered
, ul.unordered
, .embeddedobject
, .widget .description
{
  font: var(--rtd-text-font);
}

p.intro
{
  font: var(--rtd-intro-font);
}



sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}



.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}



/* lists

   Keep specificity higher than ".layout_tall .tallcontent > *"
 */
  ul.unordered.unordered
, ol.ordered.ordered
{
  padding-left:  50px;

  padding-bottom: 0;
  padding-top: 0;
  line-height: 160%;
}


ul.unordered ::marker
{
  color:     var(--color-green-bright);
  font-size: 20px;
}

ol.ordered ::marker
{
  color:     var(--color-green-bright);
  font-size: 14px;
}


/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
  padding: 5px 10px;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
}
.wh-rtd__table tr > *
{
  border-bottom: 1px solid #000000;
}
.wh-rtd__table .wh-rtd--hascolheader > *
{
  border-bottom: 2px solid #000000;
}



h1.heading1
{
  margin-top: 40px;
}

h2.heading2
{
  margin-top: 35px;
}

h3.heading3
{
  margin-top: 30px;
}

h4.heading4
{
  margin-top: 20px;
}

  .widget
, .embeddedobject
, .wh-rtd-embeddedobject /* wrapper of the preview */
{
  margin-top: var(--rtd-spacing-widget-before);
}

  .widget + *
, .embeddedobject + *
{
  margin-top: var(--rtd-spacing-widget-after);
}

  .wh-video
, p.intro
, p.description
, p.normal
, ul.unordered
, ol.ordered
/*, .widget-richcontent*/
, .widget--foldablecontent
{
  /* when changing this value also change $paragraph_space_between in config.scss */
  margin-top: var(--rtd-spacing-paragraph);
  margin-bottom: 0;
}

  h2.heading2 + .wh-video
, h2.heading2 + p.intro
, h2.heading2 + p.description
, h2.heading2 + p.normal
, h2.heading2 + ul.unordered
, h2.heading2 + ol.ordered
{
  margin-top: 7px;
}

.rtdcontent
{
  overflow-wrap: break-word; /* force line to break if there's no normal place (space/zero-width space/soft hyphen) to break a line */
}

/* any RTD content (descriptions, column content) */
  .wh-rtd > *:first-child
, .richdoc > *:first-child
, .rtdcontent > *:first-child
, .wh-form__richtext > *:first-child
, .wh-rtd-embeddedobject__preview > *:first-child /* wrapper of the widget inside of the embeddedobject editor */
{
  margin-top: 0;
}

  .wh-rtd > *:last-child
, .richdoc > *:last-child
, .rtdcontent > *:last-child
, .wh-form__richtext > *:last-child
{
  margin-bottom: 0;
}

.embeddedobject.ctablocks h3
{
  font: 600 30px/36px 'TypoPRO Dosis', Arial;
  margin-bottom: 15px;
}

.embeddedobject > h3
{
  margin: 0 0 15px 0;
}

img.wh-rtd-img
{
  max-width: 100%; /* in mobile/tablet mode content must scale to viewport */
  height: auto;
}
