/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
.body__content > .filteredoverview__feedbackandviewbar {
  width: 100%;
  max-width: var(--pagegrid-contentarea-width-full);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}

.newsoverview__results .notmatching,
.newsoverview__results .notcurrentpage {
  display: none;
}

.newsoverview__results .notmatching + hr {
  display: none;
}

.newsoverview__results .neoitem {
  padding: 10px 0 10px 0;
}
.newsoverview__results hr {
  margin-top: 15px;
  margin-bottom: 15px;
  border: 0;
  border-top: 1px solid var(--grey-darker-border);
}

.page--neo-overview .body__widgetsbottom {
  margin-top: 80px;
}

.neoitem__title {
  margin-top: 0;
}

.neoitem__title,
.neoitem__title a {
  color: var(--rtd-heading-color);
  font: var(--rtd-h4-font);
  text-decoration: none;
}

.neoitem__metabar {
  margin-bottom: 7px;
  display: flex;
  column-gap: 20px;
  color: var(--themecolor);
  font: 13px var(--rtd-text-fontfamily);
}

.neoitem__metabar__when::before {
  margin-right: 5px;
  content: var(--fa-calendar-alt);
  font: var(--fontawesome-regular);
  font-size: 16px;
}

.neoitem__metabar__location::before {
  margin-right: 5px;
  content: var(--fa-map-marker-alt);
  font: var(--fontawesome-regular);
  font-size: 16px;
}