/*@import "../shared/forms/mixins.scss";*/

/*
Widgets can be in:
1. widget column
2. main content (floating right)
3. widgets grid (on home) in 1/2/3 columns
4. widgets grid (on over ons) in 1/2 columns
*/

html.wh-widgetpreview body
{
  padding: 20px;
}


main.layout_wide > .widgets_column.empty
{
  display: none;
}


.widget--widgetsgroup.widget--widgetsgroup
{
  width: auto;
  padding: 0;
}


/* last user of .ctabackground is the Adfiz widget ? */
.widget .ctabackground
{
  background-color: #642382;
  color: #FFFFFF;
}
.ctabackground .widget__title
{
  color: #FFFFFF;
}



html.widgetpreview body
{
  margin: 20px;
  padding: 0;
}
main.widgetpreview
{
  margin: 0;
  padding: 0;
}
  main.widgetpreview .widget
, .wh-widgetpreview .widget
, .wh-widgetpreview .embeddedobject
{
  width: 280px;
}

  main.widgetpreview .widget.themesgrid
, .wh-widgetpreview .widget.themesgrid
{
  width: 882px;
}



/* We specifically target widgets in places outside the RTD content area
   to have a shadow edge */
  .widgets_column .widget
, .widgetsgrid .widget
, html.widgetpreview .widget /* preview in Publisher */
{
  border-top: 1px solid #EEEEEE;
  border-right: 1px solid #D2D2D2;
  border-bottom: 1px solid #A2A2A2;
  border-left: 1px solid #CCCCCC;
  border-radius: var(--widget-border-radius);
  box-shadow: var(--widget-box-shadow);

  background-color: var(--widget-background);
  color: #333333;
  font: normal 15px/20px "Arial";

  position: relative;

  background-size: contain;
  background-position: bottom right;
  background-repeat: no-repeat;
}
.widget.share
{
  background-size: auto;
}

  .widget.custom_nieuwsbrief
, .widget.custom_weekvanhetgeld
, .widget.downloads
, .widget.share
, .widget.links
, .widget.news_links
, .widget.news_summary
, .widget.pageindex  /* optional per page */
, .widget.quicklinks /* home header */

, .widget.theme_tools
, .widget.theme_relevant
, .widget.theme_actueel
{
  padding: 20px 18px;
}




  .widget > .title
, .widget > .widget_body > .title
, .widget__title
{
  color: #642382;
  /*font: 600 22px/24px 'TypoPRO Dosis';*/
  font: var(--rtd-h3-font);
  margin-bottom: 12px
}



/** Widget 'Anchor' *******************************************************************/

.widget--anchor
{
  display: block;

  /* offset to make sure anchor's within the page
     scroll a little further so the indended startposition
     doesn't start under the fixed header
  */
  position: relative;
  top: -20px;

  height: 0;
  margin: 0;
  padding: 0;

  /* border: 1px solid rgba(255, 0, 0, 0.25); */
}

.widget--anchor--preview
{
  font-size: 20px;
  color: #000000;
  padding: 0 20px;
  text-align: center;
}





.widget.custom_doenvoorpensioen .title
{
  padding: 20px 18px;
}

.widget.question_answer
{
  padding: 25px 15px 20px;
}
.widget.question_answer .question
{
  padding: 15px 15px 20px;
  background-color: #0cb24c;
  border-radius: 8px;
  color: #fff;
  position: relative;
  margin-bottom: 45px;
  font: 600 21px/27px 'TypoPRO Dosis', Arial;
}
.widget.question_answer .angle
{
  display: block;
  position: absolute;
  left: 30px;
  top: 100%;
  width: 0;
  height: 0;
  border-bottom: 35px solid transparent;
  border-left: 45px solid #0cb24c;
}
.widget.question_answer .getanswer
{
  /*@include clearbuttonelementstyling;*/
  background: none;
  padding: 0;
  border: 0;
  -webkit-appearance: none;

  display: flex;

  color: #333;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.widget.question_answer .getanswer::before
{
  display: none;
}
.widget.question_answer .getanswer:hover
{
  text-decoration: underline;
}
.widget.question_answer .getanswer > .fa
{
  position: relative;
  top: 2px;
  padding-right: 5px;
  color: #0cb24c;
  font-size: 20px;
}
.widget.question_answer > .question_answer_popup
{
  display: none;
}
.question_answer_popup
{
  width: 380px; /* ideally we want this width */
  max-width: 100%; /* if 380px however happens to be too large (mobile screen), than limit the width */
}
.question_answer_popup .links
{
  margin-top: 10px;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
}
.question_answer_popup .links a
{
  display: block;
  position: relative;
  padding-left: 35px;
  color: #0cb24c;
  text-decoration: none;
  font: 600 19px/24px 'TypoPRO Dosis', Arial;
}
.question_answer_popup .links a > .fa
{
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 21px;
  text-align: center;
  padding-top: 3px;
  background-color: #0cb24c;
}
.question_answer_popup .links a > .note
{
  color: #767676;
  display: inline-block;
  position: relative;
  top: -3px;
  font: 14px/20px Arial, Sans-Serif;
}

.widget.faq
{
  padding: 12px 18px 20px;
}

.widget.faq .title
{
  text-align: left;
  padding-bottom: 15px;
}
.widget.faq
{
  text-align: center;
  background: #ffffff url(../web/img/widgets/bgbottom.png) 0 100% repeat-x;
}
.widget.faq .question:before
{
  display: inline-block;
  content: "\0022";
  margin-right: -3px;
}
.widget.faq .question:after
{
  display: inline-block;
  content: "\0022";
  margin-left: -3px;
}
.widget.faq .question
{
  background-color: #e7f7ed;
  font: 400 24px/29px 'TypoPRO Dosis', Arial;
  color: #0db24c;
  padding: 20px 10px;
  border-radius: 10px;
  margin-bottom: 25px;
  position: relative;
}
.widget.faq .question .angle
{
  width: 0;
  height: 0;
  border-top: 20px solid #e7f7ed;
  border-left: transparent solid 20px;
  border-right: transparent solid 10px;
  position: absolute;
  top: 100%;
  left: 90px;
}


  .widget.pageindex
, .widget.followus /* hardcoded on home page */
{
  font: normal 14px/24px Arial;
}



/*********************************************

Generic lists

    Use this for a list:
      <ul class="linkslist">
        <li>...</li>
      </ul>

    Or for a single item:
      <span class="witharrow"><span class="text"></span></span>

    !! Keep the title/content in the line on the same line,
       otherwise an empty space character will appear between the arrow (:before) and
       the first line of the title

*********************************************/

ul.linkslist
{
  margin: 0;
  padding: 0;
}

/* in case of menu's with an opaque background (like the p3d mainmenu)
   we want the items/hitarea to occupy the full width
*/
ul.linkslist.links_fullwidth a
{
  display: inline-block;
  width: 100%;
}


/* <span> for video activation or <a> */
.witharrow .text
{
  vertical-align: middle;
}

  .linkslist li
, .witharrow
{
  padding: 0 0 0 0;
  margin: 0;
  list-style: none;

  display: flex;
  align-items: baseline;
}

  .linkslist li:before
, .linkslist li .icon /* for a list with pageicons -> .linkslist.withicons */
, .witharrow:before /* <a> or <span> */
{
  display: inline-block;
  vertical-align: middle;

  flex: none;

  font: var(--fontawesome-solid);
  content: var(--fa-chevron-right);
  font-size: 16px;
  color: var(--color-green);

  width: 19px;
}



.linkslist.greenbg > li:before
{
  color: #FFFFFF;
}

.linkslist.withicons li
{
  padding-left: 32px;
}

/* a list which uses page icons
   Tegenwoordig (2024) wordt dit alleen gebruikt als je de
   "Kies eigen links ipv tag gebaseerde relevante pagina's"
   hebt aangevinkt.

   In witty onder:
   wijzeringeldzaken.witty#relatedtotheme_content
   [if quicklinks]
*/
.linkslist.withicons li:before
{
  display: none;
}
.linkslist.withicons li .icon
{
  margin-left: -24px;
  left: -8px;

  width: 32px;
  height: 32px;
  background-size: cover;

  margin-top: -10px;
  position: relative;
  top: 10px;
}



.linkslist li + li
{
  margin-top: 10px;
}

.linkslist li a
{
  color: #000000;
  text-decoration: none;
  vertical-align: middle;
}
.widget .linkslist li a
{
  color: #333333;
}


.linkslist li a:hover
{
  text-decoration: underline;
}

/* second level (ul.linkslist is the first level) */

.linkslist ul
{
  margin-left: 10px;
  padding: 0;
  line-height: 100%;
}

.linkslist ul li:before
{
  background-image: none;
  width: auto;
  height: auto;
  content: "-";
  color: #000000;
}
.widget .linkslist ul li:before
{
  color: #333333;
}
.linkslist.greenbg ul li:before
{
  background-image: none;
  color: #FFFFFF;
}




/*********************************************

Styling for each specific widget

*********************************************/

.widget.custom_doenvoorpensioen
{
  overflow: hidden;
}
.widget.custom_doenvoorpensioen .title
{
  position: relative;
  z-index: 1;
}
.widget.custom_doenvoorpensioen .centeredbody
{
  display: table;
  position: relative;
  margin: -45px auto 0 auto;
}
.widget.custom_doenvoorpensioen .centeredbody img
{
  display: block;
}
.widget.custom_doenvoorpensioen .sign
{
  position: absolute;
  left: 9px;
  top: 103px;

  width: 204px;
  height: 126px;
  line-height: 126px;

  text-align: center;
}
.widget.custom_doenvoorpensioen form
{
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  color: #FFFFFF;
/*  font: normal 18px/100% 'TypoPRO Dosis'; */
  font: normal 16px/100% "Arial";
}
.widget.custom_doenvoorpensioen form .birthdate
{
  margin: 10px 0 10px 0;
}
.widget.custom_doenvoorpensioen form input
{
  width: 50px;
  border-radius: 0;
}
.widget.custom_doenvoorpensioen form input + input
{
  margin-left: 9px;
}


/**/

  .widget.custom_nieuwewetgeving
, .widget.custom_checkaowleeftijd
, .widget.custom_reisverzekering
{
  overflow: hidden;
  font: normal 18px 'TypoPRO Dosis';
}
  .widget.custom_nieuwewetgeving .widget__title
, .widget.custom_checkaowleeftijd .title
, .widget.custom_reisverzekering .title
{
  position: relative;
  z-index: 1;
  padding: 20px 18px 0;
  font: 600 24px/30px 'TypoPRO Dosis';
}

  .widget.custom_nieuwewetgeving .line1
, .widget.custom_reisverzekering .line1
{
  position: relative;
  z-index: 1;
  margin-top: -10px;
  padding: 0 18px;
}
.widget.custom_nieuwewetgeving .line2
{
  position: relative;
  z-index: 1;
  padding: 10px 18px 0;
}
/*  .widget.custom_nieuwewetgeving .centeredbody*/
  .widget.custom_checkaowleeftijd .centeredbody
, .widget.custom_reisverzekering .centeredbody
{
  display: table;
  position: relative;
  margin: -30px auto 5px;
}




/*
https://www.wijzeringeldzaken.nl/bibliotheek-/widgets/custom-widgets/wat-verandert-er-nog-meer-voor-jou/
https://site.kodos.sf.webhare.nl/wig/bibliotheek-/widgets/custom-widgets/wat-betekent-dit-voor-mij/
*/
.widget.custom_nieuwewetgeving
{
  display: grid;
  padding-bottom: 15px;
}
.widget--custom_nieuwewetgeving__background
{
  grid-column: 1;
  grid-row: 1;
  width: 115%;
  align-self: end;
  justify-self: end;

  margin-right: -30px;
  margin-bottom: 36px;
}
.custom_nieuwewetgeving__content__heading
{
  background: linear-gradient(to bottom, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
  padding-bottom: 35px;
}
  .widget.custom_nieuwewetgeving .title
, .widget.custom_nieuwewetgeving .line1
, .widget.custom_nieuwewetgeving .line2
{
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);
}
.widget.custom_nieuwewetgeving .line2
{
  padding: 0 18px 0;
}
  .widget.custom_nieuwewetgeving .line1
, .widget.custom_nieuwewetgeving .line2
{
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.8);
}
.custom_nieuwewetgeving__content
{
  grid-column: 1;
  grid-row: 1;

  background-image: url("../web/img/widgets/portemonnee-2019-origineel2.jpg");
  background-repeat: no-repeat;
  background-size: 115%;
  background-position: 0 calc(100% - 45px);
}

html.layout_mobile .custom_nieuwewetgeving__content
{
  background-size: 100%;
  background-position: 0 100%;
}

.custom_nieuwewetgeving__sign
{
  margin-top: 75px;
  margin-left: 18px;
  margin-right: 18px;

  display: table;
  /*background-color: #642382;*/
  background-color: rgba(100, 35, 130, 0.95);
  padding: 15px;
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.35);
}




.widget.custom_nieuwewetgeving .centeredbody > img
{
  padding: 40px 0 0 8px;
  display: block;
}
.widget.custom_checkaowleeftijd .centeredbody > img
{
  padding: 0 0 0 8px;
  display: block;
}
/*  .widget.custom_nieuwewetgeving .sign*/
  .widget.custom_checkaowleeftijd .sign
, .widget.custom_reisverzekering .sign
{
  position: absolute;
  left: 9px;
  top: 103px;

  width: 204px;
  height: 126px;
  line-height: 126px;
}
  .widget.custom_nieuwewetgeving .sign
, .widget.custom_checkaowleeftijd .sign
{
  text-align: center;
}
.widget.custom_checkaowleeftijd .sign
{
  top: 112px;
}
  .widget.custom_nieuwewetgeving form
, .widget.custom_checkaowleeftijd form
, .widget.custom_reisverzekering form
{
  display: inline-block;
  vertical-align: middle;
  text-align: left;

  color: #FFFFFF;
/*  font: normal 18px/100% 'TypoPRO Dosis'; */
  font: normal 16px/100% "Arial";
}
  .widget.custom_nieuwewetgeving form .birthdate
, .widget.custom_checkaowleeftijd form .birthdate
{
  margin: 10px 0 10px 0;
}
  .widget.custom_nieuwewetgeving form input
, .widget.custom_checkaowleeftijd form input
{
  width: 50px;
  border-radius: 0;
  border: 0 !important; /* no visible border needed, we don't want accessibility checkers to think there's no good contrast */
}
  .widget.custom_nieuwewetgeving form input + input
, .widget.custom_checkaowleeftijd form input + input
{
  margin-left: 9px;
}


.widget.custom_reisverzekering .centeredbody > img
{
  padding: 40px 0 0 8px;
  display: block;
}
.widget.custom_reisverzekering .sign
{
  top: 105px;
  left: 24px;
}

.widget.custom_reisverzekering .radiogroup
{
  margin: 10px 0;
  font-size: 14px;
  line-height: 18px;
}

.custom_reisverzekering .radiogroup > .option
{
  display: flex;
  align-items: center;
}

.custom_reisverzekering .radiogroup > .option + .option
{
  margin-top: 8px;
}

.custom_reisverzekering .radiogroup > .option > label + label
{
  padding-left: 15px;
}




.widget.custom_reisverzekering .question
{
  font: normal 18px 'TypoPRO Dosis';
}
/*
.widget.custom_reisverzekering label
{
  display: block;
  position: relative;
  padding: 2px 0 0 23px;
}
*/


/**/


.widget.custom_nieuwsbrief
{
}
.widget.custom_nieuwsbrief form
{
  margin: 30px 0 0 0;
}
.widget.custom_nieuwsbrief form input[name="email"]
{
  width: 100%;
  margin-bottom: 4px;
}




.widget.default
{
  overflow: hidden;
  position: relative;
}
.widget.default img.widgetimage
{
  width: 100%;
  padding: 0 3px;
  margin: 3px 0 0 0;

  background-color: #FFFFFF;
  border-radius: 9px 9px 0 0;
}
.widget.default.imageasbackground img
{
  position: absolute;
  right: 0;
  bottom: 0;

  width: auto;
  padding: 0;
}
.widget.default .widget_body
{
  position: relative;
/*  padding: 12px 19px 20px 19px;*/
  padding: 12px var(--widget-padding-h) 20px vaR(--widget-padding-h);
}

/* draaiboek */
.widget.draaiboek
{
  position: relative;
  background: #fff url(../web/img/widgets/bgbottom.png) 0 100% repeat-x;
}
.widget.draaiboek .widget_body
{
  position: relative;
  padding: 12px 19px 20px 19px;
  background: transparent none 100% 100% no-repeat;
}
.widget.draaiboek .description:after
{
  clear: both;
  content: '';
  display: block;
}
.widget.draaiboek .downloads
{
  list-style-type: none;
  margin: 0;
  padding: 0 0 19px;
}
.widget.draaiboek .downloads > li
{
  border-top: 1px solid #d4d4d4;
  border-bottom: 1px solid #d4d4d4;
  display: block;
  padding: 8px 19px;
}
.widget.draaiboek .downloads > li + li
{
  border-top: 0 none;
}
.widget.draaiboek .downloads .download
{
  display: block;
  padding-left: 10px;
  float: none;
  font: 300 17px/24px 'TypoPRO Dosis', Arial;
}
.widget.draaiboek a:hover
{
  text-decoration: underline;
}
.widget.draaiboek .downloads .download:before
{
  margin-top: -5px;
}

/* tools */
.widget.tools
{
  overflow: hidden;
  position: relative;
}
.widget.tools .widget_body
{
  position: relative;
  padding: 12px 19px 90px 19px;
}
.widget.tools .background
{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 72px;
  left: 0;
  background-position: 100% 100%;
  background-repeat: no-repeat;
}
.widget.tools .description:after
{
  clear: both;
  content: '';
  display: block;
}
.widget.tools .link
{
  height: 72px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff url(../web/img/widgets/bgtoolslink.png) 0 0 repeat-x;
  vertical-align: middle;
  white-space: nowrap;
}
.widget.tools .link .helper
{
  display: inline-block;
  height: 100%;
  width: 1px;
  vertical-align: middle;
}
.widget.tools .link a.witharrow:before
{
  top: -3px;
}
.widget.tools .link a
{
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  text-decoration: none;
  font: normal 18px/22px 'TypoPRO Dosis', Arial;
  color: #333;
  padding: 5px 19px 5px 19px;
}
.widget.tools .link a:hover
{
  text-decoration: underline;
}


/* slideshare */
.widget--slideshare
{
  padding-bottom: 50px;
}

.widget--slideshare .widget__body
{
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}

.widget--slideshare .widget__title
{
  padding: 12px 19px 0 19px;
}
.widget--slideshare__embed
{
  position: relative;
  width: 100%;
}
.widget--slideshare__embed:after
{
  display: block;
  content: '';
  padding-top: 83.5%;
}
.widget--slideshare__embed > div
{
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.widget--slideshare__embed iframe
{
  display: block;
  width: 100%;
  height: 100%;
}
.widget--slideshare__downloads
{
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 19px 10px 19px;
  height: 50px;
}
.widget--slideshare__downloads a
{
  display: inline-flex;
  align-items: center;

  text-decoration: none;
  color: #333;
  height: 30px;
  font: normal 16px/30px 'TypoPRO Dosis', Arial;
  background: transparent none 0px 1px no-repeat;
}
.widget--slideshare__downloads a:hover
{
  text-decoration: underline;
}
.widget--slideshare__downloads a .fa
{
  color: #0db24c;
  font-size: 24px;
  margin-right: 10px;
}


  .widget.downloads .linkslist li
{
  padding-left: 30px;
}
  .widget.downloads .linkslist li:before
{
  position: relative;
  margin-left: -22px;
  left: -8px;

  width: 22px;
  height: 20px;
  background: transparent url("../web/img/icons/widget-download.png") no-repeat scroll top left;
}



/* pseudo widget, automatically added */
.widget.filter
{
  font: normal 18px "Arial";
  color: #333333;
}
.widget.filter label
{
  color: #666666;
}



.widget.image
{
  text-decoration: none;
  overflow: hidden;

  /* when used next to eachother in a widgetsgrid,
     we want to align the images
  */
  display: block;
  display: flex;
  flex-direction: column;
}
.widget.image img
{
  flex: none;
  display: block;
  width: 100%;
  height: auto;
  vertical-align: bottom; /* to prevent whitespace below */
}
.widget.image .title
{
  padding: 12px 19px 0px;

  flex: 1 0 auto;
}



  html.layout_mobile .widget.pageindex
, html.layout_tablet .widget.pageindex
{
  display: none;
}



.widget.themesgrid
{
  overflow: hidden;
  padding: 20px 20px 20px 0;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
.widget.themesgrid > ul
{
  margin: 0;
  padding: 0;
}
.widget.themesgrid > ul > li
{
  float: left;
  list-style: none;
  padding-left: 20px;
}
html.layout_desktop .widget.themesgrid > ul > li
{
  width: 25%;
}
  html.layout_tablet .widget.themesgrid > ul > li
, html.layout_mobile .widget.themesgrid > ul > li
{
  width: 50%;
}
.widget.themesgrid > ul > li > a
{
  position: relative;
  display: block;
  border-radius: 7px;

  color: #000000;
  text-decoration: none;

  height: 128px;
}

.widget.themesgrid > ul > li > a > img
{
  position: absolute;
  top: 15px;

  left: 50%;
  margin-left: -30px;

  width: 68px;
}

.widget.themesgrid .label
{
  position: absolute;
  width: 100%;
  max-width: 100%;

  text-overflow: ellipsis;
  overflow: hidden;

  text-align: center;

  font: 600 19px 'TypoPRO Dosis';

  bottom: 5%;
}

  html.allowhover .widget.themesgrid > ul > li > a:hover
, .widget.themesgrid > ul > li > a:active
{
  background-color: #11b34f;
  color: #FFFFFF;
}

  .widget.themesgrid > ul > li > a > img.hover
, html.allowhover .widget.themesgrid > ul > li > a:hover > img.default
, .widget.themesgrid > ul > li > a:active > img.default
{
  display: none;
}
  html.allowhover .widget.themesgrid > ul > li > a:hover > img.hover
, .widget.themesgrid > ul > li > a:active > img.hover
{
  display: block;
}



a.widget.highlight
{
  display: block;
  text-decoration: none;
}
.widget.highlight > img.widgetimage
{
  display: block;
  width: 100%;
  border-radius: 8px;
}
.widget.highlight:hover > img.widgetimage
{
  opacity: 0.8;
}
.widget.highlight .title
{
  display: inline-block;

  margin-top: 25px;

  font: bold 19px "Arial";

  color: var(--color-green);

  padding-left: 38px;
  background: transparent url("../web/img/embobjects/ctalinks-arrow.png") no-repeat scroll left top;
  min-height: 31px;
}
.widget.highlight .description
{
  display: block;
  font: 12px Arial;
  margin-left: 38px;
}


.imagestrip a
{
  display: block;
}


/* Schijf van vijf */
.widget.custom_schijfvanvijf
{
  box-shadow: none;
  border: 0 none;
  height: 370px;
}
html.layout_mobile .widget.custom_schijfvanvijf
{
  height: 500px;
}
.schijfvan5holder
{
  position: absolute;
  left: 0;
  width: 100%;
  height: 538px;
  bottom: -45px;
  overflow: hidden;
}
.schijfvan5
{
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 538px;
  margin: 0 0 0 -269px;
  padding: 0;
}
.schijfvan5:after
{
  display: block;
  content: '';
  padding-top: 100%;
}
.schijfvan5 > div
{
  position: absolute;
  max-width: 538px;
  max-height: 538px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.schijfvan5 > div > .title
{
  position: absolute;
  text-align: center;
  width: 100%;
  top: 10%;
  color: #642382;
  font: 600 24px/26px 'TypoPRO Dosis', Arial;
  opacity: 0;
  transition: opacity 0.3s;
}
.schijfvan5 > div.start > .title
{
  opacity: 1;
}
.schijfvan5 > div > .rotor
{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1; /* else FF will have our content behind the background */
  transition: all 0.3s ease-in-out;
  transform: scale(1) rotate(0);
}
.schijfvan5 > div > .item
{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.schijfvan5 *
{
  outline: none;
}
.schijfvan5 > div > .clickarea
{
  width: 100%;
  height: calc(100% - 130px);
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  z-index: 2;
}
.schijfvan5 > div img
{
  width: auto;
  height: auto;
  width: 100%;
  height: 100%;
}
.schijfvan5 > div > .rotor > .item > img
{
  z-index: 1; /* else FF will have our content behind the background */
  transform: scale(1) rotate(0);
  transition: all 0.3s ease-in-out;

  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  opacity: 0;
}
.schijfvan5 > div > .centertext
{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -14%;
  margin-top: -12%;
  width: 28%;
  height: 24%;
}
.schijfvan5 > div > .centertext > div
{
  position: absolute;
  top: 18px;
  left: 0;
  width: 100%;
  height: 100%;
  color: #fff;
  font: normal 16px/21px 'TypoPRO Dosis', Arial;
  white-space: nowrap;
  text-align: center;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.schijfvan5 > div > .infoblock
{
  position: absolute;
  top: 100%;
  margin-top: -23%;
  left: 24%;
  right: 24%;
  min-height: 80px;
  background-color: #642382;
  color: #fff;
  padding: 10px 20px;
  border-radius: 10px;
}
.schijfvan5 > div > .infoblock > .info
{
  position: absolute;
  text-align: center;
  font-size: 13px;
  line-height: 21px;
  top: 9px;
  left: 20px;
  right: 20px;
  bottom: 10px;
  overflow: hidden;
  white-space: nowrap;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}
.schijfvan5 > div > .infoblock > .info a
{
  color: #fff;
}
.schijfvan5 > div > .infoblock > .arrow
{
  position: absolute;
  top: -10px;
  left: 50%;
  margin-left: -20px;
  border-bottom: 17px solid #642382;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
}

.schijfvan5 > div > .rotor > .item.aow > img
{
  transform: scale(1) rotate(0deg);
}
.schijfvan5 > div > .rotor > .item.lijfrente > img
{
  transform: scale(1) rotate(145deg);
}
.schijfvan5 > div > .rotor > .item.overigvermogen > img
{
  transform: scale(1) rotate(215deg);
}
.schijfvan5 > div > .rotor > .item.werknapensionering > img
{
  transform: scale(1) rotate(290deg);
}
.schijfvan5 > div > .rotor > .item.werknemerspensioen > img
{
  transform: scale(1) rotate(73deg);
}

.schijfvan5 > div.aow > .rotor
{
  transform: scale(1) rotate(0deg);
}
.schijfvan5 > div.lijfrente > .rotor
{
  transform: scale(1) rotate(-145deg);
}
.schijfvan5 > div.overigvermogen > .rotor
{
  transform: scale(1) rotate(-215deg);
}
.schijfvan5 > div.werknapensionering > .rotor
{
  transform: scale(1) rotate(-290deg);
}
.schijfvan5 > div.werknemerspensioen > .rotor
{
  transform: scale(1) rotate(-73deg);
}

  .schijfvan5 > div.start > .infoblock > .info.start
, .schijfvan5 > div.aow > .infoblock > .info.aow
, .schijfvan5 > div.lijfrente > .infoblock > .info.lijfrente
, .schijfvan5 > div.overigvermogen > .infoblock > .info.overigvermogen
, .schijfvan5 > div.werknapensionering > .infoblock > .info.werknapensionering
, .schijfvan5 > div.werknemerspensioen > .infoblock > .info.werknemerspensioen
, .schijfvan5 > div.aow > .centertext > .aow
, .schijfvan5 > div.lijfrente > .centertext > .lijfrente
, .schijfvan5 > div.overigvermogen > .centertext > .overigvermogen
, .schijfvan5 > div.werknapensionering > .centertext > .werknapensionering
, .schijfvan5 > div.werknemerspensioen > .centertext > .werknemerspensioen
{
  opacity: 1;
  z-index: 1;
}

  .schijfvan5 > div.aow > .rotor > .item.aow > img
, .schijfvan5 > div.lijfrente > .rotor > .item.lijfrente > img
, .schijfvan5 > div.overigvermogen > .rotor > .item.overigvermogen > img
, .schijfvan5 > div.werknapensionering > .rotor > .item.werknapensionering > img
, .schijfvan5 > div.werknemerspensioen > .rotor > .item.werknemerspensioen > img
{
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 1;
}



.widget--adfiz
{
  overflow: hidden;
}
.widget--adfiz__form
{
/*  background-color: #642382;*/
  padding: 15px 12px;
  text-align: center;
}
.widget--adfiz .widget__title
{
  font: normal 24px 'TypoPRO Dosis';
}
.widget--adfiz__image > img
{
  display: block;
}
.widget--adfiz__image--sign > img
{
  margin-top: 5px;
  margin-left: auto;
  margin-right: auto;
}
.widget--adfiz__image--sign-and-man > img
{
  margin-top: 3px;
  margin-left: auto;
}

/* we are specific enough in our selector to confidently use !important */
  .widget--adfiz__form button
, .widget--adfiz__form input
{
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 10px;

  height: 36px !important;
  line-height: 36px !important;
}

.widget--adfiz__form input
{
  font-size: 14px;
}

.widget--adfiz__form button
{
  font-size: 18px;
}


/*
.widget.default .widget_body
{
  position: relative;
  padding: 12px 19px 20px 19px;
}
*/


/* Fix zichtbaarheid focus voor input's die op een paarse achtergrond staan) *********/
.pageheader--p3d.pageheader--p3d form input
{
  border: 1px solid #642382;
}

  .pageheader--p3d #p3d_doenvoorpensioen.ctabackground input:focus
, .custom_doenvoorpensioen input:focus
, .custom_nieuwewetgeving input:focus /* purple panel in image */
, .custom_checkaowleeftijd input:focus
, .widget--adfiz input:focus
{
  border-color: #642382; /* purple border */
  box-shadow: 0 0 0 1px rgba(255,255,255,1);
}
/*************************************************************************************/


.widget.share .sharecomponent__buttonbar
{
  display: flex;
}

.widget.share .sharecomponent__buttonbar.sharecomponent__buttonbar > * + *
{
  margin-left: 7px;
}

.widget--image__image
{
  display: block;
}



.widget--pageindex.widget--pageindex
{
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
  z-index: 1;
}
