.productlist {
  list-style-type: none;
  margin: 40px 0;
  padding: 0;
}

.productlist__item .wh-form__label {
  margin: 0 !important;
  padding-right: 15px;
}

.productlist__item__preview {
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.productlist__item__image {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  /* object-position: center center; */
}

#productlist .wh-form__fieldgroup {
  grid-row: 3;
  grid-column: 2;
  justify-self: end; /* at the right */
  align-self: end;
  flex-direction: row !important;
  align-items: center !important;
  margin: 0;
  margin-top: 15px;
  padding: 0;
  --formlayout-label-width: auto;
}

#productlist .wh-form__fieldgroup .wh-form__label {
  word-break: normal !important;
  font-weight: bold;
}

.productlist__item .wh-form__fieldgroup.wh-form__fieldgroup--textedit {
  width: min-content !important;
}

.productlist__item input[type=number] {
  width: 55px;
  padding-left: 5px;
  padding-right: 10px;
  margin-left: 10px;
  text-align: right;
  font-size: 14px;
}

.productlist__item__title {
  font: bold 18px/20px "TypoPRO Dosis", Arial;
  margin-bottom: 10px;
}

@media (max-width: 550px) {
  .productlist__item {
    border-top: 1px solid #ccc;
    padding: 15px 0;
  }
}
@media (min-width: 551px) {
  .productlist__item {
    border-top: 1px solid #ccc;
    padding: 15px 0;
    display: grid;
    grid-template-columns: min-content minmax(0, 1fr);
    grid-template-rows: min-content minmax(min-content, 1fr) min-content;
  }
  .productlist__item__title {
    grid-row: 1;
    grid-column: 1/span 2;
  }
  .productlist__item__preview {
    grid-row: 2/span 2;
    grid-column: 1;
    margin-right: 20px;
  }
  .productlist__item__description {
    grid-row: 2;
    grid-column: 2;
  }
  #productlist .wh-form__fieldgroup {
    grid-row: 3;
    grid-column: 2;
    justify-self: end;
    align-self: end;
  }
}