a.publication {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  padding-bottom: 20px;
  text-decoration: none;
  margin-left: -15px;
  margin-right: -15px;
  padding-left: 15px;
  padding-right: 15px;
}

a.publication:hover {
  background-color: #F3F3F3;
}

.publication__image {
  flex: none;
}

.publication__meta {
  flex: 1 1 0px;
}

.publication__title {
  font: bold 20px/26px var(--rtd-heading-fontfamily);
  color: var(--color-purple);
  margin-top: -3px;
}

a.publication:active .publication__title,
a.publication:hover .publication__title {
  /*background-color: #f5f5f5;*/
  text-decoration: underline;
}

.publication__specs {
  margin-top: 5px;
  color: #7F7E7E;
  font: 500 16px var(--rtd-heading-fontfamily);
}

.publication__specs__file {
  color: var(--color-green-small);
}

.publication__specs__file .icon {
  color: var(--color-green);
  font-size: 20px;
  margin-right: 6px;
}

.publication__specs__divider {
  color: #333333;
  padding-left: 10px;
  padding-right: 10px;
}

.publication__specs__date {
  color: var(--color-grey-text-small);
}

.publication__description {
  margin-top: 15px;
  font: 15px/26px Arial;
  color: #333333;
}

.publication__sharebar {
  margin-top: 20px;
}

.publication__sharebar button + button {
  margin-left: 6px;
}

.publication__sharebar button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  background-color: var(--color-green-small);
  color: #FFFFFF;
  width: 18px;
  height: 18px;
  border-radius: 2px;
  font-size: 12px;
  text-align: center;
  line-height: 18px;
  cursor: pointer;
}

.publication__sharebar > *:hover {
  background-color: var(--color-green-small);
}

@media (max-width: 480px) {
  .publication__image {
    display: none;
  }
}
@media (min-width: 481px) {
  .publication__sharebar {
    width: calc(100% - 215px);
  }
  .publication__image > div {
    background-size: cover;
    width: 140px;
    margin-right: 20px;
  }
}