@import "neutraal/neutraal.css";


  .wbdvmtool
, .wbdvmtool-popup
{
  --tool-headingfontfamily:  'TypoPRO Dosis', Arial, Sans-Serif;

  /* Terug, Volgende knoppen */
  --tool-button-color:                var(--button-color);
  --tool-button-font-desktop:         var(--button-font);
  --tool-button-font-mobile:          var(--button-font);
}
