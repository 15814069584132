/*
@mixin wh-form-hidenativecontrol
{
  // display: none or visibility: hidden; will prevent focus!!
  position: absolute !important; // to not mess with flex containers
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  opacity: 0;

  margin: 0;
}

@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.effecttool__question {
  display: grid;
}

.effecttool__question .contents {
  grid-column: 1;
  grid-row: 1;
}

.p3dtool2__infopopup {
  grid-column: 1;
  grid-row: 1;
  align-self: start;
  justify-self: end;
  min-width: 50%;
  max-width: 100%;
  width: clamp(50%, 400px, 100%);
}

.effecttool__question.infopopupactive .contents,
.effecttool__question.infopopupactive .effecttool__question__contents {
  opacity: 0.5;
}

.effecttool__questiontext h2 {
  display: flex;
}

.effecttool__questiontext h2 .p3dtool2__infobutton {
  margin-left: auto;
}

.p3dtool2__infobutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  padding: 5px;
  margin-right: -5px;
  margin-top: -5px;
  cursor: pointer;
}

.p3dtool2__infobutton::before {
  display: block;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f059";
  font-size: 26px;
  color: var(--color-green);
  margin-left: auto;
}
@media (max-width: 750px) {
  .p3dtool2__infobutton::before {
    font-size: 22px;
  }
}

.effecttool__questiontext {
  position: relative;
}

.effecttool__questiontext > .p3dtool2__infobutton {
  position: absolute;
  top: 0;
  right: var(--question-sidepadding);
}

.p3dtool2__infopopup {
  display: none;
  padding: 15px;
  position: relative;
  border: 2px solid #28A532;
  border-radius: 11px;
  background-color: #F1F9F1;
  box-shadow: 0 1px 7px 3px rgba(0, 0, 0, 0.1);
}

.effecttool__question.infopopupactive .p3dtool2__infopopup {
  display: block;
}

.p3dtool2__infopop__closebutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  width: min-content;
  margin-left: auto;
  font: 16px var(--rtd-heading-fontfamily);
  color: var(--color-green);
  cursor: pointer;
}

.p3dtool2__infopop__closebutton::after {
  margin-left: 6px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f057";
  font-size: 22px;
}

.p3dtool2__infopopup__body {
  margin-top: 18px;
}

.p3dtool2__infopopup__body > *:first-child {
  margin-top: 0;
}