/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/*
input[type="date"]::-webkit-calendar-picker-indicator
{
  color: $color-theme-blue;
  background-color: $color-theme-blue;
}
input[type="date"]::-webkit-calendar-picker-indicator::after
{
  content: "X";
  color: #FFFFFF;
}
*/
.filterform-datetimewrapper {
  position: relative;
  flex: 1 0 auto;
  max-width: 227px;
}

/* date/time */
.wh-form__dateinputgroup.wh-form__dateinputgroup,
.wh-form__timeinputgroup.wh-form__timeinputgroup {
  flex: none;
  padding: 0;
}
.wh-form__dateinputgroup.wh-form__dateinputgroup input,
.wh-form__timeinputgroup.wh-form__timeinputgroup input {
  box-shadow: none;
}
.wh-form__dateinputgroup.wh-form__dateinputgroup__line::after,
.wh-form__timeinputgroup.wh-form__timeinputgroup__line::after {
  top: 12px;
}

.filterform__fields .filterform__label {
  margin: 0;
}

.wh-form__dateinputgroup__line.wh-form__dateinputgroup__line.wh-form__dateinputgroup__line,
.wh-form__timeinputgroup__line.wh-form__timeinputgroup__line.wh-form__timeinputgroup__line {
  width: 45px;
}

.wh-form__dateinputgroup__line input {
  padding: 0 !important;
}

.wh-form__dateinputgroup__line.day input {
  width: 40px;
  padding-left: 15px !important;
  text-align: left;
}

.wh-form__dateinputgroup__line.month input {
  width: 27px;
}

.wh-form__dateinputgroup__line.year input {
  width: 45px;
}

.wh-form__dateinputgroup__line:after {
  position: relative;
  content: "-";
  font-size: 23px;
  top: 9px !important;
  right: 0 !important;
  padding: 0;
}

.wh-form__dateinputgroup.wh-form__dateinputgroup {
  align-items: stretch;
}

/*
Activate monospace on a non-monospace font:

.wh-form__dateinputgroup__line input
{
  font-variant-numeric: tabular-nums;
  text-transform: full-width;
}
*/
.wh-form__dateinputgroup__line input::placeholder {
  text-transform: uppercase;
  font-size: 20px;
}

.wh-form__dateinputgroup__line.day input::placeholder,
.wh-form__dateinputgroup__line.month input::placeholder {
  transform: scaleX(0.9);
  transform-origin: center center;
  font-size: 17px;
}

.wh-form__dateinputgroup__line.year input::placeholder {
  transform: scaleX(1.2);
  transform-origin: center center;
  font-size: 17px;
  letter-spacing: 2px;
}

.wh-form__dateinputgroup__line.year::after {
  display: none;
}

html:not(.datefieldsreplaced) .filterform-datetimewrapper {
  min-width: 0;
  flex: 1 0 0px;
}

html:not(.datefieldsreplaced) .filterform-datetimewrapper input[type=date] {
  padding-right: 0;
}

html:not(.datefieldsreplaced) .filterform-datetimewrapper input[type=date]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.datefieldsreplaced .filterform-datetimewrapper input[type=date] {
  left: auto !important;
  width: auto !important;
  height: auto !important;
  border: 0;
  z-index: -1;
}

.button.ui-datepicker-trigger {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 44px;
}

.wh-form__dateinputgroup__showpicker {
  margin-left: 15px;
}

.wh-form__dateinputgroup.wh-form__dateinputgroup {
  padding-right: 45px;
}

button.ui-datepicker-trigger {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 40px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

input[type=date] {
  -webkit-appearance: none !important;
  width: 100%;
  appearance: none;
  background-color: #FFFFFF;
}

.wh-form__dateinputgroup__showpicker,
button.ui-datepicker-trigger {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
  transition: background-color var(--button-transition-duration);
  cursor: pointer;
}

.wh-form__dateinputgroup__showpicker::after,
button.ui-datepicker-trigger::after,
html:not(.datefieldsreplaced) .filterform-datetimewrapper::before {
  font: var(--fontawesome-regular);
  content: fa-calendar;
  font-size: 23px;
  color: var(--color-green);
  transition: color var(--button-transition-duration);
}

/* Style native date field */
html:not(.datefieldsreplaced) .filterform-datetimewrapper::before {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  padding-left: 10px;
  padding-right: 10px;
  pointer-events: none;
}

.ui-datepicker-trigger::after {
  /*
    position: absolute;
    left: 0;
    top: 0;
  */
}

button.ui-datepicker-trigger:hover {
  background-color: var(--color-theme);
}

button.ui-datepicker-trigger:hover::after {
  color: #FFFFFF;
}

.ui-datepicker select {
  height: 25px;
}

.ui-datepicker select + select {
  margin-left: 2px;
}

@media (min-width: 641px) and (max-width: 600px) {
  .wh-form__dateinputgroup.wh-form__dateinputgroup {
    padding-right: 32px;
  }
  .wh-form__dateinputgroup.wh-form__dateinputgroup input {
    font-size: 17px;
  }
  .wh-form__dateinputgroup__line.day input {
    width: 28px;
    padding-left: 10px !important;
    text-align: left;
  }
  .wh-form__dateinputgroup__line.month input {
    width: 27px;
  }
  .wh-form__dateinputgroup__line.year input {
    width: 36px;
  }
  .wh-form__dateinputgroup__line input::placeholder {
    text-transform: lowercase;
    font-size: 17px;
  }
  .wh-form__dateinputgroup__line.day input::placeholder,
  .wh-form__dateinputgroup__line.month input::placeholder {
    transform: scaleX(0.9);
    transform-origin: center center;
    font-size: 17px;
  }
  .wh-form__dateinputgroup__line.year input::placeholder {
    transform: scaleX(1.2);
    transform-origin: center center;
    font-size: 17px;
    letter-spacing: 1px;
  }
  .wh-form__dateinputgroup__showpicker,
  button.ui-datepicker-trigger {
    width: 36px;
  }
  .wh-form__dateinputgroup__showpicker::after,
  button.ui-datepicker-trigger::after,
  html:not(.datefieldsreplaced) .filterform-datetimewrapper::before {
    font-size: 20px;
  }
}
@media (max-width: 640px) {
  .wh-form__dateinputgroup.wh-form__dateinputgroup {
    padding-right: 32px;
  }
  .wh-form__dateinputgroup__line.day input {
    width: 28px;
    padding-left: 10px !important;
    text-align: left;
  }
  .wh-form__dateinputgroup__line.month input {
    width: 24px;
  }
  .wh-form__dateinputgroup__line.year input {
    width: 33px;
  }
  .wh-form__dateinputgroup__line input::placeholder {
    text-transform: lowercase;
    font-size: 16px;
  }
  .wh-form__dateinputgroup__line.day input::placeholder,
  .wh-form__dateinputgroup__line.month input::placeholder {
    transform: scaleX(0.9);
    transform-origin: center center;
    font-size: 14.5px;
  }
  .wh-form__dateinputgroup__line.year input::placeholder {
    transform: scaleX(1.2);
    transform-origin: center center;
    font-size: 14.5px;
    letter-spacing: 1px;
  }
  .wh-form__dateinputgroup__showpicker::after,
  button.ui-datepicker-trigger::after,
  html:not(.datefieldsreplaced) .filterform-datetimewrapper::before {
    font-size: 18px;
  }
  .wh-form__dateinputgroup__line:after {
    position: relative;
    content: "-";
    font-size: 19px;
    top: 10px !important;
    right: 0 !important;
    padding: 0;
  }
  .filterform__fields .filterform__label {
    width: auto;
    flex: none;
    padding: 0 7px;
    font-size: 15px;
  }
}