input[type=checkbox].spc-toggle {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

input[type=checkbox].spc-toggle + label + label {
  vertical-align: middle;
}

input[type=checkbox].spc-toggle + label {
  flex: none;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  width: var(--checkbox-switch-track-width);
  min-width: var(--checkbox-switch-track-width);
  height: var(--checkbox-switch-track-height);
  border-radius: calc(var(--checkbox-switch-track-height) / 2);
  background-color: var(--checkbox-switch-track-color);
  border: var(--checkbox-switch-borderwidth) solid var(--checkbox-switch-track-color);
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

html[data-whatinput=keyboard] input[type=checkbox].spc-toggle:focus + label,
input[type=checkbox].spc-toggle:focus-visible + label {
  outline: 2px solid var(--checkbox-switch-track-color-active);
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: 4px;
}

input[type=checkbox].spc-toggle + label::before {
  content: "";
  position: absolute;
  height: var(--checkbox-switch-thumb-size);
  left: 1px;
  width: var(--checkbox-switch-thumb-size);
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-transition: left 0.2s, background-color 0.2s, border-color 0.2s; /* iOS 6 */
  transition: left 0.2s, background-color 0.2s, border-color 0.2s;
}

/* OFF */
input[type=checkbox].spc-toggle.spc-toggle + label:hover {
  border-color: var(--checkbox-switch-track-color);
}

/* ON */
input[type=checkbox].spc-toggle.spc-toggle:checked + label {
  background-color: var(--checkbox-switch-track-color-active);
  border-color: var(--checkbox-switch-track-color-active);
}

/* Disabled */
input[type=checkbox].spc-toggle.spc-toggle[disabled] + label {
  background-color: var(--checkbox-switch-track-color-disabled);
  border-color: var(--checkbox-switch-track-color-disabled);
}

input[type=checkbox].spc-toggle:checked + label::before {
  left: calc(100% - var(--checkbox-switch-thumb-size) - 1px);
}

input[type=checkbox].spc-toggle + label::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  font-size: 11px;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 5px;
  line-height: calc(var(--checkbox-switch-track-height) - var(--checkbox-switch-borderwidth) * 2 + 1px);
}