/*
This overrides the default WebReader style

WebReader CSS on 28 sep 2022
https://cdn1.readspeaker.com/script/10218/webReader/r/r2050/ReadSpeaker.Styles-Button.css?v=3.6.1.2050
*/
html {
  --webreader-height: 35px;
  --webreader-button-width: var(--webreader-height);
  --webreader-border: 1px solid #DDDDDD;
  --webreader-border-color: #DDDDDD;
  --webreader-border-radius: 4px;
  --webreader-hoverbgcolor: #EEEEEE;
  --webreader-border-focus-color: #333333;
  --webreader-themecolor: var(--color-green-small);
  --webreader-popup-panel-arrowsize: 10px;
  --webreader-popup-panel-backgroundcolor: #E0E0E0;
  --webreader-popup-panel-padding: 12px;
}

.rsbtn.rsbtn_customskin .rsbtn_play {
  background: #efe;
  border-color: var(--webreader-themecolor);
}
.rsbtn.rsbtn_customskin .rsbtn_play .rsbtn_left .rsbtn_text::before {
  color: var(--webreader-themecolor);
  /* Speaker icon */
}
.rsbtn.rsbtn_customskin .rsbtn_play .rsbtn_left .rsbtn_text .rsbtn_label {
  color: var(--webreader-themecolor);
  /* Listen label */
}
.rsbtn.rsbtn_customskin .rsbtn_play .rsbtn_right {
  border-color: var(--webreader-themecolor);
}
.rsbtn.rsbtn_customskin .rsbtn_play .rsbtn_right::before {
  color: var(--webreader-themecolor);
  /* Play icon */
}
.rsbtn.rsbtn_customskin .rsbtn_play:hover {
  background: #fff;
}
.rsbtn.rsbtn_customskin .rsbtn_play:hover .rsbtn_left .rsbtn_text::before {
  color: #070;
  /* Speaker icon */
}
.rsbtn.rsbtn_customskin .rsbtn_play:hover .rsbtn_left .rsbtn_text .rsbtn_label {
  color: #070;
  /* Listen label */
}
.rsbtn.rsbtn_customskin .rsbtn_play:hover .rsbtn_right::before {
  color: #070;
  /* Play icon */
}
.rsbtn.rsbtn_customskin .rsbtn_tooltoggle {
  background: #efe;
  border-color: var(--webreader-themecolor);
  color: var(--webreader-themecolor);
}
.rsbtn.rsbtn_customskin.zoom-tooltoggler .rsbtn_tooltoggle {
  background: #fff;
  border-color: var(--webreader-themecolor);
  color: #070;
}

.rsbtn .rsbtn_play .rsbtn_left .rsbtn_text span {
  color: var(--webreader-themecolor) !important;
}

.rsbtn .rsbtn_play .rsbtn_left .rsbtn_text::before,
.rsbtn .rsbtn_play .rsbtn_left .rsbtn_text::after {
  display: none;
}

.rsbtn .rsbtn_play:focus .rsbtn_left .rsbtn_text,
.rsbtn .rsbtn_play:focus .rsbtn_left .rsbtn_text span {
  color: var(--webreader-themecolor) !important;
}

.rs_addtools .rsbtn_play:focus .rsbtn_right::before,
.rsbtn .rsbtn_play:focus .rsbtn_right::before {
  color: var(--webreader-themecolor) !important;
}

/*

#readspeaker_button
  a.rsbtn_play
  div.rsbtn_exp.rsimg.rspart
  button.rsbtn_tooltoggle
  button.rsbtn_focusforward
  div.rsbtn_toolpanel
*/
#rsbtn_scrollcontrols span.rsicn {
  color: var(--webreader-themecolor);
}

#readspeaker_button {
  display: flex;
  width: fit-content;
  padding-bottom: 0;
  box-sizing: border-box;
  /*
    .rs_addtools .rsbtn_play .rsbtn_right:before
  , .rsbtn .rsbtn_play .rsbtn_right:before
  , .rspopup .rspopup_btn.rspopup_play:before
  {
      content: "\e902";
  }
  */
  /*
    &.rsexpanded .rsbtn_play
    {
      border-radius: var(--webreader-border-radius) 0 0 var(--webreader-border-radius);
    }
  */
}
@media print {
  #readspeaker_button {
    display: none !important;
  }
}
#readspeaker_button > * {
  float: none;
}
#readspeaker_button.rsbtn {
  margin-bottom: 0;
}
#readspeaker_button .rs_addtools .rsbtn_play .rsbtn_left,
#readspeaker_button .rsbtn .rsbtn_play .rsbtn_left {
  float: none;
  height: auto;
}
#readspeaker_button .rsbtn_text::before {
  height: auto;
  line-height: 100%;
}
#readspeaker_button .rsbtn_text span {
  padding-left: 0;
  font-size: 15px;
  line-height: 100%;
  height: auto;
}
#readspeaker_button .rs_addtools.zoom-tooltoggler .rsbtn_tooltoggle,
#readspeaker_button .rsbtn.zoom-tooltoggler .rsbtn_tooltoggle {
  left: auto;
  line-height: auto;
}
#readspeaker_button .rsbtn_play {
  margin-bottom: 0;
}
#readspeaker_button .rsbtn_player_item {
  float: none;
  line-height: 1;
}
#readspeaker_button span.rsicn {
  background-color: transparent;
}
#readspeaker_button .rsbtn_tooltoggle {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: static;
  width: auto;
  height: auto;
}
#readspeaker_button .rsbtn_toolpanel {
  width: auto !important;
  min-width: 0 !important;
}
#readspeaker_button .rsbtn_powered {
  position: static;
  left: auto;
  top: auto;
}
#readspeaker_button button.rsbtn_tool {
  padding: 0;
}
#readspeaker_button .rsbtn_play {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
#readspeaker_button .rsbtn_left, #readspeaker_button .rsbtn_right {
  margin: 0;
  float: none;
  height: auto;
  line-height: 1;
  border: 0;
}
#readspeaker_button.rsexpanded .rsbtn_play .rsbtn_right {
  display: block;
}
#readspeaker_button .rsbtn_right.rsimg.rsplay.rspart::before {
  display: block;
  content: "\e902";
}
#readspeaker_button span.rsicn {
  color: var(--webreader-themecolor);
}
#readspeaker_button .rsbtn_play {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  border: var(--webreader-border);
  border-radius: var(--webreader-border-radius);
  background: #fff;
  padding: 0px 10px 0 15px;
  height: 35px;
  height: var(--webreader-height, 35px);
}
#readspeaker_button .rsbtn_play:focus {
  border-color: var(--webreader-border-focus-color);
}
#readspeaker_button .rsbtn_tooltoggle {
  border-radius: var(--webreader-border-radius) 0 0 var(--webreader-border-radius);
}
#readspeaker_button .rsbtn_tooltoggle + .rsbtn_play {
  border-radius: 0 var(--webreader-border-radius) var(--webreader-border-radius) 0;
}
#readspeaker_button .rsbtn_exp {
  height: 35px;
  height: var(--webreader-height, 35px);
}
#readspeaker_button.rsexpanded.rsbtn_exp {
  display: flex;
}
#readspeaker_button .rsbtn_exp_inner {
  border: 0;
  display: flex;
  height: 100%;
}
#readspeaker_button .rsbtn_player_item, #readspeaker_button .rsbtn_tooltoggle,
#readspeaker_button .rsbtn_tool .rsicn {
  border: var(--webreader-border);
  margin-left: -1px;
  width: var(--webreader-button-width);
  background-color: #FFFFFF;
  cursor: pointer;
}
#readspeaker_button .rsbtn_player_item:focus,
#readspeaker_button .rsbtn_tooltoggle:focus,
#readspeaker_button .rsbtn_tool:focus .rsicn {
  background-color: var(--webreader-hoverbgcolor);
  border-color: var(--webreader-border-focus-color);
  z-index: 1;
}
#readspeaker_button .rsbtn_player_item:hover,
#readspeaker_button .rsbtn_tooltoggle:hover,
#readspeaker_button .rsbtn_tool:hover .rsicn {
  background-color: var(--webreader-themecolor);
  color: #FFFFFF;
}
#readspeaker_button .rsbtn_player_item:hover span.rsicn,
#readspeaker_button .rsbtn_tooltoggle:hover span.rsicn,
#readspeaker_button .rsbtn_tool:hover .rsicn span.rsicn {
  color: #FFFFFF;
}
#readspeaker_button .rsbtn_player_item:active,
#readspeaker_button .rsbtn_tooltoggle:active,
#readspeaker_button .rsbtn_tool:active .rsicn {
  box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
#readspeaker_button .rsbtn_volume.rsbtn_player_item {
  margin-left: 6px;
}
#readspeaker_button .rsbtn_closer.rsbtn_player_item {
  margin-left: 6px;
}
#readspeaker_button .rsbtn_tooltoggle {
  margin-left: 0px;
}
#readspeaker_button .rsbtn_left.rsimg.rspart {
  margin-left: 0;
}
#readspeaker_button .rsimg .rsbtn_text {
  display: flex;
  align-items: center;
}
#readspeaker_button .rsimg .rsbtn_text::before {
  position: static;
  color: var(--webreader-themecolor);
  margin-right: 15px;
  display: none;
}
#readspeaker_button .rsbtn_focusforward {
  /*
      position: static !important; // abusing this as breakline
      width: 100% !important; // ReadSpeaker sets inline styles
  */
}
#readspeaker_button .rsbtn_tools {
  border: 0;
}
#readspeaker_button .rsbtn_toolpanel.vertical,
#readspeaker_button .rsbtn_toolpanel.vertical .rsbtn_tools {
  position: static;
}
#readspeaker_button .rsbtn_tools_inner {
  position: absolute;
  right: 0;
  top: var(--webreader-height);
  width: min-content;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
  border: 0;
  padding: var(--webreader-popup-panel-padding);
  border-radius: var(--webreader-border-radius);
  background-color: var(--webreader-popup-panel-backgroundcolor);
  margin-right: -6px;
  margin-top: var(--webreader-popup-panel-arrowsize);
}
#readspeaker_button .rsbtn_toolpanel .rsbtn_tools::before {
  content: "";
  width: 0;
  height: 0;
  border-left: var(--webreader-popup-panel-arrowsize) solid transparent;
  border-right: var(--webreader-popup-panel-arrowsize) solid transparent;
  border-bottom: var(--webreader-popup-panel-arrowsize) solid var(--webreader-popup-panel-backgroundcolor);
}
#readspeaker_button button.rsbtn_tool {
  display: flex;
  align-items: center;
}
#readspeaker_button button.rsbtn_tool .rsicn {
  flex: none;
}
#readspeaker_button button.rsbtn_tool .rsbtn_btnlabel {
  padding-right: 30px;
  white-space: nowrap;
}
#readspeaker_button button.rsbtn_tool .rs_minitoggler {
  float: none;
  margin: 0 0 0 auto;
}
#readspeaker_button .rsbtn_powered {
  display: none;
  /*
  display: block;
  width: max-content;
  margin-left: auto;
  color: #666666;
  */
}
#readspeaker_button .rsbtn_status_overlay {
  display: flex;
  align-items: center;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFFFFF;
}
#readspeaker_button .rsbtn_status_overlay span {
  color: #FFFFFF;
}

/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
@media (max-width: 639px) {
  .pageheader-bottomrow #readspeaker_button .rsbtn_tools_inner {
    position: absolute;
    left: 0;
    right: auto;
  }
  .pageheader-bottomrow #readspeaker_button .rsbtn_toolpanel .rsbtn_tools::before {
    position: absolute;
    right: calc(var(--webreader-button-width) / 2 - 7px);
    top: var(--webreader-height);
  }
  .pageheader-bottomrow #readspeaker_button.rsexpanded .rsbtn_tools_inner {
    position: absolute;
    right: 0;
    left: auto;
  }
  .pageheader-bottomrow #readspeaker_button.rsexpanded .rsbtn_toolpanel .rsbtn_tools::before {
    position: absolute;
    right: calc(var(--webreader-button-width) / 2 - 7px);
    top: var(--webreader-height);
  }
}
@media (min-width: 640px) {
  .pageheader-bottomrow #readspeaker_button .rsbtn_tools_inner {
    position: absolute;
    right: 0;
    left: auto;
  }
  .pageheader-bottomrow #readspeaker_button .rsbtn_toolpanel .rsbtn_tools::before {
    position: absolute;
    right: calc(var(--webreader-button-width) / 2 - 7px);
    top: var(--webreader-height);
  }
}
.pageheader__readspeaker #readspeaker_button .rsbtn_tools_inner {
  position: absolute;
  right: 0;
  left: auto;
}
.pageheader__readspeaker #readspeaker_button .rsbtn_toolpanel .rsbtn_tools::before {
  position: absolute;
  right: calc(var(--webreader-button-width) / 2 - 7px);
  top: var(--webreader-height);
}