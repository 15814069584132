.crumbpath
{
  --crumblepath-item-padding-h: 5px;

  display: flex;
  align-items: baseline;
  flex-wrap: wrap; /* laat het kruimelpad onder de prefix ("U bent hier") vallen als deze niet meer */

  column-gap: 10px; /* used when the prefix + items fit next to each other */
  row-gap: 4px;


  /*flex: 1 0 auto;*/
  max-width: 100%; /* prevent growing out of the viewport */

  color: #656565;
  font: 14px/17px var(--rtd-heading-fontfamily);
}

.crumbpath__prefix
{
  font-weight: bold;
}

.crumbpath__items
{
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;

  word-break: break-word;

  margin: 0 calc(-1 * var(--crumblepath-item-padding-h));
}

/* FIXME: we would like to prefer keeping all text in a .crumbpath__item on the same line.
          however if we disallow wrapping long titles will cause overflow (crumbpath extending beyond the screen) to happen on mobile. */
  .crumbpath__items a
, .crumbpath__items span /*.currentpage*/
{
  /*
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
  */

  color: inherit;
  padding: 5px var(--crumblepath-item-padding-h);
  text-decoration: none;
}

.crumbpath__items a
{
  color: #656565;
}
