/*
Purple       #641C76
Greentint    #F1F9F1

#1-Black     #000000
#2-TextBlack #333333
#3-AAGrey    #757575
#4-Divider   #DADADA
#5-Greytint  #F5F5F5
#6-White     #FFFFFF
*/
html {
  --color-greentint: #F1F9F1;
}

.page--huishoudboekjes {
  --filteredoverview-feedbackbar-color: var(--color-textblack);
}
.page--huishoudboekjes .contentarea {
  padding-left: 0;
  padding-right: 0;
}
.page--huishoudboekjes .contentarea__widthlimiter {
  max-width: calc(700px + var(--sidepadding) * 2);
  padding-left: 0;
  padding-right: 0;
}
.page--huishoudboekjes .pageheader__text, .page--huishoudboekjes .huishoudboekjes__filterbar,
.page--huishoudboekjes .huishoudboekjes__additionalfilters {
  max-width: 700px;
}
.page--huishoudboekjes .filteredoverview__feedbackandviewbar {
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
}
.page--huishoudboekjes .body__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}
.page--huishoudboekjes .body__content > * {
  flex: none;
}
.page--huishoudboekjes .comparisonbutton {
  margin-top: auto;
}
.page--huishoudboekjes .site__footerspacer {
  margin: 0;
}
.page--huishoudboekjes .sitefooter {
  z-index: 10;
}
@media (max-width: 649px) {
  .page--huishoudboekjes .filteredoverview__feedbackandviewbar__content {
    font: 16px var(--rtd-heading-fontfamily);
    color: #333333;
    margin-bottom: 18px;
    padding-bottom: 10px;
    border-bottom: 1px solid #D7D7D7;
  }
}
@media (min-width: 650px) {
  .page--huishoudboekjes .filteredoverview__feedbackandviewbar__content {
    margin-bottom: 30px;
  }
}

.huishoudboekjes__lastupdated {
  font: 13px var(--rtd-heading-fontfamily);
  color: #767676;
  margin-left: auto;
  position: relative;
  top: -27px;
}

.comparisontable-active .siteheader-menubar__content:first-child {
  display: none;
}

.siteheader-menubar__content--comparisontable {
  display: flex;
  align-items: center;
}
.siteheader-menubar__content--comparisontable h2 {
  color: var(--menubar-bar-item-color);
  font: var(--menubar-level1-font);
}
.siteheader-menubar__content--comparisontable .siteheader-menubar__comparison-closebutton {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  margin-left: auto;
  margin-right: -10px;
  appearance: none;
  display: flex;
  padding: 10px;
  color: #757575;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
.siteheader-menubar__content--comparisontable .siteheader-menubar__comparison-closebutton::before {
  display: block;
  font: var(--fontawesome-light);
  content: var(--fa-times);
  font-size: var(--sidebar-closebutton-size);
  color: #FFFFFF;
}
.siteheader-menubar__content--comparisontable .siteheader-menubar__comparison-closebutton:hover {
  background: var(--color-green);
}

@media (min-width: 650px) {
  .siteheader-menubar__content--comparisontable {
    display: none;
  }
}
.comparisonbutton,
.comparisonclosebutton {
  position: sticky;
  bottom: 0;
  z-index: 5;
  width: max-content;
  background: var(--color-purple);
  padding: 4px 20px;
  height: 45px;
  font: 16px var(--rtd-text-fontfamily);
  display: flex;
  align-items: center;
  color: #FFFFFF;
  border: 0;
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  outline-offset: 3px;
  transition: transform var(--button-transition-duration);
}

.comparisonbutton {
  margin-top: 30px;
  margin-right: auto;
  --contentarea-current-width: min(100vw, calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2));
  --sides-whitespace: max(0px, calc((100vw - var(--contentarea-current-width)) / 2));
  --shiftx: calc(min(700px, 100vw) / 2);
  margin-left: calc(var(--sides-whitespace) + var(--shiftx));
}

.body__content > .comparisonbutton {
  transform: translate(-50%, 0);
}

.body__content > .comparisonbutton[hidden] {
  display: flex;
  transform: translate(-50%, 100%);
}

.comparisonclosebutton {
  left: 50%;
  transform: translate(-50%, 0%);
}

.comparisonbutton[hidden] {
  display: none;
}

.comparisonclosebutton {
  display: none;
}

.comparisontable-active .comparisonbutton {
  display: none;
}

.comparisontable-active .comparisonclosebutton {
  display: block;
}

.comparisonbutton:hover,
.comparisonclosebutton:hover {
  background: var(--color-purple);
}

.comparisonbutton-amount {
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #FFFFFF;
  color: var(--color-purple);
  padding-right: 2px;
  letter-spacing: -1px;
  font: bold 15px Arial;
}