/*
NOTE:
- We might use "position anchoring" in the future to hook the comparisontable header to the menubar
*/
.comparisontable-active .filteredoverview__form,
.comparisontable-active .contentarea,
.comparisontable-active main > .widgets_column,
.comparisontable-active #main_widgetsgrid {
  display: none;
}

html {
  --comparisontable-header-background: var(--color-greentint);
  --comparisontable-header-color: var(--color-green);
  --comparisontable-header-itemcount-font: bold 16px var(--rtd-heading-fontfamily);
  --comparisontable-header-font: bold 15px var(--rtd-heading-fontfamily);
  --comparisontable-header-padding-top: 24px;
  --comparisontable-header-padding-bottom: 8px;
  --comparisontable-optiongroup-title-font: bold 15px var(--rtd-text-fontfamily);
  --comparisontable-item-column-minwidth: 100px;
  --comparisontable-item-font: 15px var(--rtd-text-fontfamily);
  --comparisontable-icon-size: 29px;
}

@media (min-width: 768px) {
  .page--huishoudboekjes {
    --comparisontable-header-font: bold 16px var(--rtd-heading-fontfamily);
  }
}
html.page--huishoudboekjes.comparisontable-active {
  overflow: hidden;
}
html.page--huishoudboekjes.comparisontable-active body {
  overflow: scroll;
  height: 100vh;
  width: 100vw;
}
html.page--huishoudboekjes.comparisontable-active .siteheader-top, html.page--huishoudboekjes.comparisontable-active .siteheader-menubar,
html.page--huishoudboekjes.comparisontable-active .pageheader,
html.page--huishoudboekjes.comparisontable-active .sitefooter {
  position: sticky;
  left: 0;
}

@media (min-width: 650px) {
  html.page--huishoudboekjes.comparisontable-active .siteheader-top, html.page--huishoudboekjes.comparisontable-active .siteheader-menubar,
  html.page--huishoudboekjes.comparisontable-active .pageheader,
  html.page--huishoudboekjes.comparisontable-active .sitefooter {
    display: none;
  }
  html.page--huishoudboekjes.comparisontable-active .comparisontable-wrapper {
    --header-menubar-height: 0px;
  }
}
@media (max-width: 650px) {
  .page--huishoudboekjes.comparisontable-active .pageheader {
    display: none;
  }
}
.comparisontable-wrapper {
  --contentarea-current-width: min(100vw, calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2));
  --sides-whitespace: max(0px, calc((100vw - var(--contentarea-current-width)) / 2));
  display: grid;
  grid-template-columns: var(--sides-whitespace) var(--sidepadding) minmax(1fr, max-content) var(--sidepadding) var(--sides-whitespace);
}

.comparisontable {
  grid-column: 1/-1;
  display: grid;
  grid-auto-columns: min-content;
  grid-template-columns: var(--sides-whitespace) 15px max-content repeat(var(--comparisontable-itemscount), minmax(min-content, 1fr)) 1fr 15px;
  grid-auto-rows: min-content;
  width: 100%;
}

.comparisontable + .comparisonclosebutton {
  margin-top: 30px;
  grid-column: 1/-1;
  width: max-content;
}

.comparisontable__header {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: subgrid;
  position: sticky;
  top: var(--header-menubar-height);
  z-index: 2;
  align-items: end;
  align-items: last baseline;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  background: var(--comparisontable-header-background);
  color: var(--comparisontable-header-color);
  font: var(--comparisontable-header-font);
  padding: var(--comparisontable-header-padding-top) 0 var(--comparisontable-header-padding-bottom) 0;
  transition: top 0.5s;
}

.comparisontable__header a {
  text-underline-offset: 3px;
}

.comparisontable__header-bottom {
  grid-column: 3/-2;
  display: grid;
  grid-template-columns: subgrid;
  padding-top: 12px;
  padding-bottom: 2px;
}

.comparisontable__removeitembutton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--comparisontable-icon-size);
  height: var(--comparisontable-icon-size);
  border-radius: 50%;
  background: var(--color-greentint);
  color: var(--color-green);
  border: 1px solid #DADADA;
  /*
    position: absolute;
    // bottom: calc(-1 * (var(--comparisontable-header-padding-bottom) + 2px));
    bottom: -15px;
    transform: translateY(100%);
  */
  cursor: pointer;
  outline-offset: 4px;
}

.comparisontable__removeitembutton::before {
  display: block !important;
  content: var(--fa-trash-alt);
  font: var(--fontawesome-regular);
  font-size: 14px;
}

.comparisontable__removeitembutton:hover {
  background: var(--color-green);
  border-color: var(--color-green);
}
.comparisontable__removeitembutton:hover:before {
  color: #FFFFFF;
}

html:not(.menubar--sticky) .comparisontable__header {
  top: var(--header-menubar-height);
}

html.menubar--hide .comparisontable__header {
  top: 0;
}

.comparisontable__header > * + *,
.comparisontable__header-bottom > * + *,
.comparisontable__row > * + * {
  margin-left: 20px;
}

.comparisontable__header > * {
  min-width: var(--comparisontable-item-column-minwidth);
}

.comparisontable__header :first-child {
  grid-column: 3;
}

.comparisontable__header__amountofitems {
  white-space: nowrap;
  color: #333333;
  font: var(--comparisontable-header-itemcount-font);
}

.comparisontable__optiongroup {
  grid-column: 2/-1;
  display: grid;
  grid-template-columns: subgrid;
}

.comparisontable__header + .comparisontable__optiongroup {
  margin-top: 45px;
}

.comparisontable__optiongroup + .comparisontable__optiongroup {
  margin-top: 60px;
}

.comparisontable__optiongroup__header {
  grid-column: 2/-2;
  display: grid;
  grid-template-columns: subgrid;
  padding-bottom: 4px;
  font: var(--comparisontable-optiongroup-title-font);
  position: sticky;
  padding-left: 15px;
  margin-left: -15px;
  left: calc(var(--sidepadding) - 15px);
  top: 70px;
  top: calc(var(--header-menubar-height) + var(--comparisontable-header-height) + 5px);
  display: flex;
  align-items: center;
  width: max-content;
  min-height: 38px;
  padding-right: 15px;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  box-shadow: 0 0 8px 3px rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2px);
  margin-bottom: 4px;
  /*
  // White stroke around the text
  -webkit-text-stroke: 9px #FFFFFF;
  -webkit-text-stroke: 9px #FFFFFF99;
  paint-order: stroke fill;
  */
  z-index: 1;
}

.comparisontable__row {
  font: var(--comparisontable-item-font);
}

.comparisontable__row:hover {
  background: #FAFAFA;
}

.comparisontable__row {
  position: relative;
}

.comparisontable__row .infobutton {
  flex: none;
  margin-left: 0;
  margin-right: 15px;
  background: #FFFFFF;
}

/*
#088638 - darkgreen
#F1F9F1 - green tint
*/
.comparisontable__option__titlewrapper {
  grid-column: 1;
  grid-row: 1;
  position: sticky;
  left: var(--sidepadding);
  display: flex;
}

.comparisontable__option__titlewrapper .tippy-box {
  max-width: 450px !important;
}

.comparisontable__option__title {
  justify-self: start;
  display: flex;
  align-items: center;
  padding: 4px 15px;
  border: 1px solid var(--color-green-background-highcontrast);
  background: #FFFFFF;
  color: var(--color-green-background-highcontrast);
  font: var(--comparisontable-item-font);
  min-height: 29px;
  border-radius: 15px;
  max-width: 150px;
  word-break: break-word;
  text-wrap: balance;
  box-shadow: 0 0 8px 4px rgb(255, 255, 255);
}

a.comparisontable__option__title:hover {
  background: var(--color-green-background-highcontrast);
  color: #FFFFFF;
}

.comparisontable__row::before {
  grid-column: 1;
  grid-row: 1;
  position: sticky;
  left: 0;
  content: "";
  background: #FFFFFF;
  width: 60px;
  height: 37px;
}

/*
.comparisontable__optiongroup__header::before
{
  grid-column: 1;
  grid-row: 1;

  position: sticky;
  left: 0; //var(--sidepadding);
  z-index: -1;

  content: "";
  background: #FFFFFF;
  width: 60px;
  height: calc(29px + 4px + 4px);
}
*/
.comparisontable__row {
  grid-column: 2/-2;
  display: grid;
  grid-template-columns: subgrid;
  align-items: center;
  border-top: 1px solid #F1F1F1;
  box-sizing: border-box;
  min-height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.comparisontable__value--yes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--comparisontable-icon-size);
  height: var(--comparisontable-icon-size);
  border-radius: 50%;
  background: var(--color-green);
  color: #FFFFFF;
}

.comparisontable__value--yes::before {
  content: var(--fa-check);
  font: var(--fontawesome-regular);
  font-size: 17px;
}

.comparisontable__value--no {
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--comparisontable-icon-size);
  height: var(--comparisontable-icon-size);
  border-radius: 50%;
  background: #DADADA;
  color: #FFFFFF;
}

.comparisontable__value--no::before {
  content: var(--fa-minus);
  font: var(--fontawesome-solid);
  font-size: 16px;
}