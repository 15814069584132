/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
html {
  --contentpanels-background: #FFFFFF;
  --contentpanels-box-shadow: 0 1px 5px 0 rgba(0,0,0,0.19);
  --contentpanels-radius: 8px;
  /************************************************************
   *
   *  Page components / Autosuggest
   */
  --component-autosuggest-radius: var(--contentpanels-radius);
  --component-autosuggest-padding: 4px 25px; /*13px 0 19px 0;*/
  --component-autosuggest-background: var(--contentpanels-background);
  --component-autosuggest-box-shadow: var(--contentpanels-box-shadow);
  --component-autosuggest-item-pad-h: var(--textcontrols_padleft); /* align options with the text input */
  --component-autosuggest-item-pad-v: 4px;
  --component-autosuggest-item-font: 16px/31px Arial, Sans-Serif;
  --component-autosuggest-item-color: #333333;
}

.searchpage__searchbar {
  display: flex;
  flex-wrap: wrap;
}

.searchpage__searchbar form {
  flex: 0 0 100%;
}

.wh-autocomplete-container .wh-autocomplete-values,
.header-filterbar__querycontainer .wh-autocomplete-values {
  /* positioned in a flex with wrap */
  position: relative;
  width: 100%;
}

.siteheader-top__search .wh-autocomplete-values,
.header-filterbar__querycontainer .wh-autocomplete-values,
.searchpage__searchbar .wh-autocomplete-values {
  border: 1px solid #D2D2D2;
  border-radius: 0 0 8px 8px;
  background-color: var(--component-autosuggest-background);
  box-shadow: var(--component-autosuggest-box-shadow);
  overflow: hidden;
}
.siteheader-top__search .wh-autocomplete-values .suggestion,
.header-filterbar__querycontainer .wh-autocomplete-values .suggestion,
.searchpage__searchbar .wh-autocomplete-values .suggestion {
  font: var(--component-autosuggest-item-font);
  min-height: 33px;
  padding: var(--component-autosuggest-padding);
  color: var(--component-autosuggest-item-color);
}
.siteheader-top__search .wh-autocomplete-values .suggestion:hover,
.siteheader-top__search .wh-autocomplete-values .suggestion:focus,
.header-filterbar__querycontainer .wh-autocomplete-values .suggestion:hover,
.header-filterbar__querycontainer .wh-autocomplete-values .suggestion:focus,
.searchpage__searchbar .wh-autocomplete-values .suggestion:hover,
.searchpage__searchbar .wh-autocomplete-values .suggestion:focus {
  background-color: #F1F9F1;
  color: var(--color-green);
  -moz-outline-radius: 8px;
  outline-radius: 8px;
}

.header-filterbar__querycontainer .wh-autocomplete-container {
  position: absolute;
  top: 100%;
  width: 100%;
}
.header-filterbar__querycontainer .wh-autocomplete-values {
  position: static;
  margin-left: -1px;
  margin-right: -1px;
}