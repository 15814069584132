/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/*
Used on

- Bibliotheek
  https://www.wijzeringeldzaken.nl/bibliotheek/
  .pagenavigation

- Huishoudboekjes
  https://www.wijzeringeldzaken.nl/huishoudboekjes/
  #rpc_pagethumbs2.pagenavigation

- Pers
  https://www.wijzeringeldzaken.nl/pers/
  #rpc_pagethumbs.pagenavigation
*/
.pagenavigation {
  border-top: 1px solid #dddddd;
  padding: 15px 0 0 0;
  text-align: center;
  height: 53px;
  position: relative;
  display: flex;
  align-items: center;
  font: var(--component-pagination-font);
}

.pagenavigation__pages {
  display: inline-flex;
  margin-left: auto;
  margin-right: auto;
}

.pagenavigation__previous,
.pagenavigation__next {
  background: none;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  font: inherit;
  color: var(--color-green-small);
  cursor: pointer;
  flex: 0 0 auto;
  min-width: 90px;
}

.pagenavigation__previous::before {
  margin-right: 12px;
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-left);
  font-size: 14px;
}

.pagenavigation__next::after {
  margin-left: 12px;
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-right);
  font-size: 14px;
}

.pagenavigation__page {
  background: none;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  font: inherit;
  display: inline-block;
  min-width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  color: var(--color-green-small);
  cursor: pointer;
}

.pagenavigation__page + .pagenavigation__page {
  margin-left: 5px;
}

.pagenavigation__page::before,
.pagenavigation__next::before {
  display: none;
}

.pagenavigation__page.active,
.pagenavigation__page:hover,
.pagenavigation__page.active[data-page]:not([data-page="-1"]),
.pagenavigation__page[data-page]:not([data-page="-1"]):hover {
  color: #FFFFFF;
  background-color: var(--color-green);
}

@media (max-width: 480px) {
  .pagenavigation__previous,
  .pagenavigation__next {
    font-size: 0;
    line-height: 0;
  }
  .pagenavigation__previous::before,
  .pagenavigation__next::after {
    font-size: 18px;
    margin: 0;
  }
}