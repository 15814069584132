.fo-items {
  display: flex;
  flex-direction: column;
}

.fo-item {
  position: relative;
}

.fo-item.notmatching.notmatching {
  display: none;
}

.fo-item > * {
  pointer-events: none;
}

.fo-item a,
.fo-item input {
  pointer-events: auto;
  z-index: 1;
}

.fo-item__link {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  outline: 0;
}

.fo-item__title {
  font: var(--results-item-title-font);
  color: var(--rtd-heading-color);
}

.fo-item__description {
  font: var(--results-item-description-font);
}

.fo-item__compare {
  display: flex;
  align-items: center;
  color: var(--color-aagrey);
  --formcontrol-checkradio-size: 24px;
  --formcontrol-checkradio-checked-bgcolor: var(--color-purple);
  --formcontrol-checkradio-checked-bordercolor: var(--color-purple);
  font: 14px Arial;
}

@media (max-width: 649px) {
  .fo-items {
    --results-item-title-font: bold 18px/24px var(--rtd-heading-fontfamily);
    --results-item-description-font: 15px/24px Arial;
  }
  .fo-item {
    border-bottom: 1px solid #D7D7D7;
    padding: 15px var(--sidepadding);
  }
  .fo-item:hover,
  .fo-item:focus-within {
    background: #F5F5F5;
  }
  .fo-item__icon {
    display: none;
  }
  .fo-item__description {
    margin-top: 4px;
  }
  .fo-item__compare {
    margin-top: 5px;
  }
  .fo-item__compare.fo-item__compare.fo-item__compare input {
    order: -1;
    margin-right: 10px;
  }
}
@media (min-width: 650px) {
  .fo-items {
    --results-item-title-font: 500 22px/26px var(--rtd-heading-fontfamily);
    --results-item-description-font: 15px/24px Arial;
    padding: 0 var(--sidepadding);
    row-gap: 20px;
  }
  .fo-item {
    border: 1px solid #DADADA;
    border-radius: 6px;
    box-shadow: 3px 4px 4px 0 rgba(0, 0, 0, 0.1);
    padding: 22px 10px 18px 20px;
    display: grid;
    grid-template-columns: 50px 1fr min-content;
    grid-template-rows: min-content min-content;
  }
  .fo-item:hover,
  .fo-item:focus-within {
    background: #F5F5F5;
    border: 1px solid var(--color-green);
    outline: 2px solid var(--color-green);
  }
  .fo-item:has(input:checked) {
    border: 1px solid var(--color-purple);
    background: rgba(100, 28, 118, 0.0352941176);
  }
  .fo-item__icon {
    grid-column: 1;
    grid-row: 1/span 2;
    align-self: start;
    max-width: 40px;
    max-height: 50px;
    /*
    width: 40px;
    height: 50px;
    object-fit: contain;
    object-position: top;
    */
  }
  .fo-item__title {
    grid-column: 2;
    grid-row: 1;
    align-self: baseline;
    margin-top: -3px;
  }
  .fo-item__description {
    grid-column: 2/span 2;
    grid-row: 2;
    margin-top: 6px;
  }
  .fo-item__compare {
    grid-column: 3;
    grid-row: 1;
    align-self: center;
    margin-top: -3px;
  }
  .fo-item__compare.fo-item__compare.fo-item__compare input {
    margin-left: 10px;
  }
}