@charset "UTF-8";
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
@import "./shared/rtd/rtd-shared.css";
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
@import "./css/embeddedobjects.css";
@import "./css/widgets.css";
@import "./css/widgetsgrid.css";
@import "../shared/widgets/accordion/accordion.css";
@import "../shared/widgets/checklist/checklist.css";
@import "../shared/widgets/downloadsandlinks/downloadsandlinks.css";
.pageheader--theme ~ .body__content .themeicon {
  display: none;
}

.tallcontent:empty {
  display: none;
}

@media (min-width: 959px) {
  .layout_tall {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 959px) {
  .layout_tall .tallcontent > * {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
  }
}
/*
.tallcontent
.contentwithin_wide
*/
/* !! make sure we only apply layout if we're directly within the
      main container, because when we are embedded in a FAQ/accordeon or checklist
      they will already have created the whitespace around us.
*/
.contentwithin_wide > .embeddedobject.widget--highlight {
  width: 100%;
  max-width: min(100% - var(--sidepadding) * 2, 940px);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 959px) {
  .tallcontent > .embeddedobject.widget--highlight {
    width: 100%;
    max-width: min(100% - var(--sidepadding) * 2, 940px);
    margin-left: auto;
    margin-right: auto;
  }
}
.contentwithin_wide > *,
.body__content > .widgetsgrid,
.crumbpath--contentbottom {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
}

.crumbpath--contentbottom {
  flex: none;
  padding-bottom: 10px;
}

/* The last element in the main content RTD must have a margin-bottom
   (unless it has it's own background color)
*/
.tallcontent > :last-child,
.widgets_column > :last-child,
.contentwithin_wide > :last-child {
  margin-bottom: var(--page-whitespace-bottom);
}

.tallcontent > .effecttool__root,
.contentwithin_wide > .effecttool__root {
  margin-bottom: 90px;
}

.themepanel--footer,
.widget--ctablocks,
.widget--themecarrousel {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}

/* Content with it's own background has padding within itself instead of margin-bottom */
.contentwithin_wide > .themepanel--footer:last-child,
.contentwithin_wide > .widget--ctablocks:last-child,
.contentwithin_wide > .widget--themecarrousel:last-child {
  margin-bottom: 0;
}

.js-maxhypotheek-widget:last-child {
  --page-whitespace-bottom: 90px;
}

main.rtdcontent > *:first-child {
  margin-top: 15px;
}

/*
In case of
- a header without text + widget-with-opaque-bg = 0px
- a header with text + widget-with-opaque-bg + not-mobile-layout = 0px
- a header without text + header bottomrow + widget-with-opaque-bg (mobile mode - because bottomrow will be hidden) (NOT possible yet... there's always a title?)

default     - text only sticks out on mobile
home        - text only sticks out on mobile
themeheader - always sticks out
*/
@media (min-width: 641px) {
  .pageheader--default + .body__content > .rtdcontent > .widget--ctablocks:first-child,
  .pageheader--default + .body__content > .rtdcontent > .widget--themecarrousel:first-child,
  .pageheader--home + .body__content > .rtdcontent > .widget--ctablocks:first-child,
  .pageheader--home + .body__content > .rtdcontent > .widget--themecarrousel:first-child {
    margin-top: 0;
  }
}
.pageheader--wvhg-thema2024 + .body__content > .rtdcontent > .widget--ctablocks:first-child,
.pageheader--wvhg-thema2024 + .body__content > .rtdcontent > .widget--themecarrousel:first-child {
  margin-top: 0;
}

.layout_tall .tallcontent > *:first-child,
.tallcontent + .widgets_column {
  margin-top: var(--page-whitespace-top);
}

#widgets_on_the_move > *:first-child {
  margin-top: 0;
}

.widgetsgrid {
  margin-top: 15px;
  margin-bottom: 30px;
}

#main_widgetsgrid {
  margin-top: 30px;
}

/* within the content area the widgetsgrid should not have it's own margin, but be within the margins/padding the content-area provides */
.tallcontent .widgetsgrid__inner {
  margin-left: 0px;
  margin-right: 0px;
}

.widgetgrouptitle {
  margin-bottom: -10px;
}

@media (max-width: 639px) {
  .widgetsgrid--solid {
    --widgetsgrid-solid-padding: 30px 15px;
  }
}
@media (min-width: 640px) and (max-width: 959px) {
  .widgetsgrid {
    margin-top: 30px;
  }
  .widgetsgrid--solid {
    --widgetsgrid-solid-padding: 35px 30px 20px 30px;
  }
}
@media (min-width: 960px) {
  .widgetsgrid {
    margin-top: 30px;
  }
  .widgetsgrid--solid {
    border-radius: 8px;
    --widgetsgrid-solid-padding: 40px 30px 30px 30px;
  }
}
/* Theme icon used at the left side of the page on pages within a theme folder
   (except for the landing page which has the icon embedded in the header)
*/
main .themeicon {
  display: block;
  position: absolute;
  left: -90px;
  top: var(--page-whitespace-top);
  width: 100px;
  height: 100px;
  transition: opacity 0.15s;
}

main .themeicon img {
  width: 80px;
  height: 80px;
}

@media (max-width: 768px) {
  main .themeicon {
    display: none;
  }
}
@media (max-width: 1169px) {
  main .themeicon {
    opacity: 0;
  }
}
@media (min-width: 1170px) {
  main .themeicon {
    opacity: 1;
  }
}
.widgets-moved-to-bottom .widgets_column {
  display: none;
}

@media (min-width: 640px) {
  .layout_tall {
    display: flex;
  }
  .layout_tall > .themeicon {
    flex: none;
  }
  .layout_tall > .tallcontent {
    flex: 1 0 0px;
    max-width: 639px;
  }
  .layout_tall > .widgets_column {
    width: 280px;
  }
  .layout_tall > .tallcontent + .widgets_column {
    margin-left: 21px;
  }
}
#rpc_summary:empty {
  display: none;
}

#rpc_summary:empty + #rpc_pagethumbs {
  display: none;
}

/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/**************************************************************************
*
*  Push the footer to the bottom
*/
html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

body > * {
  flex: none;
}

.site__footerspacer {
  margin-top: auto;
}

#relatedtotheme {
  margin-bottom: 100px;
}

* {
  box-sizing: border-box;
}

body {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none; /* Firefox for Android still needs this in 2022 */
  text-size-adjust: none;
}

main {
  position: relative;
  margin: 0;
  border-color: #AAAAAA;
}

#rpc_pagethumbs {
  margin-bottom: 30px;
}

/* generic class for all top level elements which are centered
   (including header, main and the green bar part of the footer)
*/
.centered {
  margin-left: auto;
  margin-right: auto;
}

/* Purely a container for referencing/grouping it's content.
   This content may be cleaned or scanned to create an index (bladwijzes) using JS.
   Don't set it to block because it might need to flow around widgets with float: right;
   Don't set it to display:contents. Although correct useage, it'll trigger serious Firefox layout bugs
*/
#nootvanderedactie {
  margin-top: 30px;
  border-top: 1px solid #CCCCCC;
  padding-top: 30px;
}

/*
Pageheader / Pensioen3daage

--> zie pensioen3daagse.css

*/
#download_pdf,
#tell_a_friend,
.pageheader--publications .pageheader__textpanel {
  background-color: #FFFFFF;
  border-top: 1px solid #EEEEEE;
  border-right: 1px solid #D2D2D2;
  border-bottom: 1px solid #A2A2A2;
  border-left: 1px solid #CCCCCC;
  border-radius: 8px;
  box-shadow: -1px 2px 3px rgba(0, 0, 0, 0.15);
  color: #333333;
}

/**/
main > div > .labelright {
  float: right;
  margin-top: 13px;
  display: inline-block;
  color: #fff;
  font: 600 13px/16px "TypoPRO Dosis", Arial;
  background-color: var(--color-grey-text-small);
  padding: 2px 7px;
  border-radius: 3px;
}

.pagedate {
  font: 14px/24px Arial, Sans-Serif;
  color: #666;
  padding-left: 20px;
  background: transparent url("@mod-minfin_wig/webdesigns/wijzeringeldzaken/web/img/date.png") 0 50% no-repeat;
}

/* taglist on rtd details page*/
#pagemeta {
  padding: 15px 0 25px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

#pagetags {
  display: table;
  padding: 0;
  margin: 0;
}

#pagetags .taggroup {
  display: table-row;
}

#pagetags .taggroup > * {
  display: table-cell;
  margin: 0;
  padding: 5px 0 0;
}

#pagetags .taggroup > strong {
  font-size: 14px;
  line-height: 16px;
  font-weight: normal;
}

#pagetags .taggroup > ul {
  list-style-type: none;
  margin-left: -5px;
  padding-left: 15px;
}

#pagetags .taggroup li {
  display: inline-block;
  background-color: var(--color-grey-text-small);
  color: #fff;
  padding: 2px 7px;
  border-radius: 3px;
  font: 600 13px/16px "TypoPRO Dosis", Arial;
  margin-left: 5px;
  margin-bottom: 3px;
}

#pagetags .taggroup li.wassearchedfor {
  background-color: #0db24c;
}

#pagemeta #pagetags + a {
  margin-top: 15px;
}

#pagemeta > a {
  display: inline-block;
  position: relative;
  text-decoration: none;
  border-top: 1px solid #7ed6a0;
  border-radius: 3px;
  color: #fff;
  height: 28px;
  padding: 5px 28px 5px 11px;
  font: normal 14px/18px Arial;
  background-color: #0db24c;
}

.summary {
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px 0;
}

.summary hr {
  border: 1px solid #DDDDDD;
  margin: 10px 0;
}

#pagesearchtabs {
  margin-top: 20px;
}

html.layout_tablet #pagesearchtabs + .summary,
html.layout_mobile #pagesearchtabs + .summary {
  border-top: 0 none;
}

.summary a {
  text-decoration: none;
  display: block;
  color: #333;
  padding: 12px 15px 12px 15px;
  position: relative;
  border-radius: 10px;
}

.summary a + a {
  margin-top: 3px;
}

.summary > * {
  margin-left: -15px;
}

.summary a:hover {
  background-color: #f5f5f5;
}

.summary .icon {
  display: block;
  width: 40px;
  height: 322px;
  position: absolute;
  top: 10px;
  left: 15px;
  background: transparent none 0 0 no-repeat;
}

.summary .icon > img {
  margin-left: -8px;
}

.summary .title,
.summary__item__title {
  font-size: 20px;
  line-height: 26px;
  color: #641C76;
  font-family: var(--rtd-heading-fontfamily);
  font-weight: 600;
  display: block;
  padding-right: 50px;
}
@media (max-width: 639px) {
  .summary .title,
  .summary__item__title {
    font: 600 18px/21px "TypoPRO Dosis", Arial;
  }
}

.summary__item__date {
  font: normal 14px/24px Arial;
  color: #656565;
  white-space: nowrap;
  display: inline-block;
}

.summary .labelright {
  float: right;
  display: inline-block;
  color: #fff;
  background-color: var(--color-grey-text-small);
  padding: 2px 7px;
  border-radius: 3px;
  font: 600 13px/16px "TypoPRO Dosis", Arial;
}

.summary .meta {
  color: #999;
}

.summary .description,
.summary__item__description {
  display: block;
  margin-top: 1px;
  overflow-x: hidden;
  font: 15px/26px Arial; /* old 14px/24px */
}

.summary__item__url {
  margin-top: 5px;
  display: block;
  white-space: nowrap;
  font: 500 15px Arial;
  color: var(--color-green);
  overflow: hidden;
  text-overflow: ellipsis;
}

.summary .meta .tag:after {
  content: ", ";
}

.summary .meta .tag:last-child::after {
  content: "";
}

.summary .meta .aanbiedertag {
  color: #642382;
}

/* filterblock right */
#filterwidget {
  padding: 15px 20px 20px;
}

html.layout_tablet #filterwidget,
html.layout_mobile #filterwidget {
  display: none;
}

#page404 h3 {
  margin-bottom: 5px;
}

#page404 ul {
  margin-top: 0;
  margin-bottom: 20px;
  padding-left: 15px;
}

.hr {
  margin: 38px 0 38px 0;
  height: 0;
  border: 0;
  border-top: 1px solid #DDDDDD;
}

.hr:after, .hr:before {
  content: "";
  margin-top: -1px;
  left: 50%;
  border: solid transparent;
  position: absolute;
}

.hr:after {
  border-color: rgba(255, 255, 255, 0);
  border-top-color: #FFFFFF;
  border-top-width: 15px;
  border-bottom-width: 15px;
  border-right-width: 30px;
  border-left-width: 30px;
  margin-left: -30px;
}

.hr:before {
  border-color: rgba(221, 221, 221, 0);
  border-top-color: #DDDDDD;
  border-top-width: 16px;
  border-bottom-width: 16px;
  border-right-width: 31px;
  border-left-width: 31px;
  margin-left: -31px;
}

/*
NOTE: amount of columns can be 2, 3, 4 or 5.
      Only on mobile we use a single column.
*/
.widget--widgetsgroup--equalheight {
  /* If not enough width for 4 columns:  downgrade 4 col grids to 3 */
}
.widget--widgetsgroup--equalheight .widgetsgrid__inner {
  display: grid;
  gap: 30px;
}
.widget--widgetsgroup--equalheight .widget {
  margin-top: 0;
}
@media (min-width: 700px) {
  .widget--widgetsgroup--equalheight .widgetsgrid__inner {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
  }
}
@media (min-width: 750px) {
  .widget--widgetsgroup--equalheight .widgetsgrid[data-cols="3"] .widgetsgrid__inner,
  .widget--widgetsgroup--equalheight .widgetsgrid[data-cols="4"] .widgetsgrid__inner,
  .widget--widgetsgroup--equalheight .widgetsgrid[data-cols="5"] .widgetsgrid__inner {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 30px;
  }
}
@media (min-width: 900px) {
  .widget--widgetsgroup--equalheight .widgetsgrid[data-cols="4"] .widgetsgrid__inner,
  .widget--widgetsgroup--equalheight .widgetsgrid[data-cols="5"] .widgetsgrid__inner {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 20px;
  }
}
@media (min-width: 1000px) {
  .widget--widgetsgroup--equalheight .widgetsgrid[data-cols="5"] .widgetsgrid__inner {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    gap: 15px;
  }
}

.adfiz__cookiemessage {
  font-size: 20px;
  color: var(--color-error);
  margin-top: 30px;
}

html[data-wh-consent*=all] .adfiz__cookiemessage {
  display: none;
}

.header__crumbrow {
  display: flex;
}

.header__crumbpath {
  flex: 1 0 0px;
}

.header__readspeaker {
  flex: 0 1 auto;
  white-space: nowrap;
}

/*
html.pensioen3daagse .header__readspeaker
{
  width:100%;
}
html.pensioen3daagse .header__readspeaker > div
{
  float: right;
}
*/
.page--publications .header__crumbrow {
  padding-right: 300px;
}
@media (max-width: 768px) {
  .page--publications .header__crumbrow {
    padding-right: 0;
  }
}

.widget-button--center {
  text-align: center;
}

.widget-button--right {
  text-align: right;
}

.textbutton {
  color: var(--button-color);
  font: var(--button-font);
  text-decoration: none;
  /* NOTE: Don't use white-space: nowrap; for textual buttons !
           These sometimes have long titles so especially on mobile they'll stretch the page beyond the viewport.
  */
  display: inline-flex;
  align-items: baseline;
}

.textbutton:hover {
  color: var(--button-color-hover);
  text-decoration: underline;
}

.defaultbutton,
.ctabutton,
.wh-poll__votebutton.wh-poll__votebutton {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color var(--button-transition-duration);
  cursor: pointer;
  outline-offset: var(--actionbutton-outline-offset);
}

.defaultbutton--solid,
.ctabutton,
.wh-poll__votebutton.wh-poll__votebutton {
  background-color: var(--button-color);
  color: #FFFFFF;
  border: 0;
}
.defaultbutton--solid:hover,
.ctabutton:hover,
.wh-poll__votebutton.wh-poll__votebutton:hover {
  background: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.defaultbutton--solid .wh-form__button--disabled,
.ctabutton .wh-form__button--disabled,
.wh-poll__votebutton.wh-poll__votebutton .wh-form__button--disabled {
  background: var(--button_disabled_backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.defaultbutton--outline {
  background: transparent;
  border: 1px solid var(--button-color);
  color: var(--button-color);
}
.defaultbutton--outline:hover {
  background: var(--button-color);
  color: #FFFFFF;
  text-decoration: none;
}
.defaultbutton--outline .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-poll__votebutton {
  justify-content: center;
}

.defaultbutton--search::after {
  font: var(--button-icon-font);
  content: var(--button-icon-search);
  margin-left: 35px;
  font-size: 18px;
  font-weight: normal;
}

.defaultbutton--backward::before {
  font: var(--button-icon-font);
  content: var(--button-icon-backward);
  margin-right: 15px;
}

.defaultbutton--forward::after {
  font: var(--button-icon-font);
  content: var(--button-icon-forward);
  vertical-align: middle;
  margin-left: 35px;
}

.textbutton--backward::before {
  font: var(--textbutton-icon-font);
  content: var(--textbutton-icon-backward);
  vertical-align: middle;
  margin-right: 15px;
}

.textbutton--forward::before {
  font: var(--textbutton-icon-font);
  content: var(--textbutton-icon-forward);
  vertical-align: middle;
  margin-right: 15px;
}

/*
  .wh-form__button--next
, .wh-form__button--submit
{
  justify-content: space-between;
}


form.wh-form--submitting .wh-form__button--submit
{
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}
*/
.widget--collapsedcontent {
  margin-top: var(--rtd-spacing-paragraph);
}

.widget--collapsedcontent__intro {
  position: relative;
}

.widget--collapsedcontent__intro ~ .widget--collapsedcontent__viewport {
  margin-top: var(--rtd-spacing-paragraph);
}

.widget--collapsedcontent__intro::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  opacity: 1;
  transition: height 0.2s ease-in-out 0.1s, opacity 0.2s ease-in-out 0s;
  pointer-events: none;
}

.widget--collapsedcontent__toggler {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--rtd-link-color);
  font: inherit;
  cursor: pointer;
}

.widget--collapsedcontent__toggler::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  margin-right: 8px;
}

.widget--collapsedcontent__viewport {
  display: none;
  opacity: 0;
  transition: opacity 2s;
}

.widget--collapsedcontent--expanded .widget--collapsedcontent__intro::after {
  display: none;
}
.widget--collapsedcontent--expanded .widget--collapsedcontent__toggler {
  display: none;
}
.widget--collapsedcontent--expanded .widget--collapsedcontent__viewport {
  display: block;
  opacity: 1;
}

.foldablecontent__foldcontainer {
  position: relative;
  overflow: hidden;
  height: 0;
  transition: height 0s;
}

.foldablecontent__foldcontainer::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  opacity: 1;
  transition: height 0.2s ease-in-out 0.1s, opacity 0.2s ease-in-out 0s;
  pointer-events: none;
}

.widget--foldablecontent--open .foldablecontent__foldcontainer::after {
  opacity: 0;
  transition: height 0.2s, opacity 0.2s ease-in-out 0.1s;
}

.foldablecontent.open > .foldablecontent__foldcontainer::after {
  height: 0;
}

.foldablecontent__contents {
  display: flow-root;
}

.foldablecontent__toggle {
  /* clear <button> styling */
  background: none;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  color: inherit;
  font: inherit;
  margin-top: 3px;
  display: block;
  color: var(--rtd-link-color);
  cursor: pointer;
}

.foldablecontent__toggle::before {
  display: none;
}

html.site--ffw .foldablecontent__toggle .more::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f107";
  font-size: 17px;
  margin-left: 8px;
}
html.site--ffw .foldablecontent__toggle .less::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f106";
  font-size: 17px;
  margin-left: 8px;
}

html.site--wig .foldablecontent__toggle,
html.site--geldlessen .foldablecontent__toggle,
html.site--wvhg .foldablecontent__toggle {
  text-transform: uppercase;
}
html.site--wig .foldablecontent__toggle .more::before,
html.site--geldlessen .foldablecontent__toggle .more::before,
html.site--wvhg .foldablecontent__toggle .more::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  margin-right: 8px;
}
html.site--wig .foldablecontent__toggle .less::before,
html.site--geldlessen .foldablecontent__toggle .less::before,
html.site--wvhg .foldablecontent__toggle .less::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f068";
  margin-right: 8px;
}

.foldablecontent__toggle > span {
  position: relative;
  display: inline-block;
}

.foldablecontent__toggle .fa {
  position: absolute;
  left: 0;
  font-size: 14px;
  top: 5px;
}

html.layout_mobile .foldablecontent--desktop .foldablecontent__foldcontainer::after,
html.layout_mobile .foldablecontent--desktop .foldablecontent__toggle,
html.layout_tablet .foldablecontent--desktop .foldablecontent__foldcontainer::after,
html.layout_tablet .foldablecontent--desktop .foldablecontent__toggle,
html.layout_desktop .foldablecontent--mobile .foldablecontent__foldcontainer::after,
html.layout_desktop .foldablecontent--mobile .foldablecontent__toggle {
  display: none;
}

html.layout_mobile .foldablecontent--desktop .foldablecontent__foldcontainer,
html.layout_tablet .foldablecontent--desktop .foldablecontent__foldcontainer,
html.layout_desktop .foldablecontent--mobile .foldablecontent__foldcontainer,
.wh-rtd-editor .foldablecontent .foldablecontent__foldcontainer {
  height: auto !important;
}

.foldablecontent__toggle > span.more,
.widget--foldablecontent--open > .foldablecontent__toggle > .less {
  display: block;
}

.foldablecontent__toggle > span.less,
.widget--foldablecontent--open > .foldablecontent__toggle > .more,
.foldablecontent.hasfit .foldablecontent__toggle,
.foldablecontent.hasfit .foldablecontent__foldcontainer::after {
  display: none;
}

.embeddedobject.widget--highlight {
  display: block;
  color: var(--widget-highlight-textcolor);
  background-color: var(--widget-highlight-background);
  padding: var(--widget-highlight-pad-v) var(--widget-highlight-pad-h) var(--widget-highlight-pad-v) var(--widget-highlight-pad-h);
  border: 2px solid var(--widget-highlight-border-color);
  border-radius: var(--widget-border-radius);
}

.widget--highlight__title.widget--highlight__title {
  display: flex;
  align-items: center;
  color: var(--widget-highlight-title-color);
  margin-top: 0;
  margin-bottom: 15px;
}

.widget--highlight__title::before {
  font: var(--fontawesome-regular);
  content: var(--fa-info-circle);
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
}

/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.widget-image__image {
  display: block;
  width: 100%;
}

.widget-image--allowfullscreen .widget-image__image {
  cursor: pointer;
}

.widget-image__caption {
  margin-top: 8px;
}

.widget-image__caption > p.normal {
  color: #333333;
  font: 14px/19px Arial;
}

.widget-image__caption + .widget-image__copyright {
  margin-top: 5px;
}

.widget-image__copyright {
  color: #757575;
  font: 14px/19px Arial;
}

.widget-image__imagewrapper {
  position: relative;
  overflow: hidden;
}

.widget-image__fullscreen {
  width: 37px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #D7D7D7;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  bottom: 10px;
  transition: transform 0.25s, border-radius 0.25s;
}

.widget-image__fullscreen::before {
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00e";
}

.widget-image__imagewrapper:hover .widget-image__fullscreen {
  transform: scale(1.5) translate(5px, 5px);
  border-radius: 50% 0 0 0;
}

.widget-richcontent--left {
  text-align: left;
}

.widget-richcontent--right {
  text-align: right;
}

.widget-richcontent--center {
  text-align: center;
}

@media (max-width: 799px) {
  .widget-richcontent__column + .widget-richcontent__column {
    margin-top: 15px;
  }
  .widget-richcontent__column:first-child > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column:last-child > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__content {
    display: flex;
    flex-direction: column;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__column:first-child {
    margin-top: 15px;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__column:last-child {
    margin-top: 0;
    order: -1;
  }
}
@media (min-width: 800px) {
  .widget-richcontent__content {
    display: flex;
    column-gap: 30px;
  }
  .widget-richcontent__column > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent__column {
    flex: 1 1 0px;
  }
}
/*
@media (min-width: $minwidth-pagecontent-twocolumn-centered)
{
  .widget-richcontent
  {
    .widget-richcontent__column:nth-child(1)
    {
      @include layout--default-left;
    }
    .widget-richcontent__column:nth-child(2)
    {
      @include layout--default-right;
    }
  }

  .widget-richcontent--equalheight .widget-richcontent__column
  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
*/
.widget-quote {
  display: flex;
  align-items: center;
}

.widget-quote__image {
  width: var(--widget-quote-photo-size);
  height: var(--widget-quote-photo-size);
  border-radius: 50%;
  flex: none;
  margin-right: 20px;
}

.widget-quote__text {
  flex: 1 0 0px;
}

.widget-quote__quote.widget-quote__quote > p {
  color: var(--widget-quote-name-color);
  font: 26px/32px var(--rtd-heading-fontfamily);
}

.widget-quote__person {
  margin-top: 10px;
}

.widget-quote__person__name {
  display: inline;
  color: #333333;
  font: 14px var(--rtd-text-fontfamily);
}

.widget-quote__person__function {
  margin-left: 7px;
  display: inline;
  color: #656565;
  font: 14px var(--rtd-text-fontfamily);
}

/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
[data-popup-video] {
  cursor: pointer;
}

.widget.video {
  padding-top: 12px;
  padding-bottom: 12px;
}

.widget.video .title,
.widget.video .description,
.widget.video .videowidget__buttonbar {
  padding-left: var(--widget-padding-h);
  padding-right: var(--widget-padding-h);
}

.widget.video .content {
  position: relative;
  padding-left: 3px;
  padding-right: 3px;
}

.videowidget__viewbutton {
  background: none;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  width: 100%;
  min-height: 100px;
  position: relative;
  background-color: #CCCCCC;
  outline-offset: 3px;
}

.videowidget__viewbutton > img {
  width: 100%;
  display: block;
}

.videowidget__viewbutton .duration {
  position: absolute;
  right: 6px;
  bottom: 4px;
  background-color: #000000;
  color: #939393;
  font: normal 12px/17px Arial;
  padding: 0 5px;
}

.videowidget__viewbutton::after {
  box-sizing: border-box;
  content: "";
  color: #FFFFFF;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f04b";
  font-size: 14px;
  padding-left: 2px;
  background-color: #218729;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  position: absolute;
  left: 13px;
  bottom: 10px;
}

.videowidget__viewbutton:hover::after {
  color: #218729;
  background-color: #FFFFFF;
}

/*
.videowidget__preview__playicon
{
  width: 37px;
  height: 37px;

  position: absolute;
  left: 13px;
  bottom: 10px;
}
*/
.widget.video .description > * {
  font: 15px/26px Arial; /* old 14px/24px */
}

.videowidget__buttonbar {
  display: flex;
  margin-top: 15px;
}

.videowidget__buttonbar * + * {
  margin-left: 12px;
}

/* no consent, no video */
/*
html:not([data-wh-consent="all"]) .viewvideo
{
  display:none;
}
*/
.videowidget__buttonbar > :first-child {
  margin-left: -7px;
}

a.videowidget__downloadbutton {
  /*@include clearbuttonelementstyling;*/
  background: none;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  display: inline-flex;
  align-items: center;
  color: inherit;
  text-decoration: none;
  color: #218729;
  font: 500 16px/20px var(--fontfamily-heading);
  padding: 5px 7px;
}

.videowidget__downloadbutton:hover {
  background-color: #F0F0F0;
  border-radius: 7px;
}

/*
.videowidget__viewbutton::before
{
  margin-right: 8px;

  @include fontawesome-solid;
  content: $fa-play-circle;
  font-size: 18px;
}
*/
.videowidget__downloadbutton::before {
  margin-right: 8px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f019";
  font-size: 18px;
}

/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.widget--rovid {
  overflow: hidden;
}

.widget--rovid__posterimage {
  width: 100%;
}

.widget--rovid__top {
  position: relative;
}

.widget--rovid__posterimage {
  display: block;
}

.widget--rovid__showvideo {
  position: absolute;
  left: 10px;
  bottom: 10px;
  /*
  background-image: url("../../web/img/video-play.green.svg");
  background-size: cover;
  background-repeat: no-repeat;
  */
}

button.widget--rovid__showvideo {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.widget--rovid__showvideo svg,
.vjs-big-play-button svg {
  --color-background: var(--color-green);
  --color-icon: #FFFFFF;
  --transition: fill var(--button-transition-duration);
}

.widget--rovid__showvideo svg {
  width: 38px; /* approx 30px * 1,4 (the shadow) */
  height: 38px;
}

.vjs-big-play-button svg {
  width: 57px; /* approx 45px * 1,4 */
  height: 57px;
}

.widget--rovid__showvideo svg use,
.vjs-big-play-button svg use {
  width: 100%;
  height: 100%;
}

.widget--rovid__showvideo:hover svg,
.vjs-big-play-button:hover svg {
  --color-background: #FFFFFF;
  --color-icon: var(--color-green);
}

.widget--rovid__duration {
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.6);
  color: #FFFFFF;
  font: 12px Arial;
  padding: 2px 4px;
}

.widget--rovid button:before,
.widget--rovid__fullscreencontent button:before {
  display: none;
}
.widget--rovid video,
.widget--rovid__fullscreencontent video {
  cursor: pointer;
}
.widget--rovid .video-js,
.widget--rovid__fullscreencontent .video-js {
  width: 100%;
  /*
      .vjs-slider
      {
        // background: #7D7D7D;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: $controlbar-slides-radius;
      }
  */
}
.widget--rovid .video-js .vjs-control-bar,
.widget--rovid__fullscreencontent .video-js .vjs-control-bar {
  width: auto;
  position: absolute;
  left: 10px;
  right: 10px;
  top: auto;
  bottom: 12px;
  height: 36px;
  border-radius: 6px;
  background-color: rgba(0, 0, 0, 0.7);
}
.widget--rovid .video-js .vjs-play-control,
.widget--rovid__fullscreencontent .video-js .vjs-play-control {
  display: inline-flex;
  align-items: center;
}
.widget--rovid .video-js .vjs-control,
.widget--rovid__fullscreencontent .video-js .vjs-control {
  outline: none;
}
.widget--rovid .video-js .vjs-time-control, .widget--rovid .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid .video-js .vjs-play-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-time-control,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-control .vjs-icon-placeholder:before {
  line-height: 36px;
}
.widget--rovid .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control .vjs-icon-placeholder:before {
  font-size: 24px;
}
.widget--rovid .video-js .vjs-play-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-control .vjs-icon-placeholder:before {
  font-size: 26px;
}
.widget--rovid .video-js .vjs-mute-control .vjs-icon-placeholder:before,
.widget--rovid__fullscreencontent .video-js .vjs-mute-control .vjs-icon-placeholder:before {
  font-size: 20px;
}
.widget--rovid .video-js .vjs-time-control,
.widget--rovid__fullscreencontent .video-js .vjs-time-control {
  font-size: 14px;
  padding-right: 6px;
}
.widget--rovid .video-js .vjs-control.vjs-play-control,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-play-control {
  order: 0;
}
.widget--rovid .video-js .vjs-control.vjs-currenttime,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-currenttime {
  order: 1;
}
.widget--rovid .video-js .vjs-control.vjs-progress-control,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-progress-control {
  order: 2;
}
.widget--rovid .video-js .vjs-control.vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-duration {
  order: 3;
}
.widget--rovid .video-js .vjs-control.vjs-volume-panel,
.widget--rovid__fullscreencontent .video-js .vjs-control.vjs-volume-panel {
  order: 4;
}
.widget--rovid .video-js .vjs-time-control.vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-time-control.vjs-duration {
  display: block;
}
.widget--rovid .video-js .vjs-remaining-time,
.widget--rovid__fullscreencontent .video-js .vjs-remaining-time {
  display: none;
}
.widget--rovid .video-js .vjs-load-progress,
.widget--rovid__fullscreencontent .video-js .vjs-load-progress {
  display: none;
}
.widget--rovid .video-js .vjs-play-progress,
.widget--rovid__fullscreencontent .video-js .vjs-play-progress {
  background-color: #0DB24C;
  border-radius: 4px;
}
.widget--rovid .video-js .vjs-current-time, .widget--rovid .video-js .vjs-remaining-time-display,
.widget--rovid .video-js .vjs-duration,
.widget--rovid__fullscreencontent .video-js .vjs-current-time,
.widget--rovid__fullscreencontent .video-js .vjs-remaining-time-display,
.widget--rovid__fullscreencontent .video-js .vjs-duration {
  display: block;
  font-size: 11px;
  padding: 0;
  opacity: 0.8;
}
.widget--rovid .video-js .vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control,
.widget--rovid .video-js .vjs-volume-bar,
.widget--rovid__fullscreencontent .video-js .vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control.vjs-volume-control,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar {
  height: 100%;
}
.widget--rovid .video-js .vjs-volume-bar,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar {
  margin: 0;
  display: flex;
  align-items: center;
}
.widget--rovid .video-js .vjs-volume-bar:focus,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar:focus {
  outline: 0;
  box-shadow: none;
}
.widget--rovid .video-js .vjs-volume-bar .vjs-volume-level:before,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar .vjs-volume-level:before {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -4.5px;
}
.widget--rovid .video-js .vjs-volume-bar:focus .vjs-volume-level:before,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar:focus .vjs-volume-level:before {
  outline: none;
  box-shadow: 0 0 2px rgb(255, 255, 255);
}
.widget--rovid .video-js .vjs-slider-horizontal .vjs-volume-level,
.widget--rovid__fullscreencontent .video-js .vjs-slider-horizontal .vjs-volume-level {
  border-radius: 4px;
  height: 5px;
}
.widget--rovid .video-js .vjs-volume-level.vjs-volume-level,
.widget--rovid__fullscreencontent .video-js .vjs-volume-level.vjs-volume-level {
  position: relative;
  z-index: 1;
}
.widget--rovid .video-js .vjs-volume-bar::after,
.widget--rovid__fullscreencontent .video-js .vjs-volume-bar::after {
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  height: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
}
.widget--rovid .video-js .vjs-picture-in-picture-control,
.widget--rovid__fullscreencontent .video-js .vjs-picture-in-picture-control {
  display: none;
}
.widget--rovid .video-js .vjs-volume-panel,
.widget--rovid__fullscreencontent .video-js .vjs-volume-panel {
  order: 1;
}
.widget--rovid .video-js .vjs-captions-toggle,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle {
  order: 2;
}
.widget--rovid .video-js .vjs-fullscreen-control,
.widget--rovid__fullscreencontent .video-js .vjs-fullscreen-control {
  order: 3;
}
.widget--rovid .video-js .vjs-captions-toggle,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle {
  width: auto;
  cursor: pointer;
}
.widget--rovid .video-js .vjs-captions-toggle.cc,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.cc {
  margin-left: 2px;
  margin-right: 0;
}
.widget--rovid .video-js .vjs-captions-toggle.ad,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.ad {
  margin-right: 3px;
}
.widget--rovid .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder {
  opacity: 1;
}
.widget--rovid .video-js .vjs-captions-toggle.cc + .vjs-captions-toggle.ad,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle.cc + .vjs-captions-toggle.ad {
  margin-left: 6px;
}
.widget--rovid .video-js .vjs-captions-toggle .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-captions-toggle .vjs-icon-placeholder {
  color: #fff;
  display: block;
  cursor: pointer;
  border: 1px solid #fff;
  font-family: Arial;
  font-size: 8px;
  display: flex;
  align-items: center;
  padding: 3px 3px;
  opacity: 0.6;
  letter-spacing: 0.7px;
}
.widget--rovid .video-js .vjs-progress-holder,
.widget--rovid__fullscreencontent .video-js .vjs-progress-holder {
  height: 5px;
  border-radius: 8px;
}
.widget--rovid .video-js .vjs-progress-holder:focus-visible,
.widget--rovid__fullscreencontent .video-js .vjs-progress-holder:focus-visible {
  box-shadow: 0 0 1px 1px rgb(255, 255, 255);
  outline: 0;
}
.widget--rovid .video-js .vjs-play-progress:before,
.widget--rovid__fullscreencontent .video-js .vjs-play-progress:before {
  content: "";
  width: 9px;
  height: 9px;
  background-color: #FFFFFF;
  border-radius: 50%;
  position: absolute;
  top: -2px;
  right: -4.5px;
}
.widget--rovid .video-js .vjs-big-play-button,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: auto;
  height: auto;
  display: block;
  vertical-align: top;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity var(--button-transition-duration);
}
.widget--rovid .video-js .vjs-big-play-button .vjs-icon-placeholder,
.widget--rovid__fullscreencontent .video-js .vjs-big-play-button .vjs-icon-placeholder {
  display: none;
}
.widget--rovid .video-js.vjs-has-started .vjs-big-play-button,
.widget--rovid__fullscreencontent .video-js.vjs-has-started .vjs-big-play-button {
  display: block;
  opacity: 0;
}
.widget--rovid .video-js.vjs-has-started + .widget--rovid__duration,
.widget--rovid__fullscreencontent .video-js.vjs-has-started + .widget--rovid__duration {
  display: block;
}
.widget--rovid .video-js .vjs-text-track-cue,
.widget--rovid__fullscreencontent .video-js .vjs-text-track-cue {
  transform: translateY(-30px);
  line-height: 30px !important;
}
.widget--rovid .video-js .vjs-text-track-cue > div,
.widget--rovid__fullscreencontent .video-js .vjs-text-track-cue > div {
  padding: 5px;
}
.widget--rovid .w-details,
.widget--rovid__fullscreencontent .w-details {
  /*
      &[open] summary
      {
        margin-bottom: 0;
      }
  */
}
.widget--rovid .w-details summary::-webkit-details-marker,
.widget--rovid__fullscreencontent .w-details summary::-webkit-details-marker {
  display: none;
}
.widget--rovid .w-details summary::marker,
.widget--rovid__fullscreencontent .w-details summary::marker {
  display: none;
}
.widget--rovid .w-details summary,
.widget--rovid__fullscreencontent .w-details summary {
  position: relative;
}
.widget--rovid .w-details summary:focus,
.widget--rovid__fullscreencontent .w-details summary:focus {
  z-index: 1;
}
.widget--rovid .w-details summary > div,
.widget--rovid__fullscreencontent .w-details summary > div {
  display: flex;
  align-items: center;
  min-height: 41px;
  padding: 0 16px;
  background: #F3F2F4;
  border-bottom: 1px solid #DDDDDD;
  color: var(--color-purple);
  font: 400 15px "TypoPRO Dosis", Arial;
  cursor: pointer;
  transition: background 0.3s;
  user-select: none;
}
.widget--rovid .w-details summary:hover > div,
.widget--rovid__fullscreencontent .w-details summary:hover > div {
  background: #e1dfe4;
}
.widget--rovid .w-details summary div:after,
.widget--rovid__fullscreencontent .w-details summary div:after {
  margin-left: auto;
  content: "";
  background-image: url("@mod-minfin_wig/webdesigns/wijzeringeldzaken/web/img/symbols/fa5-angle-down.green.cropped.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 11px;
  height: 16px;
  /*
        @include fontawesome-solid;
        content: $fa-angle-down;

        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
  */
}
.widget--rovid .w-details[open] summary > div:after,
.widget--rovid__fullscreencontent .w-details[open] summary > div:after {
  transform: scaleY(-1);
}

.video-js .vjs-control.vjs-control:focus:before,
.video-js .vjs-control.vjs-control:hover:before,
.video-js .vjs-control.vjs-control:focus {
  text-shadow: 0 0 4px rgb(255, 255, 255), 1px 1px 1px rgb(0, 0, 0);
  background-color: rgba(255, 255, 255, 0.1);
}

.vjs-slider::focus,
.widget--rovid .video-js .vjs-volume-bar::after,
.vjs-volume-bar:focus .vjs-volume-level {
  box-shadow: 0 0 1px 1px rgb(0, 0, 0), 0 0 1px 2.5px rgb(255, 255, 255);
}

.vjs-volume-control {
  padding-left: 2px;
  padding-right: 2px;
}

.widget--rovid .video-js .vjs-captions-toggle.vjs-on .vjs-icon-placeholder {
  background-color: var(--color-green);
  color: #FFFFFF;
}

.widget--rovid__fullscreencontent {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--color-green);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fsslideshow__button--closerovid {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
}

.fsslideshow__button--closerovid svg {
  fill: #FFFFFF !important;
}

.fsslideshow__button--closerovid:hover,
.fsslideshow__button--closerovid:focus {
  background-color: rgba(255, 255, 255, 0.2);
}

.widget--rovid__fullscreencontent__videowrapper {
  margin-left: auto;
  margin-right: auto;
  width: calc(100% - 30px);
  max-width: 940px;
}

.w-details__block {
  background: #F3F2F4;
  padding: 10px 15px;
}

.w-details__block--link {
  position: relative;
  padding-left: 37px;
  text-decoration: none;
  color: inherit;
  display: block;
  transition: background 0.3s;
}
.w-details__block--link:hover {
  background: #e6e4e8;
}

.w-details__block + .w-details__block {
  border-top: 1px solid #DDDDDD;
}

.w-details[open] + .w-details {
  border-top: 1px solid #DDDDDD;
}

.w-details details[open] + details {
  border-top: 1px solid #DDDDDD;
}

.w-details__block--link::before {
  margin-left: auto;
  position: absolute;
  left: 15px;
  top: 11px;
  display: block;
  width: 16px;
  height: 16px;
  content: "";
  background-image: url("@mod-minfin_wig/webdesigns/wijzeringeldzaken/web/img/symbols/fa5-arrow-alt-circle-down.solid.green.cropped.svg");
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.w-details__title {
  color: var(--color-green);
  font: 700 15px/19px "TypoPRO Dosis", Arial;
}

.w-details__details {
  color: #333333;
  font-size: 14px;
  line-height: 20px;
}

.widget--rovid--mini .w-details__details {
  color: #656565;
  font-size: 12px;
  line-height: 16px;
}

button.skiplinks {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
}

button.skiplinks:focus {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

/*
main
{
  scroll-margin-top: 15px; // keep space for fixed or sticky header floating over the content
}

main:focus
{
  outline: 0;
}
*/
.sharecomponent__buttonbar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 18px;
}

.sharecomponent__buttonbar .wh-share {
  appearance: none;
  border: 0;
  margin: 0;
  background: none;
  width: 37px;
  height: 37px;
  width: var(--component-sharepage-button-size, 37px);
  height: var(--component-sharepage-button-size, 37px);
  background-color: #FFFFFF;
  background: var(--component-sharepage-button-background, #FFFFFF);
  color: var(--component-sharepage-button-color);
  border: 1px solid var(--component-sharepage-button-color);
  border-radius: 8px;
  cursor: pointer;
}

.sharecomponent__buttonbar > .wh-share + .wh-share {
  margin-left: var(--component-sharepage-button-spacing, 13px);
}

.sharecomponent__buttonbar .wh-share:hover {
  background-color: var(--component-sharepage-button-color);
  background-image: none;
  color: #FFFFFF;
}

.sharecomponent__buttonbar .wh-share[data-network=twitter]::before {
  content: "𝕏" !important;
  font-size: 110%;
  font-weight: 700;
  font-family: Arial-black;
}

.sharecomponent__buttonbar .wh-share[data-network=facebook]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-facebook-f);
}

.sharecomponent__buttonbar .wh-share[data-network=linkedin]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-linkedin-in);
}

.sharecomponent__buttonbar .wh-share[data-network=whatsapp]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-whatsapp);
}

.sharecomponent__buttonbar .wh-share[data-network=email]::before,
.sharecomponent__buttonbar .wh-share.siteaction--emailpagelink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-envelope);
}

.sharecomponent__buttonbar .wh-share[data-network=print]::before {
  font: var(--fontawesome-regular);
  content: var(--fa-print);
  font-size: 19px;
  font-size: var(--component-sharepage-button-symbolsize, 19px);
}

.sharecomponent__buttonbar .wh-share.wh-share::before {
  font-size: 20px;
  font-size: var(--component-sharepage-button-symbolsize, 20px);
}

.sharecomponent {
  margin-top: 60px;
  padding-top: 35px;
}

.contentwithin_wide .sharecomponent {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}
.contentwithin_wide .sharecomponent--bothactions {
  width: auto;
}
.contentwithin_wide .sharecomponent:not(.sharecomponent--bothactions) .sharecomponent__buttonbar {
  justify-content: center;
}

.content__contentcolumn .sharecomponent {
  padding-left: 0;
}

.sharecomponent__title {
  color: var(--component-sharepage-heading-color);
  font: var(--component-sharepage-heading-font);
  margin-bottom: 14px;
}

h2.sharecomponent__title {
  margin-top: 0;
}

a.shareactionsbar__downloadpdflink {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid var(--button-color);
  color: var(--button-color);
  background: var(--component-sharepage-button-background, #FFFFFF);
}
a.shareactionsbar__downloadpdflink:hover {
  background-image: none;
  background-color: var(--button-color-hover);
  color: #FFFFFF;
}

a.shareactionsbar__downloadpdflink .far {
  font-size: 20px;
  margin-right: 15px;
}

.sharecomponent__buttonbar__filler {
  flex: 1 0 0px;
}

.wh-share[data-network=print] {
  margin-right: 30px;
}

/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.sitefooter-2021 {
  --footer-bottomlinks-font: 13px var(--rtd-text-fontfamily);
  --footer-heading-font: bold 20px/26px "TypoPRO Dosis Menu", "Dosis", Arial;
  --footer-heading-margin: 20px;
  --footer-linkslist-padding-v: 3px;
  --footer-linkslist-icon-margin: 12px;
  --footer-linkslist-icon-font-size: 16px;
  --footer-socialbutton-size: 38px;
  --footer-socialbutton-icon-size: 20px;
  /*
  .sitefooter__social__linkswrapper
  {

  }

  .sitefooter__social__links
  {
    display: flex;
  }
  */
}
.sitefooter-2021 .sitefooter__identity__logo {
  display: block;
  padding-bottom: 15px;
}
.sitefooter-2021 .sitefooter__identity__content a {
  color: #FFFFFF;
}
@media (max-width: 669px) {
  .sitefooter-2021 .sitefooter__content {
    background: var(--footer-panel-background);
    padding: 0 var(--sidepadding) 30px var(--sidepadding);
    font: 13px / 23px var(--rtd-text-fontfamily);
  }
  .sitefooter-2021 .sitefooter__content > * + * {
    margin-top: 25px;
  }
  .sitefooter-2021 .sitefooter__content .sitefooter__background + .sitefooter__identity {
    margin-top: 0;
  }
  .sitefooter-2021 .sitefooter__identity__logo {
    width: 100px;
  }
  .sitefooter-2021 .sitefooter__identity__content, .sitefooter-2021 .sitefooter__identity__content .rtdcontent > * {
    font: 13px/20px var(--rtd-text-fontfamily);
  }
  .sitefooter-2021 .sitefooter__header {
    margin-bottom: 15px;
  }
  .sitefooter-2021 .sitefooter__bottombar {
    height: 37px;
    font: 0.5652173913 var(--rtd-text-fontfamily);
  }
  .sitefooter-2021 .sitefooter__bottombar__copyright {
    display: none;
  }
}
@media (min-width: 670px) {
  .sitefooter-2021 .sitefooter__content {
    font: 14px / 23px var(--rtd-text-fontfamily);
    background: var(--footer-panel-background);
  }
  .sitefooter-2021 .sitefooter__identity__logo {
    display: block;
    width: 188px;
  }
  .sitefooter-2021 .sitefooter__identity__content, .sitefooter-2021 .sitefooter__identity__content .rtdcontent > * {
    font: 13px/20px var(--rtd-text-fontfamily);
  }
  .sitefooter-2021 .sitefooter__header {
    margin-bottom: 20px;
  }
  .sitefooter-2021 .sitefooter__content__copyright {
    display: none;
  }
  .sitefooter-2021 .sitefooter__bottombar {
    height: 48px;
    font: 14px / 23px var(--rtd-text-fontfamily);
  }
}
@media (min-width: 670px) and (max-width: 974px) {
  .sitefooter-2021 .sitefooter__content {
    display: grid;
    grid-template-columns: calc((100% - 970px) / 2) 15px 1fr 20px 1fr 15px calc((100% - 970px) / 2);
    grid-row-gap: 25px;
    row-gap: 25px;
  }
  .sitefooter-2021 .sitefooter__identity {
    grid-column: 3;
    grid-row: 1;
  }
  .sitefooter-2021 .sitefooter__quicklinks {
    grid-column: 5;
    grid-row: 1;
    padding-top: 30px;
  }
  .sitefooter-2021 .sitefooter__social {
    grid-column: 3;
    grid-row: 3;
  }
  .sitefooter-2021 .sitefooter__newsletterlink {
    grid-column: 5;
    grid-row: 3;
    align-self: end;
  }
  .sitefooter-2021 .sitefooter__social, .sitefooter-2021 .sitefooter__newsletterlink {
    padding-bottom: 30px;
  }
}
@media (min-width: 975px) {
  .sitefooter-2021 .sitefooter__content {
    display: grid;
    grid-template-columns: calc((100% - 970px) / 2) 15px 1fr 50px 1fr 50px 1fr 15px calc((100% - 970px) / 2);
    grid-template-rows: min-content min-content;
  }
  .sitefooter-2021 .sitefooter__content > * {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .sitefooter-2021 .sitefooter__identity {
    padding-top: 0;
  }
  .sitefooter-2021 .sitefooter__identity {
    grid-column: 3;
    grid-row: 1/span 2;
  }
  .sitefooter-2021 .sitefooter__quicklinks {
    grid-column: 5;
    grid-row: 1/span 2;
  }
  .sitefooter-2021 .sitefooter__social {
    grid-column: 7;
    grid-row: 1;
  }
  .sitefooter-2021 .sitefooter__newsletterlink {
    grid-column: 7;
    grid-row: 2;
    align-self: end;
  }
}
.sitefooter-2021 .sitefooter__social {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sitefooter-2021 a.sitefooter__newsletterlink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font: 14px/23px var(--rtd-text-fontfamily);
  color: #FFFFFF;
}
.sitefooter-2021 .sitefooter__newsletterlink__icon {
  margin-right: 13px;
}
.sitefooter-2021 .sitefooter__newsletterlink__icon .far {
  font-size: 20px;
}
.sitefooter-2021 .sitefooter__socialitem:hover .fab,
.sitefooter-2021 a.sitefooter__newsletterlink:hover .sitefooter__newsletterlink__icon {
  color: var(--color-green);
}

.sitefooter-compact__top {
  background-image: linear-gradient(0deg, #0A893B 13%, #0DB24C 95%);
  color: #FFFFFF;
}

.sitefooter-compact__content {
  display: flex;
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 669px) {
  .sitefooter-compact {
    font-size: 13px;
  }
  .sitefooter-compact__content {
    flex-direction: column;
    padding-bottom: 15px;
  }
  .sitefooter-compact__identity__logo {
    order: 0;
    display: block;
    width: 91px;
    height: 52px;
  }
  .sitefooter-compact__identity {
    order: 1;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .sitefooter-compact__copyright {
    order: 12;
  }
  .sitefooter-compact__spacer1,
  .sitefooter-compact__spacer2 {
    display: none;
  }
  .sitefooter-compact__bottombar {
    height: 37px;
    font: 14px Arial, "Sans-Serif";
  }
  .sitefooter-compact__bottombar__copyright {
    display: none;
  }
}
@media (min-width: 670px) {
  .sitefooter-compact__identity {
    align-self: center;
  }
  .sitefooter-compact__spacer1 {
    flex: 2 0 0px;
  }
  .sitefooter-compact__identity__logo {
    display: block;
    padding-bottom: 15px;
  }
  .sitefooter-compact__spacer2 {
    flex: 3 0 0px;
  }
  .sitefooter-compact__copyright {
    display: none;
  }
  .sitefooter-compact__bottombar {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
    height: 48px;
  }
}
.sitefooter-compact__bottombar {
  color: #333333;
  height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 0; /* so the "|" char can be made invisible when spanning two lines */
}

.sitefooter-compact__bottombar > .metaitem {
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sitefooter-compact__bottombar__copyright,
.sitefooter-compact__bottomlinks__items,
.sitefooter-compact__bottombar:first-line {
  font: 14px Arial, "Sans-Serif";
}

.sitefooter-compact__bottomlinks__items a {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  white-space: nowrap;
}

.sitefooter-compact__bottombar__copyright {
  color: #333333;
  margin-right: 6px;
}

.sitefooter-compact__bottomlinks {
  display: flex;
}

.sitefooter-compact__bottomlinks a {
  color: inherit;
  text-decoration: none;
  padding-left: 8px;
  padding-right: 8px;
}

.sitefooter-compact__bottomlinks a:hover {
  text-decoration: underline;
}

.sitefooter__content {
  color: #FFFFFF;
  text-underline-offset: 2px;
}

.sitefooter__header {
  font: var(--footer-heading-font);
  margin-top: 0;
  margin-bottom: var(--footer-heading-margin);
  color: inherit;
}

.sitefooter__quicklinks .sitefooter__header + a {
  margin-top: -8px;
}

.sitefooter-linkslist a {
  display: flex;
  align-items: baseline;
  color: #FFFFFF;
  text-decoration: none;
  font: var(--footer-linkslist-font);
  padding-top: var(--footer-linkslist-padding-v);
  padding-bottom: var(--footer-linkslist-padding-v);
}

.sitefooter-linkslist a:hover > * {
  text-decoration: underline;
}

.sitefooter-linkslist a::before {
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  content: var(--fa-chevron-right)/"";
  font-size: var(--footer-linkslist-icon-font-size);
  margin-right: var(--footer-linkslist-icon-margin);
  text-decoration: none;
}

.sitefooter__social__links.socialbuttons-horizontal {
  display: flex;
}
.sitefooter__social__links.socialbuttons-horizontal .sitefooter__socialitem + .sitefooter__socialitem {
  margin-left: 13px;
}
.sitefooter__social__links.socialbuttons-horizontal .sitefooter__socialitem__title {
  display: none;
}

.socialbuttons-list {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.socialbuttons-list a.sitefooter__socialitem {
  align-self: start;
  padding-top: var(--sociallinks-list-padding-v);
  padding-bottom: var(--sociallinks-list-padding-v);
}
.socialbuttons-list a.sitefooter__socialitem .icon {
  margin-right: var(--footer-linkslist-icon-margin);
}
.socialbuttons-list .sitefooter__socialitem__title {
  color: #FFFFFF;
  font: var(--footer-linkslist-font);
}
.socialbuttons-list a.sitefooter__socialitem:hover .sitefooter__socialitem__title {
  text-decoration: underline;
}

a.sitefooter__socialitem {
  display: flex;
  text-decoration: none;
}

.sitefooter__socialitem .icon,
.sitefooter__newsletterlink__icon {
  display: block;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--footer-socialbutton-size);
  height: var(--footer-socialbutton-size);
  border-radius: 50%;
  cursor: pointer;
}

.socialbuttons-outline .sitefooter__socialitem .icon {
  border: 1px solid #FFFFFF;
}

.socialbuttons-subtle .sitefooter__socialitem .icon {
  background: #4D9E54;
}

.sitefooter__socialitem .icon {
  color: #FFFFFF;
  font-size: var(--footer-socialbutton-icon-size);
}

.sitefooter__newsletter__icon .far {
  color: #FFFFFF;
  font-size: 18px;
}

.sitefooter__socialitem:hover .icon,
a.sitefooter__newsletterlink:hover .sitefooter__newsletterlink__icon {
  background-color: #FFFFFF;
  color: var(--color-green);
}

.sitefooter__bottombar {
  color: #333333;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 0; /* so the "|" char can be made invisible when spanning two lines */
}

.sitefooter__bottombar > span {
  white-space: nowrap;
}

.sitefooter__bottombar > .metaitem {
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sitefooter__bottombar__copyright,
.sitefooter__bottomlinks__items,
.sitefooter__bottombar:first-line {
  font: var(--footer-bottomlinks-font);
}

.sitefooter__bottomlinks__items a {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sitefooter__panel--bottombar {
  margin-left: -8px;
}

.sitefooter__bottombar__copyright {
  color: #333333;
  margin-right: 6px;
}

/*
.sitefooter__bottomlinks
{
  display: flex;
}

// No design available for when the items don't fit on mobile...
// But at least let them wrap instead of stretching the page.
.sitefooter__bottomlinks__items
{
  display: flex;
  flex-wrap: wrap;
}
*/
.sitefooter__bottombar__copyright {
  padding-left: 8px;
  padding-right: 8px;
}

.sitefooter__bottomlinks a {
  color: inherit;
  text-decoration: none;
  padding-left: 8px;
  padding-right: 8px;
}

.sitefooter__bottomlinks a:hover {
  text-decoration: underline;
}

.sitefooter-2024 {
  --footer-heading-font: bold 18px/22px var(--rtd-heading-fontfamily);
  --footer-heading-margin: 11px;
  --footer-linkslist-font: 17px/20px var(--rtd-heading-fontfamily);
  --footer-linkslist-padding-v: 3px;
  --footer-linkslist-icon-margin: 12px;
  --footer-linkslist-icon-font-size: 14px;
  --sociallinks-list-padding-v: 4px;
  --footer-about-font: 13px/18px var(--rtd-text-fontfamily);
  --footer-bottomlinks-font: 13px var(--rtd-text-fontfamily);
  --footer-socialbutton-size: 24px;
  --footer-socialbutton-icon-size: 13px;
}
.sitefooter-2024 .identity-wig {
  font: var(--footer-heading-font);
}
.sitefooter-2024 .identity-wig img {
  height: 12px;
  vertical-align: super;
  margin-right: 3px;
}
.sitefooter-2024 .identity-p3d {
  width: max-content;
  font: bold 16px var(--rtd-heading-fontfamily);
}
.sitefooter-2024 .identity-p3d img {
  display: block;
  width: 132px;
}
.sitefooter-2024 .identity-p3d__wiglogo {
  margin-top: 7px;
}
.sitefooter-2024 .identity-p3d__dates {
  display: flex;
  align-items: center;
  justify-content: center;
  font: 12px var(--rtd-heading-fontfamily);
}
.sitefooter-2024 .identity-p3d__dates__day {
  margin-top: 4px;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #FFFFFF;
  color: var(--footer-panel-background);
  margin-left: 4px;
  margin-right: 4px;
}
.sitefooter-2024 .identity-p3d__dates__day:first-child {
  margin-left: 0;
}
.sitefooter-2024 .sitefooter__panel__toggle {
  background: none;
  border: 0;
  color: inherit;
  padding: 0;
  cursor: pointer;
}
.sitefooter-2024 .sitefooter__panel--rijksoverheid {
  color: #333333;
  font: var(--footer-about-font);
}
.sitefooter-2024 .sitefooter__bottombar__copyright {
  color: var(--footer-bottombar-color);
}
.sitefooter-2024 .sitefooter__bottomlinks {
  color: var(--footer-bottombar-color);
}
.sitefooter-2024 .sitefooter__panel--language {
  color: var(--footer-bottombar-color);
  font: var(--footer-bottomlinks-font);
}
.sitefooter-2024 .sitefooter__panel--language__prefix {
  font-weight: bold;
  margin-right: 8px;
}
.sitefooter-2024 .sitefooter__panel--language a {
  color: var(--footer-bottombar-color);
  text-decoration: none;
}
.sitefooter-2024 .sitefooter__panel--language .selected {
  text-decoration: underline;
}
.sitefooter-2024 .sitefooter__panel--language a:hover {
  text-decoration: underline;
}
.sitefooter-2024 .sitefooter__panel--language .seperator {
  margin: 0 5px;
}
@media (max-width: 669px) {
  .sitefooter-2024 {
    --footer-heading-margin: 5px;
    --footer-bottombar-color: #757575;
  }
  .sitefooter-2024 .sitefooter__panel:first-child {
    margin-top: 0px;
  }
  .sitefooter-2024 .sitefooter__content__main {
    display: flow-root; /* encapsulate margins */
    padding-top: 10px;
    padding-bottom: 25px;
    background: var(--footer-panel-background);
    --rtd-link-color: #FFFFFF;
  }
  .sitefooter-2024 .sitefooter__panel--identity {
    display: none;
  }
  .sitefooter-2024 .sitefooter__panel__toggle {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    position: relative;
    margin-top: 0 !important;
    min-height: 32px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .sitefooter-2024 .sitefooter__panel__toggle h2 {
    margin: 0;
  }
  .sitefooter-2024 .sitefooter__panel--mobilewrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
  .sitefooter-2024 .sitefooter__panel--mobilewrapper:not([hidden]) {
    margin-top: 4px;
    padding-bottom: 25px;
  }
  .sitefooter-2024 .sitefooter__panel__toggle:hover {
    background: rgba(0, 0, 0, 0.2);
  }
  .sitefooter-2024 .sitefooter__panel__toggle::after {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    display: block;
    content: "";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
  .sitefooter-2024 .sitefooter__panel--relatedsites::after {
    position: absolute;
    left: 15px;
    right: 15px;
    display: block;
    content: "";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
  .sitefooter-2024 .sitefooter__panel:first-child .sitefooter__panel__toggle::after,
  .sitefooter-2024 .sitefooter__panel--identity + * .sitefooter__panel__toggle::after {
    display: none;
  }
  .sitefooter-2024 .sitefooter__panel__toggle:hover::after {
    left: 0;
    right: 0;
  }
  .sitefooter-2024 .sitefooter__panel__toggle .icon {
    margin-left: auto;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.21);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background var(--site-transitions-duration);
  }
  .sitefooter-2024 .sitefooter__panel__toggle .icon:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .sitefooter-2024 .sitefooter__panel__toggle .icon::before {
    font: var(--menubar-bar-toggler-font);
    content: var(--menubar-bar-toggler-content);
    font-size: var(--menubar-bar-toggler-size);
  }
  .sitefooter-2024 .sitefooter__panel--language {
    margin-top: 25px;
    padding-left: 15px;
    padding-right: 15px;
    color: #FFFFFF;
    font-family: var(--rtd-heading-fontfamily);
  }
  .sitefooter-2024 .sitefooter__panel--language a {
    color: inherit;
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid {
    padding-left: 15px;
    padding-right: 15px;
    margin: 0;
  }
  .sitefooter-2024 .sitefooter__panel--bottombar {
    margin-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 15px;
  }
}
@media (min-width: 670px) {
  .sitefooter-2024 {
    --footer-bottombar-color: #757575;
  }
  .sitefooter-2024 .sitefooter__panel--mobilewrapper {
    display: contents;
  }
  .sitefooter-2024 .sitefooter__panel--language, .sitefooter-2024 .sitefooter__panel--bottombar {
    align-self: baseline;
  }
}
@media (min-width: 670px) and (max-width: 974px) {
  .sitefooter-2024 .sitefooter__content {
    display: grid;
    grid-template-columns: calc((100% - 970px) / 2) 15px 1fr 50px 1fr 50px 1fr 15px calc((100% - 970px) / 2);
    grid-template-rows: min-content min-content;
  }
  .sitefooter-2024 .sitefooter__content__main {
    display: contents;
  }
  .sitefooter-2024 .sitefooter__content::before {
    content: "";
    grid-row: 1/span 2;
    grid-column: 1/-1;
    background: var(--footer-panel-background);
  }
  .sitefooter-2024 .sitefooter__panel {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .sitefooter-2024 .sitefooter__panel--identity {
    grid-row: 1;
    grid-column: 3;
  }
  .sitefooter-2024 .sitefooter__panel--quicklinks {
    grid-row: 2;
    grid-column: 3;
  }
  .sitefooter-2024 .sitefooter__panel--social {
    grid-row: 2;
    grid-column: 5;
  }
  .sitefooter-2024 .sitefooter__panel--relatedsites {
    grid-row: 2;
    grid-column: 7;
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid {
    grid-row: 2;
    grid-column: 9;
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid {
    display: contents;
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid img {
    grid-row: 3;
    grid-column: 3;
    height: 100px;
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid .rtdcontent {
    grid-row: 3;
    grid-column: 5/span 5;
    align-self: center;
  }
  .sitefooter-2024 .sitefooter__panel--language {
    grid-row: 4;
    grid-column: 3;
    color: #757575;
  }
  .sitefooter-2024 .sitefooter__panel--bottombar {
    grid-row: 4;
    grid-column: 5/span 5;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sitefooter-2024 .sitefooter__bottomlinks a {
    margin-left: -8px;
  }
}
@media (min-width: 975px) {
  .sitefooter-2024 .sitefooter__content {
    display: grid;
    grid-template-columns: calc((100% - 970px) / 2) 15px 1fr 50px 1fr 50px 1fr 50px 1fr 15px calc((100% - 970px) / 2);
    grid-template-rows: min-content min-content;
  }
  .sitefooter-2024 .sitefooter__content__main {
    display: contents;
  }
  .sitefooter-2024 .sitefooter__content::before {
    content: "";
    grid-row: 1;
    grid-column: 1/-1;
    background: var(--footer-panel-background);
  }
  .sitefooter-2024 .sitefooter__panel {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .sitefooter-2024 .sitefooter__panel--identity {
    grid-row: 1;
    grid-column: 3;
  }
  .sitefooter-2024 .sitefooter__panel--quicklinks {
    grid-row: 1;
    grid-column: 5;
  }
  .sitefooter-2024 .sitefooter__panel--social {
    grid-row: 1;
    grid-column: 7;
  }
  .sitefooter-2024 .sitefooter__panel--relatedsites {
    grid-row: 1;
    grid-column: 9;
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid {
    grid-row: 1;
    grid-column: 11;
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid {
    display: contents;
    color: #333333;
    font: var(--footer-about-font);
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid img {
    grid-row: 2;
    grid-column: 3;
    height: 100px;
  }
  .sitefooter-2024 .sitefooter__panel--rijksoverheid .rtdcontent {
    grid-row: 2;
    grid-column: 5/span 5;
    align-self: center;
  }
  .sitefooter-2024 .sitefooter__panel--language {
    grid-row: 3;
    grid-column: 3;
  }
  .sitefooter-2024 .sitefooter__panel--bottombar {
    grid-row: 3;
    grid-column: 5/span 5;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .sitefooter-2024 .sitefooter__bottomlinks {
    display: inline-flex;
  }
}

@page {
  size: 210mm 297mm;
  /* Chrome sets own margins, we change these printer settings */
  margin: 27mm 16mm 27mm 16mm;
}
@media print {
  #slidemenu-container {
    display: none;
  }
  .siteheader-top__content > * {
    display: none !important;
  }
  .siteheader-top__content .siteheader-identity {
    display: block !important;
  }
  .siteheader-menubar,
  .themepanel--header,
  .pageheader-bottomrow,
  .sitefooter,
  #dialogscontainer,
  .widgets_column,
  .sharecomponent,
  #main_widgetsgrid {
    display: none !important;
  }
}
.consilio--highlight {
  font-weight: bold;
  text-decoration: underline;
}

.widget--checklist .widget__title,
.ctalinks h3 {
  padding-bottom: 12px;
  border-bottom: 1px solid #DADADA;
  margin-bottom: 15px;
}

.embeddedobject.ctalinks ul {
  margin: 0;
  padding: 0;
}

.embeddedobject.ctalinks:not(.largeicons) li {
  list-style: none;
  display: flex;
}

.embeddedobject.ctalinks:not(.largeicons) li::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f0a9";
  font-size: 30px;
  color: var(--color-green);
  flex: none;
  width: 43px;
}

.embeddedobject.ctalinks li + li {
  margin-top: 10px;
}

.embeddedobject.ctalinks a {
  display: inline-block;
  vertical-align: top;
  color: #333333;
  text-decoration: none;
  font-size: 15px;
}

.embeddedobject.ctalinks a > strong {
  display: block;
  margin: -4px 0 2px;
  font: 600 19px/24px "TypoPRO Dosis";
  color: var(--color-green-small);
}

.embeddedobject.ctalinks.largeicons li {
  margin-left: 70px; /* 64 + 6 */
}

/*.widget.widget-downloadsandlinks*/
.widget__description.rtdcontent > * {
  color: #333333;
  font: 15px/26px Arial;
}

.widget__description + * {
  margin-top: 5px;
}

.widget__linkslist,
.pageindexwidget__items {
  list-style: none;
  color: var(--color-green);
  padding: 0;
  margin: 0;
}

* + .widget__linkslist {
  margin-top: 20px;
}

.widget__linkslist a:hover span,
.pageindexwidget__items a:hover span {
  text-decoration: underline;
}

a.widget__showmoreitemslink {
  margin-top: 30px;
}

.widget__linkslist li a,
.pageindexwidget__items li a,
a.widget__showmoreitemslink {
  display: flex;
  text-decoration: none;
  font: 500 16px/20px var(--rtd-heading-fontfamily);
  color: #218729;
  padding-top: 4px;
  padding-bottom: 4px;
}

.widget__linkslist li a::before,
a.widget__showmoreitemslink::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f138";
  font-size: 23px;
  margin-right: 8px;
}

.pageindexwidget__items li a {
  color: #333333;
}

.widget.links .widget__linkslist a::before,
.widget.news_links .widget__linkslist a::before,
.pageindex--bullets .pageindexwidget__items li a::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 18px;
  margin-top: 1px;
  margin-right: 15px;
  color: var(--color-green);
}

.pageindex--numbered .pageindexwidget__items {
  list-style: number;
  font: 500 17px/28px var(--rtd-heading-fontfamily);
  margin-left: 16px;
}

.pageindex--numbered .pageindexwidget__items li a {
  color: var(--color-green);
  font: 500 17px/28px var(--rtd-heading-fontfamily);
}

.widget.pageindex {
  padding: 15px 0 0 0;
}

.widget.pageindex .title {
  padding-left: var(--widget-padding-h);
  padding-right: var(--widget-padding-h);
  padding-bottom: 10px;
  border-bottom: 1px solid #DADADA;
  margin: 0;
}

.pageindexwidget__items {
  padding-top: 10px;
  padding-bottom: 25px;
  padding-left: var(--widget-padding-h);
  padding-right: var(--widget-padding-h);
}

.sectionmenu-mobile-wrapper {
  /* Fix Safari issue of <select> causing an overflow
     the currently selection option has a long title.
     (even contain: size; does not work)
  */
  contain: layout; /* iOS 16+ */
  /*
  padding: 5px;
  margin: -5px;
  overflow: hidden;
  */
}

#sectionmenu-mobile {
  text-overflow: ellipsis; /* Apply ellipsis to selected option title. Works in Chrome and Firefox, not in Safari yet */
}

/*********************************************

'Read more' links

*********************************************/
.widget a.showmoreitemslink {
  margin-top: 30px;
  display: inline-block;
  font: 14px Arial;
  color: #218729; /* $widgetblock-action-color */
}
.widget a.showmoreitemslink::before {
  content: "» ";
}
.birthdate.birthdate input {
  height: 38px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: center;
}

.widget.custom_nieuwewetgeving form .ctabutton,
.widget.custom_checkaowleeftijd form .ctabutton {
  height: 38px;
}