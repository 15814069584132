/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.widget--ctablocks {
  background-color: var(--color-panel-background);
  padding-top: 33px;
  padding-bottom: 41px;
}

.ctablocks__title.ctablocks__title,
.ctablocks__items {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 799px) {
  .ctablocks__item {
    margin-top: 22px;
  }
}
@media (min-width: 800px) {
  .ctablocks__items {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 22px;
    gap: 22px;
  }
}
.ctablocks__item {
  position: relative;
  display: block;
  padding: 28px 20px;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  text-decoration: none;
  padding-right: 90px;
}

.p3dorganizations .ctablocks__item {
  display: flex;
  align-items: center;
}

.ctablocks__item:focus,
.ctablocks__item:hover {
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}

.ctablocks__item::after {
  flex: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f0a9";
  font-size: 36px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 27px;
}

/*
.ctablocks__item__title
{
  color: var(--color-green-heading);
  font: bold 28px/29px var(--rtd-heading-fontfamily);
  word-break: break-word;
}

.ctablocks__item__title + .ctablocks__item__description
{
  margin-top: 6px;
}
*/
.ctablocks__item__description {
  color: #333333;
  font: 18px/28px var(--rtd-heading-fontfamily);
}

@media (max-width: 640px) {
  .ctablocks__item {
    padding: 20px 15px;
    padding-right: 83px;
  }
  .ctablocks__item::after {
    right: 20px;
  }
  .ctablocks__item__description {
    line-height: 25px;
  }
}
.p3dorganizations .ctablocks__item img {
  display: block;
  max-width: 100%;
  max-height: 200px;
}

.p3dorganizations .ctablocks__item__title {
  font: normal 14px/24px Arial, Sans-Serif;
}

.p3dorganizations .ctablocks__item img + .ctablocks__item__title {
  margin-top: 10px;
}