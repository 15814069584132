/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
#summaryfilter .fieldgroup {
  width: 238px;
}

#summaryfilter .fieldgroup:after {
  clear: both;
  content: "";
  display: block;
}

#summaryfilter .fieldgroup + .fieldgroup {
  margin-top: 15px;
}

#summaryfilter .fieldgroup > strong {
  display: block;
  font: 400 18px/24px "TypoPRO Dosis", Arial;
  padding-bottom: 5px;
}

#summaryfilter .fieldgroup label {
  color: #666;
  font-size: 14px;
}

#summaryfilter .fieldgroup label .info {
  color: #a9a9a9; /* #0db24c; */
  padding: 3px 5px;
  width: 20px;
  height: 20px;
  font-size: 18px;
  display: inline-block;
  position: relative;
  top: 1px;
}

#summaryfilter input[type=search],
#summaryfilter select {
  width: 100%;
  max-width: 100%;
}

#summaryfilter button {
  margin-top: 5px;
}

#summaryfilter .fieldgroup--date > select + select {
  margin-top: 12px;
}

#summaryfilter button[type=submit] {
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: var(--button-color);
  color: #FFFFFF;
  justify-content: space-between;
  width: 100%;
  height: 44px;
  font: 18px/26px var(--rtd-heading-fontfamily);
}
#summaryfilter button[type=submit]:hover {
  background-color: var(--button-color-hover);
}
#summaryfilter button[type=submit]:after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-search);
  content: var(--fa-search)/"";
  font-size: 18px;
  vertical-align: middle;
  margin-left: 35px;
}

.pageitemsfilters .taglist__item input {
  margin-right: 15px;
}

/* search results feedback */
#rpc_feedback {
  font: 500 18px/28px var(--rtd-heading-fontfamily);
}