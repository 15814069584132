/*
@mixin wh-form-hidenativecontrol
{
  // display: none or visibility: hidden; will prevent focus!!
  position: absolute !important; // to not mess with flex containers
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  opacity: 0;

  margin: 0;
}

@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
html.page--p3dtool {
  --button-height: 44px;
}
@media (min-width: 480px) {
  html.page--p3dtool .wh-share[data-network=whatsapp] {
    display: none;
  }
}
html.page--p3dtool #content-wrapper > .effecttool__root {
  max-width: none;
  padding-left: 0;
  padding-right: 0;
}
html.page--p3dtool .effecttool__loading {
  /*
  We zijn niet geinterreseerd in het "loading" bericht omdat:
  a) inladen zowiezo snel gaat
  b) we (tenzij ?start in de URL staat) een beginscherm laten zien, dus de tool hoeft er nog niet direct te staan
  */
  display: none !important;
}
html.page--p3dtool .effecttool__loaded.effecttool__loaded {
  display: none !important;
}
@media (max-width: 750px) {
  html.page--p3dtool {
    --button-font: 18px/26px var(--rtd-heading-fontfamily);
    --button-height: 40px;
  }
}

/* Hide the effecttool and special header for it until:
   - The start button has been used (on the start page)
   - The URL has "begin" on it as parameter
*/
.p3dtool2__questionpage__heading {
  display: none;
}

html.starttool .p3dtool2__startpage {
  display: none;
}
html.starttool .p3dtool2__questionpage__heading {
  display: block;
}
html.starttool .effecttool__loaded.effecttool__loaded {
  display: block !important;
}

.effecttool--isresults .p3dtool2__questionpage__heading.p3dtool2__questionpage__heading {
  display: none;
}

.p3dtool__otheroptionscta {
  background: var(--widget-highlight-background);
  text-align: center;
  padding: 27px 60px 30px 60px;
  --rtd-heading-color: var(--color-green);
}
@media (max-width: 599px) {
  .p3dtool__otheroptionscta {
    --rtd-text-font: 15px var(--rtd-heading-fontfamily);
    --rtd-h2-font: 600 18px var(--rtd-heading-fontfamily);
    margin-top: 30px;
    border-radius: 30px;
    width: 90%;
  }
}
@media (min-width: 600px) {
  .p3dtool__otheroptionscta {
    --rtd-text-font: 16px var(--rtd-heading-fontfamily);
    --rtd-h2-font: 600 20px var(--rtd-heading-fontfamily);
    margin-top: 60px;
    border-radius: 30px;
    max-width: max-content !important;
    margin-left: auto;
    margin-right: auto;
  }
}

.p3dtool__themaslist {
  border: 1px solid #AFAFAF;
  background: #F9F9F9;
  border-radius: 8px;
  padding: 25px 20px;
}

.p3dtool__themaslist h2 {
  margin: 0 0 10px 0;
}

.p3dtool__themaslist ul {
  margin: 0;
  padding: 0 0 0 20px;
}

.p3dtool__themaslist,
.p3dtool__startpage__content,
.p3dtool2__questionpage__heading,
.effecttool__loaded > .effecttool__progressholder,
.effecttool__loaded > .effecttool__pages > .effecttool__opening,
.effecttool__loaded > .effecttool__pages > .effecttool__question,
.effecttool__results__header {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
}

.p3dtool2__questionpage__heading .heading {
  color: var(--color-green);
  font: bold 24px/26px var(--rtd-heading-fontfamily);
}

@media (max-width: 750px) {
  .p3dtool2__questionpage__heading > .heading + p {
    margin-top: 5px;
  }
  html.p3dtool2--results .effecttool__root {
    padding-bottom: 30px;
    margin-bottom: 0;
  }
}
@media (min-width: 751px) {
  .p3dtool2__questionpage__heading > .heading + p {
    margin-top: 8px;
  }
}
.p3dtool2__aowleeftijd--cannothelp {
  display: none;
}

.effecttool__root.too_young .p3dtool2__aowleeftijd--cannothelp,
.effecttool__root.too_old .p3dtool2__aowleeftijd--cannothelp {
  display: block;
}
.effecttool__root.too_young .p3dtool2__aowleeftijd--info, .effecttool__root.too_young .p3dtool2__results__content,
.effecttool__root.too_young .effecttool__groups,
.effecttool__root.too_young .p3dtool2__sendresultslink,
.effecttool__root.too_old .p3dtool2__aowleeftijd--info,
.effecttool__root.too_old .p3dtool2__results__content,
.effecttool__root.too_old .effecttool__groups,
.effecttool__root.too_old .p3dtool2__sendresultslink {
  display: none;
}
.effecttool__root.too_young .p3dtool2__questionpage__heading, .effecttool__root.too_young .effecttool__progressholder,
.effecttool__root.too_young .effecttool__question,
.effecttool__root.too_old .p3dtool2__questionpage__heading,
.effecttool__root.too_old .effecttool__progressholder,
.effecttool__root.too_old .effecttool__question {
  display: none;
}
.effecttool__root.too_young .effecttool__results,
.effecttool__root.too_old .effecttool__results {
  display: block; /* If we skipped all questions, for results page to appear */
}