.companylist__blocks {
  list-style-type: none;
  margin: 18px 0 0 0;
  padding: 0;
}

html.layout_tablet .companylist__img,
html.layout_mobile .companylist__img {
  width: auto !important;
  max-height: 80px;
  display: block;
  margin: 0 auto;
}

.companylist__block {
  display: block;
  height: 157px;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: 7px;
  color: #FFFFFF;
  font: normal 14px/24px Arial, Sans-Serif;
  text-decoration: none;
  position: relative;
}

html.layout_mobile .companylist__block {
  height: auto;
}

.companylist__block {
  padding: 15px; /* anders past het allemaal niet */
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
  background: none;
  background-color: #FFFFFF;
  color: #333333;
}

.companylist__block img {
  display: block;
}

.companylist__block strong {
  position: absolute;
  left: 15px;
  bottom: 15px;
  line-height: 20px;
  display: block;
  font-weight: normal;
}

html.layout_mobile .companylist__block strong {
  position: static;
}