.js-maxhypotheek-widget {
  --rekentool-rood: #D61824;
  --formcontrols-height: 48px; /* instead of 44px */
  --formcontrols-border-color: #C5C5C5; /* instead of #333333; */
  --formcontrols-radius: 4px; /* instead of 3px */
  --textcontrols_textcolor: #333333;
  --formcontrols-border-color-error: var(--rekentool-rood);
  --button-height: 46px;
  --textcontrols_placeholdercolor: #767676;
  --formlayout-label-font: bold 16px/26px var(--rtd-text-fontfamily);
  --pagegrid-col-default: 1 / -1;
  --formlayout-prefix-background: #F5F5F5;
  --formlayout-prefix-border-color: #C5C5C5;
  --formlayout-prefix-color: #218729;
  --formlayout-infotext-background: var(--color-sublegrey);
  /* NOTE: override .wh-form__fieldgroup--error .wh-form__infotextwrapper.wh-form__infotextwrapper setting background: none; */
}
.js-maxhypotheek-widget input::selection {
  color: #000000;
  background-color: #DDDDDD;
}
.js-maxhypotheek-widget [data-wh-form-pagerole=thankyou]:not(.wh-form__page--hidden) + .wh-form__navbuttons {
  display: none;
}
.js-maxhypotheek-widget hr {
  border: 0;
  border-top: 1px solid #C5C5C5;
  width: 100%; /* needed for the flex layout version of the form */
  margin: 25px 0;
}
.js-maxhypotheek-widget .rekentool-restart {
  background: none;
  border: none;
  display: flex;
  color: var(--color-green);
  font: 18px var(--fontfamily-heading);
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}
.js-maxhypotheek-widget .wh-form__label {
  word-wrap: break-word; /* upon overflow with a word without a nice (soft)break point, allow breaking up anyway */
}
.js-maxhypotheek-widget .wh-form__error {
  margin-top: 14px;
  background: var(--formlayout-fieldgroup-bgcolor-error);
  border-radius: 8px;
  width: 100%;
  padding: 14px 19px 14px 19px;
}
.js-maxhypotheek-widget .wh-form__infotextwrapper.wh-form__infotextwrapper.wh-form__infotextwrapper,
.js-maxhypotheek-widget .result__elaboration .wh-form__richtext {
  margin-top: 14px;
  background: var(--formlayout-infotext-background);
  border-radius: 8px !important;
  width: 100%;
  padding: 14px 19px 14px 19px;
}
.js-maxhypotheek-widget .wh-form__infotextwrapper::before {
  display: none;
}
.js-maxhypotheek-widget .wh-form .wh-form__fieldgroup--error {
  background: none; /* cancel the background set by the normal form layout styling */
  --formlayout-prefix-background: var(--rekentool-rood);
  --formlayout-prefix-color: #FFFFFF;
  --formlayout-prefix-border-color: var(--rekentool-rood);
}
.js-maxhypotheek-widget .rekentool-resultpanel {
  background: rgba(40, 165, 50, 0.07);
  border-radius: 8px !important;
  width: 100%;
  padding: 14px 19px 14px 19px;
  padding-top: 20px;
  font: bold 18px var(--fontfamily-heading);
  text-align: center;
}
.js-maxhypotheek-widget .rekentool-resultpanel-amount {
  display: block;
  margin-top: 5px;
  color: var(--color-green);
  font: bold 36px var(--fontfamily-heading);
}
.js-maxhypotheek-widget .wh-form__fieldgroup--richtext .wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: block;
}
.js-maxhypotheek-widget .wh-form__fieldgroup--richtext .wh-form__richtext > hr:first-child {
  margin-top: 25px;
}
@media (min-width: 768px) {
  .js-maxhypotheek-widget {
    /* The actual error message */
  }
  .js-maxhypotheek-widget .wh-form__page {
    display: grid;
    grid-template-columns: var(--formlayout-label-width) 1fr;
  }
  .js-maxhypotheek-widget .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
    grid-column: 1/-1;
    display: grid;
    grid-template-columns: var(--formlayout-label-width) 1fr;
    grid-template-columns: subgrid;
  }
  .js-maxhypotheek-widget .wh-form__fieldgroup--richtext:not(.wh-form__fieldgroup--hidden) {
    grid-column: 1/-1;
    display: block;
  }
  .js-maxhypotheek-widget .wh-anchor {
    position: absolute; /* take out of flow so the first .wh-form__fields can be on the same row as it's .wh-form__label */
  }
  .js-maxhypotheek-widget .wh-form__label {
    grid-column: 1;
    grid-row: 1/10; /* so it won't stretch the first row */
    padding-right: 15px;
  }
  .js-maxhypotheek-widget .wh-form .wh-form__fieldgroup > .wh-form__label {
    min-width: 0;
    max-width: none;
  }
  .js-maxhypotheek-widget .wh-form__fieldgroup.display-stacked:not(.wh-form__fieldgroup--hidden) {
    display: block;
    margin-bottom: 5px !important;
  }
  .js-maxhypotheek-widget .wh-form__fieldgroup.display-stacked:not(.wh-form__fieldgroup--hidden) .wh-form__label {
    margin-bottom: 7px;
    text-wrap: pretty;
    text-wrap: balance;
  }
  .js-maxhypotheek-widget .wh-form__fields {
    display: contents;
  }
  .js-maxhypotheek-widget .wh-form__fields > .wh-form__fieldline {
    grid-column: 2;
  }
  .js-maxhypotheek-widget .wh-form__error {
    grid-column: 1/-1;
  }
  .js-maxhypotheek-widget .wh-form__infotextwrapper {
    grid-column: 1/-1;
  }
}
.js-maxhypotheek-widget .rekentool-resultpanel {
  grid-column: 1/-1;
}
.js-maxhypotheek-widget .groupedprefix > .wh-form__fields > .wh-form__fieldline > .wh-form__prefix {
  min-width: 28px;
  align-self: stretch;
  padding: 0 3px;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--formlayout-prefix-background);
  border: 1px solid var(--formlayout-prefix-border-color);
  border-right: 0;
  border-radius: var(--formcontrols-radius) 0 0 var(--formcontrols-radius);
  color: var(--formlayout-prefix-color);
}
.js-maxhypotheek-widget .groupedprefix > .wh-form__fields > .wh-form__fieldline > .wh-form__prefix + input {
  border-radius: 0 var(--formcontrols-radius) var(--formcontrols-radius) 0;
  min-width: 100px;
}
.js-maxhypotheek-widget .groupedprefix > .wh-form__fields > .wh-form__fieldline > .wh-form__prefix + input:hover,
.js-maxhypotheek-widget .groupedprefix > .wh-form__fields > .wh-form__fieldline > .wh-form__prefix + input:focus {
  outline: 0;
}
.js-maxhypotheek-widget .groupedprefix > .wh-form__fields > .wh-form__fieldline > .wh-form__prefix + input:focus {
  border-left: 0;
}