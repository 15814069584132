/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
html.page--home2021 h1.heading1,
html.page--home2021 .themecarrousel__title,
html.page--home2021 .themepanel__title {
  text-align: center;
}
@media (min-width: 960px) {
  html.page--home2021 h1.heading1::after,
  html.page--home2021 .themecarrousel__title::after,
  html.page--home2021 .themepanel__title::after {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    font-size: 30px;
    content: var(--fa-chevron-down);
    margin-left: 16px;
  }
}