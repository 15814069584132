/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
@font-face {
  font-weight: bold;
  font-family: "VAGRoundedStd";
  /*  src: url("../../web/fonts/VAGRoundedStd-Bold.ttf"); - FIXME: SASS crashes with .otf so we renamed to .ttf OMG */
  src: url("../../../weekvanhetgeld/web/fonts/vagroundedstd-bold-webfont.woff") format("woff");
  /*
  font-family: 'vag_rounded_std_thinbold';
  src: url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.eot);
  src: url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.eot?#iefix) format('embedded-opentype'), url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.woff) format('woff'), url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.ttf) format('truetype'), url(/profiles/weekvanhetgeld/themes/wvhg/fonts/vagroundedstd-bold-webfont.svg#vag_rounded_std_thinbold) format('svg');
  font-weight: normal;
  font-style: normal;
  */
}
.pageheader--wvhg-thema2024 {
  grid-template-rows: min-content min-content min-content;
  color: #FFFFFF;
  /**** header text & quicklinks below header */
  /**** header text & quicklinks overlapping the header */
  --pageheader-content-overlap-height: 80px !important;
  /*
  1078 x 967
  37
  */
}
.pageheader--wvhg-thema2024 .pageheader__custombar {
  grid-column: 1;
  grid-row: 1;
  background-color: var(--color-purple);
}
.pageheader--wvhg-thema2024 .headerbutton:not(:hover) {
  border-color: #FFFFFF !important;
  color: #FFFFFF !important;
}
.pageheader--wvhg-thema2024.pageheader--withtext .pageheader__custombar {
  padding-bottom: 110px;
}
@media (max-width: 879px) {
  .pageheader--wvhg-thema2024 {
    --pageheader-content-overlap-height: 35px !important;
  }
  .pageheader--wvhg-thema2024.pageheader--withtext .pageheader__custombar {
    padding-bottom: 40px;
  }
  .pageheader--wvhg-thema2024 .pageheader__text {
    margin-bottom: 30px;
  }
}
.pageheader--wvhg-thema2024 .quicklinks {
  box-shadow: 0 0 9px rgba(255, 255, 255, 0.35);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 0;
  border-radius: 5px;
}
.pageheader--wvhg-thema2024 .pageheader__custombar__content {
  width: 100%;
  max-width: var(--contentarea-width);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: min(10vw, 32px);
}
.pageheader--wvhg-thema2024 .pageheader__slogan {
  white-space: nowrap;
  font: bold 55px VAGRoundedStd;
  --available-space: min(100vw, 1200px);
  font-size: calc(var(--available-space) * 0.042);
  letter-spacing: 1px;
}
.pageheader--wvhg-thema2024 .pageheader__slogan .pageheader__description {
  margin-top: 10px;
}
.pageheader--wvhg-thema2024 .pageheader__slogan .pageheader__description > p {
  white-space: normal;
  text-wrap: pretty;
  --rtd-text-font: 16px/22px var(--rtd-text-fontfamily);
  font: var(--rtd-text-font);
}
.pageheader--wvhg-thema2024 .pageheader__slogan, .pageheader--wvhg-thema2024 .pageheader__date-and-url {
  padding: 15px 0;
}
.pageheader--wvhg-thema2024 .siteheader-wvhg__when {
  background-color: var(--color-theme-background);
  font: bold 25px VAGRoundedStd;
  font-size: clamp(16px, 2vw, 25px);
  letter-spacing: 1px;
  padding: 10px 20px;
  border-radius: 25px;
  white-space: nowrap;
}
@media (min-width: 850px) {
  .pageheader--wvhg-thema2024 .pageheader__custombar__content {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .pageheader--wvhg-thema2024 .pageheader__date-and-url {
    text-align: center;
  }
  .pageheader--wvhg-thema2024 .siteheader-wvhg__when {
    background-color: var(--color-theme-background);
    font: bold 25px VAGRoundedStd;
    font-size: clamp(16px, 2vw, 25px);
    letter-spacing: 1px;
    padding: 10px 20px;
    border-radius: 25px;
    white-space: nowrap;
  }
  .pageheader--wvhg-thema2024 .pageheader__mascotte {
    margin-right: -1.3%;
    margin-bottom: -1%;
    margin-left: -1%;
    margin-top: 15px;
    margin-bottom: 15px; /* WIG page header */
    min-width: 140px;
    height: 100%;
    max-height: 250px;
    height: auto;
    width: auto;
    object-fit: contain;
    flex: none;
  }
}
@media (min-width: 850px) and (max-width: 1000px) {
  .pageheader--wvhg-thema2024 .pageheader__custombar__content {
    padding: 0 15px;
  }
  .pageheader--wvhg-thema2024 .pageheader__slogan {
    font-size: calc(var(--available-space) * 0.04) !important;
  }
}
@media (max-width: 849px) {
  .pageheader--wvhg-thema2024 .pageheader__custombar__content {
    margin-top: 13px;
    margin-bottom: 13px;
    padding: 10px 0 5px 15px;
    overflow: hidden;
    display: grid;
    grid-template-column: 1fr min-content;
    grid-template-rows: min-content min-content min-content;
    column-gap: 0;
    position: relative;
  }
  .pageheader--wvhg-thema2024 .pageheader__custombar__content::before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: linear-gradient(to right, #641c76 210px, rgba(100, 28, 118, 0) 400px);
    content: "";
  }
  .pageheader--wvhg-thema2024 .pageheader__slogan, .pageheader--wvhg-thema2024 .pageheader__date-and-url {
    z-index: 3;
  }
  .pageheader--wvhg-thema2024 .pageheader__slogan {
    grid-column: 1;
    grid-row: 1;
    font-size: calc(var(--available-space) * 0.065);
  }
  .pageheader--wvhg-thema2024 .pageheader__date-and-url {
    grid-column: 1;
    grid-row: 2;
    display: flex;
    flex-direction: column;
  }
  .pageheader--wvhg-thema2024 .pageheader__date-and-url .siteheader-wvhg__when {
    order: 5;
  }
  .pageheader--wvhg-thema2024 .siteheader-wvhg__when {
    margin-top: 15px;
    align-self: start;
    background-color: var(--color-theme-background);
    font: bold 25px VAGRoundedStd;
    font-size: clamp(16px, 2vw, 25px);
    letter-spacing: 1px;
    padding: 7px 18px 6px 18px;
    border-radius: 25px;
    white-space: nowrap;
  }
  .pageheader--wvhg-thema2024 .siteheader-top__mobile {
    max-width: 1200px;
  }
  .pageheader--wvhg-thema2024 .pageheader__mascotte {
    grid-column: 2;
    grid-row: 1/span 3;
  }
  .pageheader--wvhg-thema2024 .pageheader__mascotte {
    margin-right: -1.3%;
    margin-bottom: -1%;
    margin-left: -1%;
    margin-bottom: 0;
    min-width: 150px;
    height: 100%;
    max-height: 220px;
    height: auto;
    width: auto;
    object-fit: contain;
  }
}