/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/* horizontal perfect scrollbar */
.themecarrousel-scrollbar-container {
  display: none;
  position: relative;
  height: 10px;
}
.themecarrousel-scrollbar-container::before {
  content: "";
  position: absolute;
  /*
      left: $sidepadding;
      right: $sidepadding;
  */
  left: 0;
  right: 0;
  top: 50%;
  height: 1px;
  background-color: #BEBEBE;
}
.themecarrousel-scrollbar-container.nativescroll {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.themecarrousel-scrollbar-container .ps__rail-x {
  /*
      @media (max-width: $timetable-eventlabel-minwidth-full-label - 1px)
      {
        margin-left: $timetable-rowlabel-width-mobile;
      }

      @media (min-width: $timetable-eventlabel-minwidth-full-label)
      {
        margin-left: $timetable-rowlabel-width;
      }

      margin-right: 10px;
  */
  height: 100%;
  border-radius: 3px;
  opacity: 1;
  cursor: pointer;
}
.themecarrousel-scrollbar-container .ps__rail-x > .ps__thumb-x {
  top: 50%;
  bottom: auto;
  transform: translateY(-50%);
  height: 7px;
  border-radius: 4px;
  background-color: #BEA3C5;
}

.themecarrousel-scrollbar-container .ps__rail-x:hover > .ps__thumb-x,
.themecarrousel-scrollbar-container .ps__rail-x:focus > .ps__thumb-x,
.themecarrousel-scrollbar-container .ps__rail-x.ps--clicking .ps__thumb-x {
  height: 10px;
  background-color: #641C76;
}

.themecarrousel-scrollbar-container.ps--active-x {
  display: block;
}

.themecarrousel-scrollbar-container .ps__rail-x {
  opacity: 1;
  display: block;
  position: relative;
  top: auto;
  left: auto;
}