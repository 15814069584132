input[aria-invalid=true] {
  border-color: var(--color-error);
}

.mpErrorRow {
  color: var(--color-error);
}

.mpErrorSummary {
  color: var(--color-error);
}

.mpQuestionTable {
  margin-top: 10px;
}