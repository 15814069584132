/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
html.site--wig2024 {
  --sidebar-relatedwebsites-item-icon-font: var(--fontawesome-solid);
  --sidebar-relatedwebsites-item-icon-content: var(--fa-external-link-alt);
  --sidebar-relatedwebsites-item-icon-font-size: 11px;
  --sidemainmenu-padding-top: 0px;
  --sidemainmenu-padding-right: 17px;
  --sidemainmenu-padding-bottom: 17px;
  --sidemainmenu-padding-left: 17px;
  --sidebar-content-indent: 30px;
  --sidebar-closebutton-color: var(--color-purple);
  --sidebar-closebutton-size: 24px;
  --sidebar-menu-level1-toggleicon-font: 900 12px "Font Awesome 5 Pro";
  --sidebar-menu-level1-toggleicon-collapsed-content: var(--fa-chevron-right);
  --sidebar-menu-level1-toggleicon-expanded-content: var(--fa-chevron-down);
  --sidebar-menu-level2-toggleicon-font: var(--fontawesome-regular);
  --sidebar-menu-level2-toggleicon-collapsed-content: var(--fa-plus);
  --sidebar-menu-level2-toggleicon-expanded-content: var(--fa-minus);
  --sidebar-menu-level3-toggleicon-font: var(--fontawesome-regular);
  --sidebar-menu-level3-toggleicon-collapsed-content: var(--fa-plus);
  --sidebar-menu-level3-toggleicon-expanded-content: var(--fa-minus);
  --sidemainmenu-toggler-width: 20px;
  --sidemainmenu-toggler-margin: 20px;
  --sidebar-menu-toggle-color: var(--color-purple);
  --sidebar-menu-toggle-color-hover: var(--color-purple);
  --sidebar-menu-toggle-fontsize: 22px;
  --sidebar-secondarylinks-color: var(--rtd-text-color);
  --sidebar-menu-level1-font: 500 19px var(--rtd-heading-fontfamily);
  --sidebar-menu-level1-padding: 4px 0px 4px 25px;
  --sidebar-menu-level1-expandedbgcolor: transparent;
  --sidebar-menu-level1-textcolor: var(--color-purple);
  --sidebar-menu-level2-font: 15px var(--rtd-heading-fontfamily);
  --sidebar-menu-level2-padding: 7px 10px 7px 25px;
  --sidebar-menu-level2-expandedbgcolor: transparent;
  --sidebar-menu-level2-textcolor: #333333;
  --sidebar-menu-level3-font: 15px var(--rtd-heading-fontfamily);
  --sidebar-menu-level3-padding: 7px 10px 7px 45px;
  --sidebar-menu-level3-textcolor: #333333;
  --sidebar-menu-level4-font: 15px var(--rtd-heading-fontfamily);
  --sidebar-menu-level4-padding: 7px 10px 7px 55px;
  --sidebar-menu-level4-textcolor: #333333;
}
html.site--wig2024 .sidebar__header {
  height: var(--header-topbar-height);
}
html.site--wig2024 .sidebar__header + hr {
  margin-left: 0;
}
html.site--wig2024 .sidemainmenu__item--hassubitems > .sidemainmenu__item__link {
  font-weight: var(--rtd-heading-font-weight);
  font-weight: 500;
  color: var(--color-purple);
}

#slidemenu-container {
  position: absolute;
  z-index: var(--sidemainmenu-zindex, 4);
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  height: 100vh;
}

#slidemenu {
  position: fixed;
  width: 80%;
  max-width: 300px;
  height: 100vh;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  transition: transform 0.4s;
}

.sidebar--slidefromleft {
  left: 0;
  transform: translateX(calc(-100% - 5px));
}

.sidebar--slidefromright {
  right: 0;
  transform: translateX(calc(100% + 5px));
}

#slidemenu:focus {
  outline: none;
}

html.sidebar-enabled #slidemenu {
  overflow: auto;
  pointer-events: auto;
  transition: transform 0.4s;
  transform: translateX(0%) !important;
}

/*
@keyframes slide_in_from_left
{
  from { transform: translateX(calc(-100% - 5px)); }
  to   { transform: translateX(0); }
}

@keyframes slide_out_to_left
{
  from { transform: translateX(0); }
  to   { transform: translateX(calc(-100% - 5px)); }
}
*/
html {
  --sidemainmenu-padding-bottom: ;
}

#slidemenu {
  padding-bottom: var(--sidemainmenu-padding-bottom);
  --sidebar-groups-spacing: 25px;
}

.sidebar__header {
  display: flex;
  align-items: center;
  padding-top: var(--sidemainmenu-padding-top);
  margin-right: var(--sidemainmenu-padding-right);
}

.sidebar__header__close {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  color: #757575;
  font: 15px var(--rtd-heading-fontfamily);
  cursor: pointer;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
  padding-left: var(--sidebar-content-indent);
  display: flex;
  align-items: center;
  position: relative;
}

.sidebar__header__close::before {
  width: auto; /* override WIG <button> styling */
  height: auto; /* override WIG <button> styling */
  position: absolute;
  left: 0;
  font: var(--fontawesome-light);
  content: var(--fa-times);
  font-size: var(--sidebar-closebutton-size);
  color: var(--sidebar-closebutton-color);
  margin-right: 12px;
}

.sidebar hr {
  margin-top: var(--sidebar-groups-spacing);
  margin-bottom: var(--sidebar-groups-spacing);
  margin-left: calc(var(--sidemainmenu-padding-left) + var(--sidebar-content-indent));
  border: 0;
  border-top: 1px solid #D7D7D7;
}

.sidebar .sidebar__header + hr {
  margin-top: 0;
}

.sidebar__relatedwebsites {
  margin-top: 35px;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__relatedwebsites a {
  display: flex;
  align-items: center;
  position: relative;
  color: var(--sidebar-menu-level1-textcolor);
  font: var(--sidebar-menu-level1-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level1-padding);
}

.sidebar__relatedwebsites a::before {
  position: absolute;
  left: 0;
  font: var(--sidebar-relatedwebsites-item-icon-font);
  content: var(--sidebar-relatedwebsites-item-icon-content);
  font-size: var(--sidebar-relatedwebsites-item-icon-font-size);
}

.sidebar__relatedwebsites a.iscurrent::before {
  content: var(--fa-chevron-right);
}

.sidebar__relatedwebsites a:hover {
  text-decoration: underline;
}

.sidebar__secondarylinks {
  margin-left: calc(var(--sidemainmenu-padding-left) + var(--sidebar-content-indent));
  margin-right: var(--sidemainmenu-padding-right);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar__secondarylinks a {
  color: var(--sidebar-secondarylinks-color);
  font: 16px var(--rtd-heading-fontfamily);
  text-decoration: none;
  padding-top: 3px;
  padding-bottom: 3px;
}

.sidebar__secondarylinks a:hover {
  text-decoration: underline;
}

.sidebar__secondarylinks a + a {
  margin-top: 5px;
}

.sidebar__languages {
  display: flex;
  align-items: center;
  color: var(--rtd-text-color);
  font: 16px var(--rtd-heading-fontfamily);
}

/* Language menu in header */
.sidebar__header > .sidebar__languages {
  margin-left: auto;
  padding-left: 30px;
  text-underline-offset: 3px;
}
.sidebar__header > .sidebar__languages span {
  background: #F1F1F1;
  border-radius: 8px;
  color: var(--color-green);
  text-decoration: underline;
}
.sidebar__header > .sidebar__languages a {
  color: var(--color-black);
  text-decoration: none;
}
.sidebar__header > .sidebar__languages a:hover {
  text-decoration: underline;
}
.sidebar__header > .sidebar__languages a, .sidebar__header > .sidebar__languages span {
  flex: none;
  width: 27px;
  height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2px;
  font-size: 13px;
  /*
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 4px;
  padding-bottom: 4px;
  */
}
.sidebar__header > .sidebar__languages .sidebar__languages span.selected {
  text-decoration: none;
}

.sidebar__header > .sidebar__languages .sidebar__languages > :last-child {
  margin-right: -3px;
}

/* Language menu at bottom of the sidebar */
.sidebar > .sidebar__languages {
  margin-top: var(--sidebar-groups-spacing);
  margin-left: calc(var(--sidemainmenu-padding-left) + var(--sidebar-content-indent));
  margin-right: var(--sidemainmenu-padding-right);
}
.sidebar > .sidebar__languages .sidebar__languages a {
  color: var(--sidebar-menu-level1-textcolor);
}
.sidebar > .sidebar__languages .sidebar__languages a,
.sidebar > .sidebar__languages .sidebar__languages span {
  flex: none;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
}
.sidebar > .sidebar__languages .sidebar__languages span.selected {
  text-decoration: none;
}

.sidebar__menu {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: var(--sidemainmenu-padding-left);
  margin-right: var(--sidemainmenu-padding-right);
}

.sidebar__menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidemainmenu__item__link .icon {
  flex: none;
  align-self: start;
  position: relative;
  top: 2px;
}

.sidemainmenu__item__link svg.icon {
  position: relative;
  top: -2px;
  width: 26px;
  height: 23px;
}

.sidemainmenu__item__title {
  align-self: baseline;
}

button.sidemainmenu__item__link {
  background: none;
  appearance: none;
  border: none;
  width: 100%;
  text-align: left;
}

.sidemainmenu-level1__itemlink .icon {
  margin-right: 5px;
  vertical-align: baseline;
  width: 26px;
  text-align: center;
}

.sidemainmenu-level1__itemlink .icon .sidemainmenu-level2__itemlink .icon {
  margin-right: 5px;
  vertical-align: baseline;
  width: 20px;
  text-align: center;
}

/*
By default we hide level 3 and 4, but using the toggles they can be expanded.
DON'T use display: none; because that way the items won't influence the width of
the pulldown until opened causing the pulldown to get wider (and reposition if it's centered).
*/
.sidebar__menu--level2,
.sidebar__menu--level3,
.sidebar__menu--level4 {
  height: 0;
  overflow: hidden;
  visibility: hidden; /* prevent tabnavigation within hidden content */
}

.sidemainmenu__item--expand > .sidebar__menu--level2,
.sidemainmenu__item--expand > .sidebar__menu--level3,
.sidemainmenu__item--expand > .sidebar__menu--level4 {
  height: auto;
  overflow: visible;
  visibility: visible;
}

.sidebar__menu--level3 {
  padding-top: 5px;
}

.sidemainmenu-level1__itemlink,
.sidemainmenu-level2__itemlink,
.sidemainmenu-level3__itemlink {
  display: flex;
  position: relative;
  cursor: pointer;
}

.sidemainmenu-level1__itemlink:hover .sidemainmenu__item__title,
.sidemainmenu-level2__itemlink:hover .sidemainmenu__item__title,
.sidemainmenu-level3__itemlink:hover .sidemainmenu__item__title {
  text-decoration: underline;
}

.sidemainmenu__item__toggle {
  position: absolute;
  left: 0;
  cursor: pointer;
}

.sidemainmenu__item__toggle:hover::before {
  color: var(--sidebar-menu-toggle-color-hover);
}

.sidemainmenu__item__toggle::before {
  content: "";
  width: var(--sidemainmenu-toggler-width);
  flex: none;
  font-size: var(--sidebar-menu-toggle-fontsize);
  color: var(--sidebar-menu-toggle-color);
}

/*
.sidebar__menu--level1 .sidemainmenu__item__toggle { outline: 1px solid #FF0000; }
.sidebar__menu--level2 .sidemainmenu__item__toggle { outline: 1px solid #00FF00; }
*/
.sidebar__menu--level1 .sidemainmenu__item__toggle::before {
  padding-left: 3px;
  font: var(--sidebar-menu-level1-toggleicon-font);
  content: var(--sidebar-menu-level1-toggleicon-collapsed-content);
}
.sidebar__menu--level1 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  padding-left: 0;
  content: var(--sidebar-menu-level1-toggleicon-expanded-content);
}

.sidebar__menu--level2 .sidemainmenu__item__toggle::before {
  padding-left: 3px;
  font: var(--sidebar-menu-level2-toggleicon-font);
  content: var(--sidebar-menu-level2-toggleicon-collapsed-content);
}
.sidebar__menu--level2 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  padding-left: 0;
  content: var(--sidebar-menu-level2-toggleicon-expanded-content);
}

.sidebar__menu--level3 .sidemainmenu__item__toggle::before {
  padding-left: 3px;
  font: var(--sidebar-menu-level3-toggleicon-font);
  content: var(--sidebar-menu-level3-toggleicon-collapsed-content);
}
.sidebar__menu--level3 .sidemainmenu__item--hassubitems.sidemainmenu__item--expand > a .sidemainmenu__item__toggle::before {
  padding-left: 0;
  content: var(--sidebar-menu-level3-toggleicon-expanded-content);
}

.sidemainmenu-level1__itemlink {
  color: var(--sidebar-menu-level1-textcolor);
  font: var(--sidebar-menu-level1-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level1-padding);
}

.sidemainmenu-level2__itemlink {
  color: var(--sidebar-menu-level2-textcolor);
  font: var(--sidebar-menu-level2-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level2-padding);
}

.sidemainmenu-level3__itemlink {
  color: var(--sidebar-menu-level3-textcolor);
  font: var(--sidebar-menu-level3-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level3-padding);
}

.sidemainmenu-level4__itemlink {
  color: var(--sidebar-menu-level4-textcolor);
  font: var(--sidebar-menu-level4-font);
  text-decoration: none;
  padding: var(--sidebar-menu-level4-padding);
}

html.sidebar-enabled body {
  overflow: hidden;
}

html body::after {
  animation: hidepagecontent 1s;
  animation-direction: reverse;
}

html.sidebar-enabled body::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);

    transition: background-color 0.25s
              , backdrop-filter 0.25s
              , -webkit-backdrop-filter 0.25s;
  */
  z-index: var(--zindex-modalitylayer);
  animation: hidepagecontent 1s;
  animation-fill-mode: forwards;
}

@keyframes hidepagecontent {
  0% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
  }
}