/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/*
NOTES:
- The z-index MUST be defined on .siteheader-menubar due to .siteheader-menubar using position: sticky.
  The problem is that this means the dorpdowns cannot have a higher z-index than the menubar itself.
  (or we must use .. to get onto the toplayer and use position anchoring....)
*/
html {
  --zindex-pageheader: 1;
  --zindex-pageheader-filterbar: 2;
  --filteredoverview-teaserbar-zindex: 3;
  --zindex-menubar: 4;
  --zindex-identityoverlay-p3d: 5;
  --zindex-modalitylayer: 6;
  --sidemainmenu-zindex: 7;
}
@media (max-width: 879px) {
  html {
    --zindex-identityoverlay-p3d: 3;
  }
}

html {
  scroll-padding-top: var(--header-menubar-height);
}

.siteheader-top {
  height: var(--header-topbar-height);
}

.siteheader-menubar {
  height: var(--header-menubar-height);
  z-index: var(--zindex-menubar);
}

html.site--wig2024 .siteheader-menubar {
  position: sticky;
  top: 0;
  transition: transform 0.5s, background-color 0.1s;
}

html.site--wig2024.menubar--hide .siteheader-menubar {
  transform: translateY(-100%);
}

html.site--wig2024.menubar--sticky .siteheader-menubar {
  transform: translateY(0);
  transition: none;
}

.siteheader-top__toplinks__more {
  z-index: 3;
}

.siteheader-identity--p3d {
  z-index: var(--zindex-identityoverlay-p3d);
}

.siteheader-menubar {
  display: grid;
}

.siteheader__menu,
.siteheader-top__search {
  z-index: 2;
}

.pageheader {
  position: relative;
  z-index: var(--zindex-pageheader);
}

.pageheader__filterbar {
  z-index: var(--zindex-pageheader-filterbar);
}

.filteredoverview__teaser {
  z-index: 10;
}

.siteheader-top__search {
  --formcontrols-focus-border-width: 0;
  --formcontrols-focus-outline: 2px solid rgba(255,255,255,0.6);
  --formcontrols-focus-outline-offset: 3px;
}

button.skiplinks {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 100;
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 44px;
  color: #FFFFFF;
  background-color: var(--color-purple);
  text-decoration: none;
  font-size: 16px;
  opacity: 0;
  pointer-events: none;
}

button.skiplinks:focus {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

main {
  scroll-margin-top: 15px;
}

main:focus {
  outline: 0;
}

/* page specific header with image and optional overlay texts */
/* encapsulate z ordering within by creating a new stacking context */
.pageheader {
  position: relative;
}

.siteheader-identity__logolink {
  display: block;
  text-decoration: none;
}

.siteheader-identity__slogan {
  color: var(--color-purple);
  font: 17px/23px "TypoPRO Dosis", "Dosis", Arial;
}

.siteheader-top button::before,
.siteheader-menubar button::before {
  display: none;
}

.siteheader-top__content {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.siteheader__menu {
  flex: 1 0 auto;
}

.topmenu__spacer {
  flex: 1 1 65px;
}

.siteheader__showsidebartoggle {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #FFFFFF;
  cursor: pointer;
}

.siteheader__showsidebartoggle .far {
  font-size: 20px;
}

@media (min-width: 880px) {
  html {
    --header-topbar-height: var(--header-topbar-height-desktop);
    --header-menubar-height: var(--header-menubar-height-desktop);
  }
  .siteheader-top {
    display: flex;
  }
  .siteheader-top__content {
    align-self: center;
  }
  .siteheader-top .siteheader__showsidebartoggle {
    display: none;
  }
  .site--wig2024 .siteheader-top__search__submit {
    margin-right: -2px;
    width: 40px;
    height: 40px;
    padding: 0;
  }
  .site--wig2024.headersearch--active .siteheader-top__search__submit {
    z-index: 1;
    color: var(--color-green);
  }
  .site--wig2024 .siteheader-top__search__submit .fas {
    font-size: 20px;
  }
}
.siteheader-top__search {
  align-self: center;
  position: relative;
  pointer-events: none;
}

.siteheader-top__search__submit {
  pointer-events: auto;
}

/*
.siteheader-top__search__keyword
{
  display: none;
  visibility: hidden; // to take out of the tab navigation
}
*/
html.headersearch--active .siteheader-top__search {
  pointer-events: auto;
}

/*
html.headersearch--active .siteheader-top__search__keyword
{
  display: block;
  visibility: visible;
}
*/
.siteheader-top__search .wh-autocomplete-container {
  top: 100%;
  position: absolute;
  z-index: 2;
  right: 0;
}

.siteheader-top__search .wh-autocomplete-values {
  position: static;
}

.siteheader-top__search__clearfieldwrapper {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  flex: none;
}

.siteheader-top__search__input.siteheader-top__search__input,
.siteheader-top__search .wh-autocomplete-container {
  width: calc(100vw - 15px);
  max-width: 500px;
}

.siteheader-top__search__input.siteheader-top__search__input {
  font-size: 16px;
  padding-left: 18px;
}

.siteheader-top__right {
  align-self: flex-start;
  margin-top: 16px;
  display: flex;
  flex-direction: column;
}

.siteheader-top__search {
  display: flex;
  flex-wrap: wrap;
}

.siteheader__showsidebartoggle {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
}

.siteheader-top__search__submit {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #FFFFFF;
  cursor: pointer;
}
html.site--wig2024 .siteheader-top__search__submit {
  border: 1px solid rgba(255, 255, 255, 0.35);
  border-radius: 8px;
}

@media (max-width: 879px) {
  html {
    --header-topbar-height: var(--header-topbar-height-mobile);
    --header-menubar-height: var(--header-menubar-height-mobile);
  }
  .siteheader-top {
    display: flex;
  }
  .siteheader-menubar .siteheader__showsidebartoggle {
    display: none;
  }
  .siteheader-top__search {
    margin-left: auto;
  }
  .site--wig2024 .siteheader-top__search__submit {
    margin-right: -2px;
    width: 36px;
    height: 36px;
    padding: 0;
  }
  .site--wig2024 .siteheader-top__search__submit .fas {
    font-size: 16px;
  }
}
html.headersearch--active .siteheader-top__search__submit {
  border-color: transparent;
}

html.headersearch--active .siteheader-top__search__submit .fas {
  color: var(--color-green);
}

.siteheader-top__search__input.siteheader-top__search__input {
  border-radius: 8px;
  height: 100%;
  opacity: 0;
  /*visibility: hidden; make unfocusable */
}

.headersearch--active .siteheader-top__search__input.siteheader-top__search__input,
.siteheader-top__search__input.siteheader-top__search__input:focus {
  opacity: 1;
  /*visibility: visible;  also allows focus again */
}

.spccomp-searchpanel__query.spccomp-searchpanel__query {
  padding-left: 56px;
}

/*
.spccomp-searchpanel__query__submit
{
  @include clearbuttonelementstyling;
  font-size: 22px;
  color: $themecolor-pink;

  position: absolute;
  left: 20px;
  top: 9px;
}
*/
.spccomp-searchpanel__query__clear {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font-size: 24px;
  color: #767676;
  position: absolute;
  right: 25px;
  top: 9px;
}

.siteheader-menubar {
  display: grid;
  background: var(--color-green-background);
}

.siteheader-menubar__content {
  grid-column: 1;
  grid-row: 1;
  align-self: center;
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
}

.siteheader-menubar__content {
  position: relative;
  display: flex;
  height: 100%;
  max-width: min(970px, 100dvw);
}

.site__header__menu__items {
  height: 100%;
}

@media (min-width: 880px) {
  .siteheader__showsidebartoggle {
    display: none;
  }
  .siteheader-top__search {
    margin-left: 15px;
  }
  html.site--p3d .siteheader-top__search {
    margin-left: 5px;
  }
  .siteheader-top__content {
    position: relative;
  }
  .siteheader-top__right {
    margin-left: auto;
  }
}
@media (max-width: 879px) {
  .topbar__wiglogo {
    width: 204px;
  }
  .siteheader-identity__slogan {
    display: none;
  }
  .siteheader__showsidebartoggle {
    flex: none;
  }
  .siteheader-top .siteheader__showsidebartoggle {
    color: var(--color-green);
    width: auto;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: -15px;
  }
  .siteheader-menubar .siteheader__showsidebartoggle {
    margin-left: auto;
    margin-right: 15px;
    color: #FFFFFF;
  }
  .siteheader__showsidebartoggle .far {
    font-size: 20px;
    margin-top: -1px;
  }
  .siteheader-top__right {
    margin-left: 30px;
    flex: 1 0 auto;
  }
  .siteheader-top__right {
    display: none;
  }
}
.pageheader__filterbar {
  background-color: var(--pageheader-filterbar-background);
}

.pageheader__expandedfilters {
  background-color: var(--pageheader-filterbar-background);
}

.pageheader-bottomrow {
  margin-top: 13px;
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between; /* push ReadSpeaker to the right if it's on the same row */
  column-gap: 10px;
}

.header__readspeaker {
  flex: none;
}

/* Up till 850px allow the ReadSpeaker button
   to wrap to the next line if the crumblepath
   otherwise wouldn't fit on a single line.
*/
@media (max-width: 850px) {
  .pageheader-bottomrow {
    flex-wrap: wrap;
    row-gap: 10px; /* distance between crumblepath and ReadSpeaker buttonbar */
  }
  .pageheader-bottomrow .crumbpath {
    flex: 1 1 auto;
  }
}