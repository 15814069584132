.widget--news .widget-itemslist
{
  /*border-top:    1px solid #dedede;*/
  border-bottom: 1px solid #dedede;
}

.widget--news__footer
{
  padding: 15px 20px 12px 20px;
}



.widget-itemslist a
{
  display: flex;
  align-items: center;

  text-decoration: none;
  padding: 15px 20px;
}
.widget-itemslist.widget--news__items a
{
  padding: 13px 20px;
}

.widget-itemslist a > .widget-itemslist__item__title
{
  display: inline-block; /* make flex work on <span> in IE10 */
  flex-grow: 1;
}

.widget-itemslist a + a
{
  border-top: 2px dotted #d5d5d5;
}

/*
Options:
- img in DOM
- ::after -> fontawesome
- ::after -> custom font using fontastic.me
- ::after -> image-background: ...svg
*/
.widget-itemslist a::after
{
  flex: none;
  margin-left: 15px;

  display: inline-block;
  /* !! important: make sure the SVG has no width or height or it won't scale */;
  content: url("../web/img/symbols/angle-right-thin.svg");
  width: 13px;
  height: 25px;
}
.widget-itemslist a:hover::after
{
  display: inline-block;
  /* !! important: make sure the SVG has no width or height or it won't scale */;
  content: url("../web/img/symbols/angle-right-thin.white.svg");
}

.widget__biglist a:hover
{
  background-color: var(--color-green);
  color: #FFFFFF;

  margin-bottom: -2px;
  border-bottom: 2px solid var(--color-green);
  position: relative;
}

.widget__biglist a + a:hover
{
  border-top: 2px solid var(--color-green);
}

.widget__biglist a:hover .neoitem__date
{
  color: #FFFFFF !important;
}



  .ctalink
, .backlink
{
  display: inline-flex;
  align-items: center;

  text-decoration: none;

  /* specify anchor color in case we're not used as an <a> element
     might happen when we are in a container which uses [data-popup].
  */
  color: var(--color-green-small);
}

.ctalink > *
{
  text-decoration: underline;
}

/*
  .ctalink::before
, .ctabutton.ctabutton--arrow::before
, .wh-form__button::before
{
  display: inline-block;
  width: 32px;
  margin-right: 5px;

  position: relative;
  top: 2px;

  / * prevent stretching the button * /
  margin-top: -6px;
  margin-bottom: -6px;
}
*/
