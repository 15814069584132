/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.pageheader--p3d2 {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
}

.pageheader--p3d__image {
  width: 100%;
}

@media (max-width: 600px) {
  .pageheader--p3d__image--desktop {
    display: none;
  }
}
@media (min-width: 601px) {
  .pageheader--p3d__image--mobile {
    display: none;
  }
}