.page--p3d-aanmelden .site__footerspacer {
  padding-top: 60px;
}

.p3d-participant__loginoptions .widget--highlight {
  margin-top: 30px;
}
.p3d-participant__loginoptions .widget--highlight .ctabutton {
  margin-top: 20px;
}
.p3d-participant__loginoptions .widget--highlight.p3d-participant__logincta {
  --widget-highlight-background: #FFFFFF;
}

@media (min-width: 768px) {
  .p3d-participant__loginoptions {
    display: flex;
    column-gap: 20px;
    --rtd-spacing-widget-before: 0;
    --rtd-spacing-widget-after: 0;
  }
  .p3d-participant__loginoptions > * {
    flex: 1 1 0px;
    min-width: 0;
    display: flex;
    flex-direction: column;
  }
  .p3d-participant__loginoptions > * .rtdcontent {
    margin-bottom: auto;
  }
  .p3d-participant__loginoptions > * .ctabutton {
    margin-top: 20px;
  }
}
.p3d-order-form__summary-address {
  font-size: 14px;
}

/*
#promotiemateriaal.wh-form .wh-form__fieldgroup
{
  flex-direction: row !important;
  align-items: center !important;
}

#promotiemateriaal.wh-form .wh-form__label
{
  min-width: 0;
  flex: 1 1 auto !important;
  width: auto !important;
}

#promotiemateriaal.wh-form .wh-form__fieldgroup > .wh-form__fields
{
  max-width: none;
  // width: auto !important;
}
*/
.productlist__item .wh-form__fieldgroup--error {
  padding: 10px !important;
  margin-right: 10px;
  position: relative;
}

.productlist__item .wh-form__error {
  position: absolute;
  right: 0;
  width: auto;
  white-space: nowrap;
}