.wiglibrary-filter--category .radiolist__option,
.wiglibrary-filter--category .checkboxlist__option {
  /*
  Using column-break-inside: avoid; in Webkit causes the room where an item might have been
  if it wasn't moved to the next column to stay reserved. So we end up with weird/unpredicatable whitespace at the bottom.
  The inline-block method works better.
  */
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  /* https://stackoverflow.com/questions/7785374/how-to-prevent-column-break-within-an-element */
}

@media (max-width: 799px) {
  .wiglibrary-filter--category .filterpanel__options {
    columns: 2;
  }
  .wiglibrary-filter--period {
    margin-top: 30px;
  }
  .wiglibrary-filter--period__items {
    display: flex;
    flex-wrap: wrap;
    column-gap: 30px;
    row-gap: 15px;
  }
  .wiglibrary-filter--period__items > * {
    flex: 1 1 0px;
  }
}
@media (max-width: 600px) {
  .wiglibrary-filter--category .filterpanel__options {
    columns: 1;
  }
}
@media (min-width: 800px) {
  .wiglibrary-filterpanel {
    display: flex;
  }
  .wiglibrary-filter--category .filterpanel__options {
    columns: 2;
  }
  .wiglibrary-filter--category {
    width: 100%;
  }
  .wiglibrary-filter--period {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 280px;
  }
  .wiglibrary-filter--period .filterpanel--pulldown + .filterpanel--pulldown {
    margin-top: 16px;
  }
}