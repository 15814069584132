.widget--specsheet h2 {
  padding-bottom: 10px;
  border-bottom: 1px solid #BDBDBD;
  margin-bottom: 30px;
}

.specsheet {
  display: grid;
  grid-template-columns: max-content max-content;
  column-gap: 45px;
}

dl.specsheet__row,
dl.specsheet__row dd {
  margin: 0;
}

.specsheet__optiongroup {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: subgrid;
}

.specsheet__optiongroup + .specsheet__optiongroup {
  margin-top: 40px;
}

.specsheet__optiongroup__header {
  margin-bottom: 15px;
}

.specsheet__row {
  grid-column: 1/-1;
  display: grid;
  grid-template-columns: subgrid;
  font: var(--comparisontable-item-font);
  align-items: center;
  border-top: 1px solid #F1F1F1;
  box-sizing: border-box;
  min-height: 56px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
}

.specsheet__option__title {
  justify-self: start;
  display: flex;
  align-items: center;
  padding: 4px 15px;
  border: 1px solid #767676;
  color: #767676;
  font: var(--comparisontable-item-font);
  min-height: 29px;
  border-radius: 15px;
  max-width: 200px;
  word-break: break-word;
  text-wrap: balance;
}

a.specsheet__option__title {
  text-decoration: none;
  border: 1px solid var(--color-green-background-highcontrast);
  color: var(--color-green-background-highcontrast);
}

a.specsheet__option__title:hover {
  background: var(--color-green-background-highcontrast);
  color: #FFFFFF;
}