/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
html:not(.filteredoverview--showfilters) .header-expandedfilters {
  display: none;
}

.pageheader__expandedfilters {
  grid-column: 1;
  grid-row: 2;
}

.pageheader__expandedfilters__content {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  padding-top: 9px;
  padding-bottom: 65px;
}

.page--huishoudboekjes .filteredoverview__teaser,
.page--publications .filteredoverview__teaser {
  width: 100%;
}

.page--publications .filteredoverview__teaser__button {
  display: block;
  width: 100%;
  max-width: var(--pagegrid-contentarea-width);
  margin-left: auto;
  margin-right: auto;
}

.page--huishoudboekjes .filteredoverview__teaser__button {
  --contentarea-current-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  --sides-whitespace: max(calc((100vw - var(--contentarea-current-width)) / 2), 0px);
  margin-left: calc(var(--sides-whitespace));
  width: 100%;
  max-width: 700px;
}