@charset "UTF-8";
.orderitemsummary {
  background: #F5F5F5;
  border-radius: 5px;
  padding: 20px 15px;
}

.orderlist {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* 350px just allows us to fit 2 columns on an iPad display */
  column-gap: 20px;
  row-gap: 30px;
}

.orderlist__item {
  /*
  display: grid;
  grid-template-columns: min-content minmax(min-content, 1fr) min-content;
  grid-template-columns: subgrid;
  */
}

.orderlist__item__image {
  display: block;
  width: 100%;
}

.orderlist__item__heading {
  display: flex;
  align-items: end;
  font: bold 16px/20px "TypoPRO Dosis", Arial;
  margin-bottom: 10px;
}

.orderlist__item__heading::before {
  /*
  \00a0 non breakable space
  \200b zero width space
  \A newline
  */
  content: "​\a​";
  white-space: pre;
  vertical-align: top;
}