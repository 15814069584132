/*
Used for:
- sidebar (mobile menu)
- overview page mobile filters popup
*/
html:has(.site-dialog) {
  overflow: hidden;
}

/* FULLSCREEN dialog */
.site-dialog {
  /* for browsers without native <dialog> support */
  position: fixed;
  z-index: 100; /* if we aren't a dialog we aren't on the top-layer so use z-index to get on top */
  left: 0;
  top: 0;
  /*top: env(safe-area-inset-top);*/
  bottom: 0;
  /*width: 80%;*/
  height: 100%;
  height: 100dvh; /* FIXME: otherwise the dialog gets centered causing part of the top to go out of view */
  width: 100%; /* DON'T use dvw, in Safari it'll be good, but in Chrome and Firefox they'll include the vertical scrollbar area too */
  /* Found some small references about margins for positioning with <dialog>... */
  /*
  Positioning left
    margin-left: 0;
    margin-right: auto;
    max-width: 500px;
  */
  max-width: none; /* use full width */
  max-height: none; /* Chrome applies a max height to <dialog> */
  background-color: #FFFFFF;
  border: none;
  padding: 0;
}

/*
.site-dialog--centered
{
  box-shadow: 0 0 5px rgba(0, 0, 0, .5);
}
*/
.dialog__content {
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-template-rows: min-content;
}

.dialog__content > form {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  grid-auto-rows: min-content;
}

.dialog__content > form > * {
  align-self: start;
}

.acoifilterpage-filterdialog {
  padding-bottom: 130px; /* reserve space to place the "X results" teaser bar so it won't overlap the filter options */
}

/*
html.sidebar-enabled body
{
  position: fixed;
  overscroll-behavior: contain;
}
*/
.dialog__header {
  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
  padding-top: var(--site-menubar-padding-top);
  padding-bottom: var(--site-menubar-padding-bottom);
  min-height: 50px;
  margin-bottom: 15px;
}

.dialog__header__content {
  grid-column: var(--site-menubar-grid-column);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dialog__header__content {
  padding-left: var(--sidepadding);
}

/*
.dialog__header__identity img
{
  display: block;

  width: auto;
  height: var(--site-menubar-logo-height);
}
*/
.dialog__closebutton {
  margin-left: auto;
}

.dialog__closebutton {
  padding: 0;
  margin: 0;
  border: 0;
  display: flex;
  padding: 10px;
  color: #757575;
  cursor: pointer;
  margin-left: var(--sidemainmenu-padding-left);
  display: flex;
  align-items: center;
  position: relative;
}

.dialog__closebutton:hover {
  background-color: #E0E0E0;
}

.dialog__closebutton::before {
  font: var(--fontawesome-light);
  content: var(--fa-times);
  font-size: var(--sidebar-closebutton-size);
  color: var(--sidebar-closebutton-color);
}

.dialog__body {
  grid-column: var(--pagegrid-col-verywide);
  /*display: grid;*/
  /*grid-template-columns: var(--pagegrid-template-columns);*/
}

.filtersdialog {
  background: var(--pageheader-filterbar-background);
}

.site-dialog__footer-sticky {
  position: sticky;
  bottom: 0px;
  z-index: 50;
}

.filtersdialog__applybutton {
  display: block;
  width: -webkit-fill-available;
  margin-left: var(--sidepadding);
  margin-right: var(--sidepadding);
}