/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
@media (min-width: 880px) {
  .pageheader--p3dcarrousel {
    margin-top: 60px;
  }
}
@media (max-width: 500px) {
  .pageheader--p3dcarrousel__hdr {
    margin-bottom: 20px;
  }
  .pageheader--p3dcarrousel::before {
    margin-top: 75px;
  }
}
@media (max-width: 650px) {
  .pageheader--p3dcarrousel {
    margin-top: 20px;
  }
}
.pageheader--p3dcarrousel::before {
  content: "";
  grid-column: 1/-1;
  grid-row: 2;
  background-color: #EBEADA;
}

.pageheader--p3dcarrousel__hdr .line1 {
  color: var(--color-purple);
}

.pageheader--p3dcarrousel__hdr .line2 {
  color: var(--color-green-bright);
  font-weight: bold;
}

.pageheader--p3dcarrousel__hdr {
  opacity: 0;
  transition: opacity 0.2s;
  width: max-width; /* so the part without text isn't a clickable part of the link */
  text-decoration: none;
}

.pageheader--p3dcarrousel__hdr.activeslide {
  opacity: 1;
  pointer-events: auto;
}

.pageheader--p3dcarrousel::before {
  height: clamp(75px, 14vw, 171px);
}

.pageheader--p3dcarrousel__image {
  display: block;
  margin-left: auto;
  height: 342px;
  height: clamp(150px, 31vw, 342px);
}

.pageheader--p3dcarrousel {
  display: grid;
  grid-template-columns: minmax(15px, calc((100% - 940px) / 2)) minmax(0, 1fr) minmax(0, 1fr) minmax(15px, calc((100% - 940px) / 2));
  grid-template-rows: min-content min-content min-content;
}

.pageheader--p3dcarrousel__hdr {
  pointer-events: none;
  grid-column: 2/span 2;
  grid-row: 1;
  align-self: end;
  margin-top: 30px;
  margin-top: clamp(15px, 2.55vw, 30px);
  margin-bottom: 33px;
  margin-bottom: clamp(15px, 2.55vw, 30px);
  font: 54px/60px var(--rtd-heading-fontfamily);
  font-size: 30px;
  font-size: clamp(28px, 4.4vw, 44px);
  line-height: 125%;
}

/*
.pageheader--p3dcarrousel__text
{
  grid-column: 2;
  grid-row: 2;
  align-self: end;

  padding: 20px 0 25px 0;
  padding-bottom: $startbutton-height-desktop*0.5 + 20px;
}
*/
.pageheader--p3dcarrousel__imagewrapper {
  pointer-events: none;
  margin-left: 5%;
  grid-column: 3;
  grid-row: 1/span 2;
  align-self: end;
  justify-self: end;
  position: relative;
}