fieldset {
  display: block;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
  border: 0 none;
}

form input[type=search] {
  /* Webkit had some strict styling rules when it uses its search appearance */
  -webkit-appearance: none;
}

.wh-form__dateinputgroup__line.wh-form__dateinputgroup__line input,
.wh-form__timeinputgroup__line.wh-form__timeinputgroup__line input {
  min-height: 0 !important;
  height: auto !important;
}

.wh-form__dateinputgroup__line.wh-form__dateinputgroup__line::after,
.wh-form__timeinputgroup__line.wh-form__timeinputgroup__line::after {
  top: 1px;
}

/* the spinner takes away too much room for the p3d widget,
   but we want to use type=number for mobile devices/virtual keyboards
*/
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

label {
  cursor: pointer;
}

/* Having a max-width for all controls was a mistake.
   At least disable that behaviour for widgets,
   we assume they can make their own decision on width's
*/
.widget input[type=date],
.widget input[type=email],
.widget input[type=number],
.widget input[type=tel],
.widget input[type=text],
.widget input[type=password],
.widget input[type=search] {
  max-width: 100%;
}

/*
.wh-form .error-holder
{
  clear: left;
  float: none;
  color: var(--color-error);
  display: none;
}
.wh-form .invalid > .error-holder
{
  display: block;
}
.wh-form .fieldgroup:after
{
  clear: both;
  content: '';
  display: block;
}
.wh-form .formgroup + .formgroup
{
  margin-top: 20px;
}
.wh-form .fieldgroup > *
{
  float: left;
  margin-bottom: 5px;
}
.wh-form .fieldgroup > label
{
  clear: both;
}
.wh-form .fieldgroup > .grouptitle
{
  display: block;
  font:400  18px/24px 'TypoPRO Dosis', Arial;
  padding-bottom: 5px;
}

  .wh-form .fieldgroup.invalid input[type="date"]
, .wh-form .fieldgroup.invalid input[type="email"]
, .wh-form .fieldgroup.invalid input[type="number"]
, .wh-form .fieldgroup.invalid input[type="tel"]
, .wh-form .fieldgroup.invalid input[type="text"]
, .wh-form .fieldgroup.invalid input[type="password"]
, .wh-form .fieldgroup.invalid textarea
{
  border-color: var(--color-error);
  background-color: #ffeeee;
}
*/
/* Formulieren in widgets */
/*
  .ctabackground .wh-form .fieldgroup.invalid .error-holder
{
  display: none;
}
  .ctabackground .wh-form .fieldgroup.invalid input[type="date"]
, .ctabackground .wh-form .fieldgroup.invalid input[type="email"]
, .ctabackground .wh-form .fieldgroup.invalid input[type="number"]
, .ctabackground .wh-form .fieldgroup.invalid input[type="tel"]
, .ctabackground .wh-form .fieldgroup.invalid input[type="text"]
, .ctabackground .wh-form .fieldgroup.invalid input[type="password"]
, .ctabackground .wh-form .fieldgroup.invalid textarea
{
  background-color: #FFFFFF;
  color: #AA0000;
}
  .ctabackground .wh-form .fieldgroup.invalid input[type="email"]::placeholder
, .ctabackground .wh-form .fieldgroup.invalid input[type="text"]::placeholder
{
  color: #AA0000;
  font-weight: bold;
}
*/
button > *,
.button > *,
a.button > *,
input[type=submit] > * {
  vertical-align: middle;
}

/* Style very specific to prevent styling pulldowns in components (such as the RTD toolbar pulldown) or tools */
select.wh-form__pulldown,
select.wh-rtd__toolbarstyle {
  /* FA5 angle-down arrow cropped and with purple fill as background image */
  background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjIwOCIgdmlld0JveD0iMCAwIDMyMSAyMDgiIHdpZHRoPSIzMjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTE0NCAyMDAuMy0xMzYtMTM2Yy05LjQtOS40LTkuNC0yNC42IDAtMzMuOWwyMi42LTIyLjZjOS40LTkuNCAyNC42LTkuNCAzMy45IDBsOTYuNCA5Ni40IDk2LjQtOTYuNGM5LjQtOS40IDI0LjYtOS40IDMzLjkgMGwyMi42IDIyLjZjOS40IDkuNCA5LjQgMjQuNiAwIDMzLjlsLTEzNiAxMzZjLTkuMiA5LjQtMjQuNCA5LjQtMzMuOCAweiIgZmlsbD0iIzY0MjM4MiIgLz48L3N2Zz4K);
  background-position: right 6px top calc(50% + 1px);
  background-repeat: no-repeat;
  background-size: 15px auto;
  padding-right: 25px;
}

select.wh-rtd__toolbarstyle.wh-rtd__toolbarstyle {
  /* Make sure the select follows the size of the buttons beside it */
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  background-size: 10px auto;
}