/*
@mixin wh-form-hidenativecontrol
{
  // display: none or visibility: hidden; will prevent focus!!
  position: absolute !important; // to not mess with flex containers
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  opacity: 0;

  margin: 0;
}

@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
/*
<div class="effecttool__dialog__modalbg">
   <div class="effecttool__dialog__holder">
      <div class="effecttool__dialog">
         <div class="effecttool__dialog effecttool__dialog--reminder ">
            <div class="effecttool__dialog__header">Zet deze tip als reminder in mijn agenda</div>
            <form class="effecttool__reminder__form" autocomplete="off">
               <input type="hidden" name="title" value="Reminder"><input type="hidden" name="description" value="hghghghg"><input type="hidden" name="link" value="">
               <div class="effecttool__reminder__part">
                  <div class="effecttool__reminder__label">Dit zullen we in uw agenda zetten:</div>
                  <div class="effecttool__reminder__preview">
                     <div class="effecttool__reminder-title">Reminder</div>
                     <div class="effecttool__reminder-description">hghghghg</div>
                     <a href="#" class="effecttool__reminder-link"></a>
                  </div>
               </div>
               <div class="effecttool__reminder__part">
                  <div class="effecttool__reminder__label">Op welke dag wilt u de reminder plaatsen?</div>
                  <input class="effecttool__reminder__date" type="date" name="date" data-format="%d - %m - %Y">
                  <div class="effecttool__dialog__buttonbar"><a class="effecttool__reminder__download effecttool__button effecttool__button--solid">Download agendapunt</a></div>
               </div>
            </form>
         </div>
      </div>
   </div>
</div>
*/
@media (max-width: 640px) {
  .page--p3dtool .effecttool__dialog__holder > .effecttool__dialog {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #FFFFFF;
    padding: var(--sidepadding);
  }
}
@media (min-width: 640px) {
  .page--p3dtool .effecttool__dialog__modalbg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(2px);
  }
  .page--p3dtool .effecttool__dialog__holder {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .page--p3dtool .effecttool__dialog__holder > .effecttool__dialog {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 2px 2px 3px solid rgba(0, 0, 0, 0.25);
    padding: 20px;
  }
}