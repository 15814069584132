/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/*
Variations on the header:
  .page--home2021 .pageheader - Textpanel will be at the bottom right within the header
  .pageheader.pageheader--theme     - Textpanel will be hanging at the bottom right of the header, in case of being in a theme folder a themeicon will be shown to the left of the text panel

  .page--huishoudboekjes - Less high and less padding in textpanel


Notes:
- The home version of the header will grow to fit the text (or the part covering)
  (if this would be necessary for the normal/theme header we could try to have the negative margin-top as a grid row instead and have the background image cover that row)
*/
.pageheader__bgimage--placeholder {
  background-color: #F1F1F1;
}

.pageheader:not(.pageheader--withcustomcontent) {
  display: grid;
  grid-template-rows: 1fr min-content;
}
.pageheader:not(.pageheader--withcustomcontent) .pageheader__bgimage {
  grid-column: 1;
  grid-row: 1;
}

.pageheader:not(.pageheader--withcustomcontent) .pageheader__content {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.pageheader.pageheader--withcustomcontent .pageheader__bgimage {
  grid-column: 1/span 6;
  grid-row: 1;
}

.pageheader__text__title + p {
  margin-top: 12px;
}

.pageheader--compact .pageheader__text__title, .pageheader--compact .pageheader__text {
  color: #FFFFFF;
}

.headertextbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f054";
  font-size: 22px;
  margin-right: 16px;
}

.pageheader--default .pageheader__text,
.pageheader--home .pageheader__text,
.pageheader--theme .pageheader__text {
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
}
.pageheader--default .pageheader__customcontent,
.pageheader--home .pageheader__customcontent,
.pageheader--theme .pageheader__customcontent {
  --widget-padding-h: 20px;
  /*
  --widget-tableofcontents-item-bullet-size: 13px;
  --widget-tableofcontents-header-font: bold 22px/24px var(--rtd-heading-fontfamily); / * instead of rtd-h2-font which uses 24px/28px * /
  --widget-tableofcontents-item-font: 500 14px/28px var(--rtd-text-fontfamily);
  */
  --widget-tableofcontents-header-font: bold 22px/24px var(--rtd-heading-fontfamily); /* instead of rtd-h2-font which uses 24px/28px */
}
.pageheader--default .pageheader__customcontent .embeddedobject,
.pageheader--home .pageheader__customcontent .embeddedobject,
.pageheader--theme .pageheader__customcontent .embeddedobject {
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  margin-top: 0;
  flex: 1 1 auto;
  padding: 0;
}
.pageheader--default .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents,
.pageheader--home .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents,
.pageheader--theme .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents {
  padding: 10px var(--widget-padding-h) var(--widget-padding-v) var(--widget-padding-h);
}
.pageheader--default .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents a,
.pageheader--home .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents a,
.pageheader--theme .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents a {
  text-underline-offset: 2px;
}
.pageheader--default .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents a:hover,
.pageheader--home .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents a:hover,
.pageheader--theme .pageheader__customcontent .embeddedobject.widget--tableofcontents .tableofcontents a:hover {
  color: var(--color-purple);
}
.pageheader--default .pageheader__text__title,
.pageheader--home .pageheader__text__title,
.pageheader--theme .pageheader__text__title {
  color: var(--color-purple);
}
.pageheader--default .pageheader__text__description,
.pageheader--home .pageheader__text__description,
.pageheader--theme .pageheader__text__description {
  color: #000000;
}
.pageheader--default .headertextbutton,
.pageheader--home .headertextbutton,
.pageheader--theme .headertextbutton {
  display: flex;
  align-items: baseline;
  color: var(--color-green);
  text-decoration: none;
}

@media (max-width: 879px) {
  .siteheader-menubar + .pageheader-bottomrow {
    margin-top: 10px;
  }
  html.site--p3d .siteheader-menubar + .pageheader {
    margin-top: 15px;
  }
  .siteheader-menubar + .body__content {
    margin-top: 15px;
  }
  /* Situation in which no bottomrow (crumblepath and readspeaker bar)
     is shown below the pageheader */
}
@media (max-width: 879px) and (max-width: 640px) {
  /*
      main.rtdcontent > *:first-child
      {
        margin-top: 0;
      }
  */
}
@media (min-width: 640px) {
  /*
    .pageheader-bottomrow .header__readspeaker
    {
      margin-left: auto;
    }
  */
  #readspeaker_button {
    justify-content: flex-end;
  }
  .crumbpath--contentbottom {
    display: none;
  }
}
@media (max-width: 639px) {
  .pageheader-bottomrow {
    display: none;
  }
  .pageheader-bottomrow .header__readspeaker {
    margin-left: 0;
  }
  /*
    // Since the bottomrow is now hidden we must treat the body as if it's directly after the menubar
    .pageheader-bottomrow + .body__content
    {
      margin-top: $whitespace-content-to-menubar;
    }
  */
}
html.pensioen3daagse #themescontainer .pageheader-bottomrow {
  margin-top: 65px;
}

.pageheader--theme .pageheader-bottomrow {
  margin-top: 32px;
}

@media (max-width: 640px) {
  .pageheader--withcustomcontent {
    display: grid;
  }
  .pageheader--withcustomcontent .pageheader__content {
    display: contents;
  }
  .pageheader--withcustomcontent .pageheader__text {
    grid-column: 1;
    grid-row: 1;
    width: auto !important;
    margin-left: var(--sidepadding) !important;
    margin-right: var(--sidepadding);
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .pageheader--withcustomcontent .pageheader__customcontent {
    margin-top: 15px;
    grid-column: 1;
    grid-row: 2;
    margin-left: var(--sidepadding);
    margin-right: var(--sidepadding);
  }
  .pageheader--withcustomcontent .pageheader__readspeaker {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: var(--sidepadding);
    margin-right: var(--sidepadding);
    grid-column: 1;
    grid-row: 3;
  }
  html:has(.pageheader + .filteredoverview__form) .pageheader--default {
    background: var(--pageheader-filterbar-background);
  }
  .pageheader__bgimage {
    min-height: 188px;
  }
  .pageheader__themeiconholder {
    display: none;
  }
  .pageheader--default .pageheader__content,
  .pageheader--home .pageheader__content,
  .pageheader--theme .pageheader__content {
    grid-row: 2;
    margin-top: -58px;
  }
  .pageheader--default .pageheader__text,
  .pageheader--home .pageheader__text,
  .pageheader--theme .pageheader__text {
    padding: 14px 14px 14px 14px;
    width: 100%;
    max-width: 691px;
    margin-left: auto;
  }
  .pageheader--compact .pageheader__content {
    grid-column: 1;
    grid-row: 1;
    align-self: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .pageheader__text__title {
    font: bold 24px/26px var(--rtd-heading-fontfamily);
  }
  .pageheader__text__description {
    font: 16px/24px var(--rtd-heading-fontfamily);
  }
  .headertextbutton {
    margin-top: 20px;
    font: bold 20px/24px var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 988px) {
  .pageheader:not(.pageheader--withcustomcontent) .pageheader__readspeaker {
    grid-column: 1;
    grid-row: 1;
    align-self: start;
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .pageheader:not(.pageheader--withcustomcontent) .pageheader__readspeaker {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 989px) {
  .pageheader:not(.pageheader--withcustomcontent) .pageheader__readspeaker {
    grid-column: 1;
    grid-row: 2;
    align-self: end;
    position: relative;
  }
  .pageheader:not(.pageheader--withcustomcontent) .pageheader__readspeaker {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
  }
  .pageheader:not(.pageheader--withcustomcontent) .pageheader #readspeaker_button {
    position: absolute;
    top: 40px;
    right: 15px;
  }
}
@media (min-width: 641px) {
  .pageheader__bgimage {
    min-height: 377px;
  }
  .page--huishoudboekjes .pageheader__bgimage {
    min-height: 205px;
  }
  .pageheader--home .pageheader__bgimage {
    min-height: 446px;
  }
  .pageheader--compact .pageheader__bgimage {
    min-height: 172px;
  }
  .pageheader--default .pageheader__content,
  .pageheader--home .pageheader__content {
    grid-column: 1;
    grid-row: 1;
    align-self: end;
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-xl-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .pageheader:not(.pageheader--withcustomcontent) .pageheader--default .pageheader__readspeaker,
  .pageheader:not(.pageheader--withcustomcontent) .pageheader--home .pageheader__readspeaker {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-xl-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
  }
  .pageheader--withcustomcontent .pageheader__readspeaker {
    grid-column: 3;
    grid-row: 2;
    align-self: start;
  }
  .pageheader--withcustomcontent .pageheader__readspeaker #readspeaker_button {
    position: relative;
    top: 0;
    margin-top: 30px;
  }
  .pageheader--compact .pageheader__content {
    grid-column: 1;
    grid-row: 1;
  }
  .pageheader--theme .pageheader__content {
    grid-column: 1;
    grid-row: 2;
    align-self: end;
  }
  .pageheader--theme .pageheader__text,
  .pageheader--theme .pageheader__customcontent {
    margin-top: -120px;
  }
  .pageheader--default .pageheader__text,
  .pageheader--home .pageheader__text,
  .pageheader--theme .pageheader__text {
    padding: 30px 24px 32px 24px;
    width: 100%;
    max-width: 691px;
    margin-left: auto;
  }
  .pageheader--default .pageheader__themeiconholder + .pageheader__text,
  .pageheader--home .pageheader__themeiconholder + .pageheader__text,
  .pageheader--theme .pageheader__themeiconholder + .pageheader__text {
    margin-left: auto;
  }
  .page--huishoudboekjes .pageheader__text {
    padding: 20px 24px 20px 24px;
  }
  .pageheader--withcustomcontent {
    display: grid;
    grid-template-columns: calc((100% - 940px) / 2 - var(--sidepadding)) var(--sidepadding) 2fr 1fr var(--sidepadding) calc((100% - 940px) / 2 - var(--sidepadding));
    grid-template-rows: minmax(340px, min-content) 1fr;
  }
  .pageheader--withcustomcontent .pageheader__bgimage {
    grid-column: 1/span 6;
    grid-row: 1;
  }
  .pageheader--withcustomcontent .pageheader__content {
    display: contents;
  }
  .pageheader--withcustomcontent .pageheader__text {
    grid-row: 1;
    grid-column: 3;
    margin-top: 75px;
    margin-bottom: 36px;
    padding: 21px 24px 25px 24px;
  }
  .pageheader--withcustomcontent .pageheader__customcontent {
    margin-left: 20px;
    margin-top: 75px;
    grid-row: 1/span 2;
    grid-column: 4;
  }
  .pageheader--compact .pageheader__text__description {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .pageheader--compact .pageheader__text__title {
    flex: 1 1 auto;
    padding-right: 30px;
  }
  .pageheader--compact .pageheader__text__description .rtdcontent {
    margin-left: auto;
    flex: 1 0 auto;
    max-width: 53%;
    text-align: right;
  }
  .pageheader__text__title {
    font: bold 36px/40px var(--rtd-heading-fontfamily);
  }
  .pageheader__text__description {
    font: 18px/28px var(--rtd-heading-fontfamily);
  }
  .headertextbutton {
    margin-top: 20px;
    font: bold 22px/28px var(--rtd-heading-fontfamily);
  }
  .pageheader__themeiconholder {
    flex: none;
    margin-right: 30px;
    width: 143px;
    height: 143px;
    margin-top: -71px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .pageheader__themeiconholder img {
    display: block;
    width: 100px;
    height: 100px;
  }
}
.pageheader__text__description .widget-button {
  margin-top: 20px;
}

.pageheader .headerbutton {
  display: inline-flex;
  align-items: center;
  height: 44px;
  border: 2px solid #13A538;
  border-radius: 5px;
  font: 500 18px/26px var(--rtd-heading-fontfamily);
  padding-left: 20px;
  padding-right: 40px;
  outline-offset: var(--actionbutton-outline-offset);
  text-decoration: none;
  cursor: pointer;
}

.pageheader .headerbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f054";
  font-size: 18px;
  margin-right: 15px;
}

.pageheader .headerbutton:hover {
  background-color: #13A538;
  color: #FFFFFF;
}