/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.p3dtool__startpage__theme:not(hidden) + .p3dtool__startpage__theme:not(hidden) {
  margin-top: 30px;
}

.pageheader--p3dtool::before {
  content: "";
  grid-column: 1/-1;
  grid-row: 2;
  background-color: #EBEADA;
  margin-bottom: calc(var(--startbutton-height) * -0.5);
}

.pageheader--p3dtool__hdr .line1,
.p3dtool2__aowleeftijd__hdr .line1 {
  color: var(--color-purple);
}

.pageheader--p3dtool__hdr .line1 :first-child,
.p3dtool2__aowleeftijd__hdr .line1 :first-child {
  margin-top: 0;
}

.pageheader--p3dtool__hdr .line2,
.p3dtool2__aowleeftijd__hdr .line2 {
  color: var(--color-green);
  font-weight: bold;
}

.pageheader--p3dtool__text {
  color: #333333;
}

.pageheader--p3dtool__startbutton {
  display: inline-flex;
  align-items: center;
  border: 1px solid #641C76;
  border-radius: 39.5px;
  background-color: #641C76;
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
}

.pageheader--p3dtool__startbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f0da";
  font-size: 40px;
  margin-right: 30px;
}

@media (max-width: 639px) {
  .pageheader--p3dtool {
    --startbutton-height: 66px;
    display: grid;
    grid-template-columns: minmax(15px, calc((100% - 940px) / 2)) minmax(0, 1fr) min-content minmax(15px, calc((100% - 940px) / 2));
    grid-template-rows: min-content min-content min-content;
  }
  .pageheader--p3dtool__hdr {
    grid-column: 2/span 2;
    grid-row: 1;
  }
  .pageheader--p3dtool__hdr {
    font: 26px/28px var(--rtd-heading-fontfamily);
    --rtd-text-font: 26px/28px var(--rtd-heading-fontfamily);
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .pageheader--p3dtool__hdr .line1 {
    margin-bottom: 5px;
  }
  .pageheader--p3dtool__hdr .line2,
  .p3dtool2__aowleeftijd__hdr .line2 {
    font-weight: bold;
  }
  .pageheader--p3dtool__text {
    grid-column: 2;
    grid-row: 2;
    margin-top: 15px;
    margin-bottom: 15px;
    --rtd-text-font: 16px/24px var(--rtd-heading-fontfamily);
  }
  .pageheader--p3dtool__startbutton {
    grid-column: 2/span 2;
    grid-row: 3;
    height: var(--startbutton-height);
    padding: 0 30px;
    width: 90%;
    z-index: 1;
    font: bold 22px/26px var(--rtd-heading-fontfamily);
  }
  /*
    .pageheader--p3dtool__imagewrapper
    {
      grid-column: 3;
      grid-row: 2;

      align-self: end;
      margin-bottom: $startbutton-height-mobile*0.5 - 3px;
    }

    .pageheader--p3dtool__image
    {
      width: 50vw;
    }
  */
  .pageheader--p3dtool__imagewrapper,
  .pageheader--p3dtool__image-outofflow {
    display: contents;
  }
  .pageheader--p3dtool__image {
    grid-column: 3;
    grid-row: 2;
    align-self: end;
    width: 50vw;
    max-height: clamp(200px, 20vw, 350px);
    position: relative;
    object-fit: contain;
  }
  [data-targetaudience=default] .pageheader--p3dtool__image {
    margin-top: -35px;
  }
  [data-targetaudience=nieuwe-baan] .pageheader--p3dtool__image {
    margin-top: -40px;
    margin-bottom: 0px;
  }
  [data-targetaudience=overlijden] .pageheader--p3dtool__image {
    margin-top: -100px;
    margin-bottom: 15px;
  }
  [data-targetaudience=pensioen] .pageheader--p3dtool__image {
    top: -25px;
    margin-bottom: 15px;
  }
  [data-targetaudience=arbeidsongeschikt] .pageheader--p3dtool__image {
    top: -45px;
    margin-bottom: -40px;
  }
  [data-targetaudience=samenwonen-of-trouwen] .pageheader--p3dtool__image {
    top: -35px;
    margin-bottom: -40px;
  }
  [data-targetaudience=verhuizen] .pageheader--p3dtool__image {
    top: -35px;
    margin-bottom: -40px;
  }
  [data-targetaudience=zzper-worden] .pageheader--p3dtool__image {
    top: -35px;
    margin-bottom: -40px;
  }
  [data-targetaudience=kind] .pageheader--p3dtool__image {
    top: -35px;
    margin-bottom: -40px;
  }
  [data-targetaudience=uit-elkaar-gaan] .pageheader--p3dtool__image {
    margin-top: -85px;
    margin-bottom: 10px;
  }
}
@media (max-width: 320px) {
  .pageheader--p3dtool__hdr {
    font: 22px/28px var(--rtd-heading-fontfamily);
    --rtd-text-font: 22px/28px var(--rtd-heading-fontfamily);
  }
}
@media (min-width: 640px) {
  .pageheader--p3dtool {
    --startbutton-height: 79px;
    display: grid;
    grid-template-columns: minmax(15px, calc((100% - 940px) / 2)) minmax(0, 1fr) minmax(0, 1fr) minmax(15px, calc((100% - 940px) / 2));
    grid-template-rows: min-content min-content min-content;
  }
  .pageheader--p3dtool::before {
    min-height: 185px;
  }
  .pageheader--p3dtool__hdr {
    grid-column: 2/span 2;
    grid-row: 1;
    align-self: end;
    margin-top: 30px;
    margin-top: clamp(15px, 2.55vw, 30px);
    margin-bottom: 33px;
    margin-bottom: clamp(15px, 2.55vw, 30px);
    /*
        font: 54px/60px var(--rtd-heading-fontfamily);
        font-size: 30px;
        font-size: unquote("clamp(30px, 4.6vw, 54px)");    // Chrome 79, Safari 13.1+, iOS 13.5+ -
    */
    font: 50px/60px var(--rtd-heading-fontfamily);
    font-size: clamp(30px, 4.8vw, 50px);
    line-height: 125%;
    --rtd-text-font: clamp(30px, 4.8vw, 50px)/125% var(--rtd-heading-fontfamily);
  }
  .pageheader--p3dtool__hdr .line1 > * {
    margin-top: 0;
  }
  .pageheader--p3dtool__hdr .line2 {
    font: bold clamp(26px, 2.9vw, 33px)/125% var(--rtd-heading-fontfamily);
  }
  .pageheader--p3dtool__hdr .line2 br {
    display: none;
  }
  .pageheader--p3dtool__text {
    grid-column: 2;
    grid-row: 2;
    align-self: center;
    padding: 20px 0 17px 0;
  }
  .pageheader--p3dtool__text.pageheader--p3dtool__text > * {
    font: 21px/28px var(--rtd-heading-fontfamily);
  }
  .pageheader--p3dtool__startbutton {
    grid-column: 2;
    grid-row: 3;
    height: var(--startbutton-height);
    /*
    fullwidth (original design)
    min-width: 100%;
    padding: 0 20px;
    */
    width: max-content;
    padding: 0 80px 0 40px;
    font: bold 30px var(--rtd-heading-fontfamily);
    font-size: clamp(22px, 3vw, 30px);
    line-height: 100%;
  }
  .pageheader--p3dtool__imagewrapper,
  .pageheader--p3dtool__image-outofflow {
    display: contents;
  }
  .pageheader--p3dtool__image {
    grid-column: 3;
    grid-row: 1/span 2;
    display: block;
    margin-left: auto;
    max-width: 100%;
    max-height: clamp(230px, 27vw, 270px);
    align-self: end; /* at the bottom of the bar */
    justify-self: end; /* align at the page content right */
    margin-bottom: calc(var(--startbutton-height) * -0.5);
  }
  [data-targetaudience] .pageheader--p3dtool__image {
    margin-bottom: 0;
  }
  [data-targetaudience=default] .pageheader--p3dtool__image {
    margin-top: 0px;
    margin-bottom: calc(var(--startbutton-height) * -0.5);
  }
  [data-targetaudience=nieuwe-baan] .pageheader--p3dtool__image {
    margin-top: -40px;
  }
  [data-targetaudience=samenwonen-of-trouwen] .pageheader--p3dtool__image {
    margin-right: min(60px, 8%);
  }
  [data-targetaudience=verhuizen] .pageheader--p3dtool__image {
    margin-right: min(60px, 8%);
  }
  [data-targetaudience=zzper-worden] .pageheader--p3dtool__image {
    margin-right: min(60px, 8%);
  }
  [data-targetaudience=kind] .pageheader--p3dtool__image {
    margin-right: min(60px, 8%);
  }
  /*
    .pageheader--p3dtool__imagewrapper
    {
      margin-left: 5%;

      grid-column: 3;
      grid-row: 1 / span 2;
      align-self: end;
      position: relative;
      height: 100%;
    }

    // Take out of flow, make 100% on the image mean actual available height
    .pageheader--p3dtool__image-outofflow
    {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0; // needed for Firefox
      right: 0;
    }

    .pageheader--p3dtool__image
    {
      display: block;
      margin-left: auto;
      height: 100%;
    }
  */
}