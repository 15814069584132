/*
@mixin wh-form-hidenativecontrol
{
  // display: none or visibility: hidden; will prevent focus!!
  position: absolute !important; // to not mess with flex containers
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  opacity: 0;

  margin: 0;
}

@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.effecttool__youranswers {
  display: none;
}

.p3dtool2__results__content > * {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 750px) {
  .p3dtool2__aowleeftijd + .p3dtool2__results__content {
    margin-top: 21px;
  }
  .effecttool__group + .effecttool__group,
  .effecttool__effect + .effecttool__effect {
    margin-top: 25px;
  }
  .p3dtool2__sendresultslink {
    margin-top: 30px;
  }
  .p3dtool2__sendresultslink,
  .p3dtool2__results__sharetool {
    width: auto;
    max-width: 970px;
    margin-left: var(--sidepadding);
    margin-right: var(--sidepadding);
  }
}
@media (min-width: 751px) {
  .p3dtool2__aowleeftijd + .p3dtool2__results__content {
    margin-top: 35px;
  }
  .effecttool__group + .effecttool__group,
  .effecttool__effect + .effecttool__effect {
    margin-top: 25px;
  }
  .p3dtool2__sendresultslink {
    margin-top: 32px;
  }
}
.p3dtool2__aowleeftijd__hdr {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  padding-top: 15px;
  padding-bottom: 5px;
}

.pageheader--p3dtool__hdr .line1,
.pageheader--p3dtool__hdr .line2 {
  font-weight: bold;
}

.p3dtool2__aowleeftijd__panel {
  background-color: #EBEADA;
}

.p3dtool2__aowleeftijd__panel__content {
  display: flex;
  flex-wrap: wrap;
}

.aowleeftijd_prefix {
  display: block;
  margin-bottom: 4px;
}

.p3dtool2__aowleeftijd__panel__header {
  flex: 1 0 0px;
  color: var(--color-purple);
  margin-right: -14%;
}

.aowleeftijd__months {
  white-space: nowrap;
}

.p3dtool2__aowleeftijd__panel__image {
  margin-top: -20%;
  align-self: end;
  width: 58%;
  max-width: 374px;
}

.p3dtool2__aowleeftijd__greenpanel {
  width: 100%;
  border-radius: 8px;
  background-color: #25A22F;
  color: #FFFFFF;
}

.aowleeftijd_prefix {
  font: var(--p3dtool-aowleeftijd-prefix-font);
}

.aowleeftijd {
  font: var(--p3dtool-aowleeftijd-font);
}

@media (min-width: 751px) {
  .page--p3dtool {
    --p3dtool-aowleeftijd-prefix-font: 600 30px/36px var(--rtd-heading-fontfamily);
    --p3dtool-aowleeftijd-font: bold clamp(38px, 4vw, 48px) var(--rtd-heading-fontfamily);
  }
  .p3dtool2__aowleeftijd__panel {
    padding: 25px 0px 30px 0px;
  }
  .p3dtool2__aowleeftijd__panel__content {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
  }
  .p3dtool2__aowleeftijd__panel__header {
    margin-bottom: 30px;
  }
  .p3dtool2__aowleeftijd__greenpanel {
    padding: 18px 25px;
    font: 400 21px/28px var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 750px) {
  .page--p3dtool {
    --p3dtool-aowleeftijd-prefix-font: bold 21px/24px var(--rtd-heading-fontfamily);
    --p3dtool-aowleeftijd-font: bold 42px/90% var(--rtd-heading-fontfamily);
  }
  .p3dtool2__aowleeftijd__panel {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
    padding-top: 17px;
    padding-bottom: 19px;
  }
  .p3dtool2__aowleeftijd__panel__header {
    margin-bottom: 18px;
  }
  .p3dtool2__aowleeftijd__hdr .line1 {
    margin-bottom: 10px;
    font: bold 24px/26px var(--rtd-heading-fontfamily);
  }
  .p3dtool2__aowleeftijd__hdr .line2 {
    font: bold 24px/26px var(--rtd-heading-fontfamily);
  }
  .aowleeftijd_prefix {
    margin-bottom: 6px;
  }
  .p3dtool2__aowleeftijd__panel__image {
    margin-top: -18%;
  }
  .p3dtool2__aowleeftijd {
    overflow: hidden;
  }
  .p3dtool2__aowleeftijd__panel__image {
    margin-right: -9%;
  }
  .p3dtool2__aowleeftijd__greenpanel {
    padding: 10px 12px;
    font: 500 18px/26px var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 390px) {
  .p3dtool2__aowleeftijd__hdr .line1 {
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 120%;
  }
  .p3dtool2__aowleeftijd__hdr .line2 {
    font-size: 20px;
    line-height: 120%;
  }
  .p3dtool2__aowleeftijd__hdr .line2 br {
    display: none;
  }
  .aowleeftijd_prefix {
    margin-right: -60px;
  }
  .page--p3dtool {
    --p3dtool-aowleeftijd-prefix-font: bold 21px/24px var(--rtd-heading-fontfamily);
    --p3dtool-aowleeftijd-font: bold 33px/90% var(--rtd-heading-fontfamily);
  }
}
@media (max-width: 350px) {
  .p3dtool2__aowleeftijd__panel__image {
    width: 52%;
  }
}
@media (max-width: 639px) {
  .p3dtool2__aowleeftijd__hdr {
    margin-bottom: 10px;
    font: 30px/33px var(--rtd-heading-fontfamily);
  }
  .p3dtool2__aowleeftijd__hdr .line2 {
    font-weight: bold;
  }
}
@media (min-width: 640px) {
  .p3dtool2__aowleeftijd__hdr {
    margin-bottom: 33px;
    margin-bottom: clamp(15px, 2.55vw, 30px);
    font: 54px/60px var(--rtd-heading-fontfamily);
    font-size: 30px;
    font-size: clamp(30px, 4.6vw, 54px);
    line-height: 125%;
  }
}
.p3dtool2__resultsbutton {
  display: inline-flex;
  align-items: center;
  height: 43px;
  border: 1px solid #641C76;
  border-radius: 21.5px;
  background-color: #641C76;
  color: #FFFFFF;
  font: bold 18px var(--rtd-heading-fontfamily);
  text-decoration: none;
  padding-left: 18px;
  padding-right: 20px;
}

.p3dtool2__resultsbutton:hover {
  background-color: #FFFFFF;
  color: var(--color-purple);
}

.p3dtool2__resultsbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: var(--fa-chevron-right);
  margin-right: 15px;
}

.p3dtool2__sendresultslink {
  background: var(--widget-highlight-background);
  padding: var(--widget-highlight-pad-v) var(--widget-highlight-pad-h) var(--widget-highlight-pad-v) var(--widget-highlight-pad-h);
  border-radius: 8px;
}

.p3dtool2__sendresultslink .heading {
  color: var(--widget-highlight-title-color);
  font: var(--p3dtool-results-heading-font);
  margin-top: -5px;
}

.p3dtool2__sendresultslink__form__emailholder {
  margin-top: 10px;
  position: relative;
  width: 100%;
  max-width: 341px;
  display: flex;
  align-items: center;
}

.p3dtool2__sendresultslink__form .heading::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: var(--fa-envelope);
  font-size: 22px;
  margin-right: 11px;
}

.p3dtool2__sendresultslink__form input[name=email] {
  width: 100%;
  border: 2px solid var(--color-green);
  font-size: 16px;
}

.p3dtool2__sendresultslink__form button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  position: absolute;
  right: 15px;
  padding: 4px 6px;
}

.p3dtool2__sendresultslink__form button::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 18px;
  color: var(--color-green);
}

.p3dtool2__sendresultslink__success {
  display: none;
}

.p3dtool2__sendresultslink--success .p3dtool2__sendresultslink__form {
  display: none;
}
.p3dtool2__sendresultslink--success .p3dtool2__sendresultslink__success {
  display: block;
}

.p3dtool2__results__sharetool__buttonbar {
  margin-top: 25px;
  display: block;
  font: 16px/20px var(--rtd-heading-fontfamily);
  color: var(--color-purple);
}

.p3dtool2__results__sharetool__buttonbar__sharelabel {
  display: block;
  margin-right: 14px;
  margin-bottom: 7px;
}