/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.filteredoverview__teaser__button {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.filteredoverview__teaser {
  display: none;
  position: -webkit-sticky;
  position: sticky;
  margin-top: -35px;
  z-index: var(--filteredoverview-teaserbar-zindex);
  bottom: 11px;
  width: 100%;
  opacity: 0;
  transition: opacity 0.5s;
  pointer-events: none;
  cursor: pointer;
}

a.filteredoverview__teaser {
  text-decoration: none;
}

.filteredoverview__teaser__content {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  height: 71px;
  border-radius: 8px;
  background: var(--color-theme-background);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.32);
  position: relative;
  width: 100%;
  max-width: min(100% - var(--sidepadding) * 2, 940px);
  margin-left: auto;
  margin-right: auto;
  height: 70px;
  display: flex;
  align-items: center;
}

.filteredoverview__teaser__content:hover {
  background: linear-gradient(180deg, #0B923E 0%, #0B923E 100%);
}

html.filteredoverview--showfilters .filteredoverview__teaser {
  display: block;
  opacity: 1;
  pointer-events: auto;
}

/*
.filteredoverview__teaser--aboveresults
{
  opacity: 1;
  pointer-events: auto;
}
*/
.filteredoverview__teaser__label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  transition: background-color 0.25s;
}
.filteredoverview__teaser__label:focus {
  box-shadow: 0 0 0 2px #000;
  outline: 0;
}
@supports (outline: -webkit-focus-ring-color auto 5px) {
  .filteredoverview__teaser__label:focus {
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 5px;
  }
}
.filteredoverview__teaser__label:active:focus {
  box-shadow: none;
  outline: none;
}

.filteredoverview__teaser__label b {
  margin-left: 7px;
  margin-right: 5px;
}

.filteredoverview__teaser__label::after {
  margin-left: 18px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-arrow-circle-down);
  content: var(--fa-arrow-circle-down)/"";
}

.filteredoverview__teaser__content {
  height: clamp(50px, 10vw, 70px);
}

.page--huishoudboekjes .filteredoverview__teaser__content,
.page--publications .filteredoverview__teaser__content {
  height: clamp(40px, 10vw, 60px);
}

.filteredoverview__teaser__label {
  font: 18px var(--rtd-heading-fontfamily);
  font: clamp(18px, 3.5vw, 24px) var(--rtd-heading-fontfamily);
}

.filteredoverview__teaser__label::after {
  font-size: 27px;
  font-size: clamp(26px, 4.5vw, 32px);
}

.infobutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  margin-left: auto;
  outline-offset: 3px;
}

.infobutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: var(--fa-info-circle);
  font-size: 18px;
  color: var(--color-green);
}

.page--publications .filteredoverview__feedbackandviewbar__content {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: 15px;
  row-gap: 8px;
  margin-bottom: 18px;
  padding-bottom: 10px;
  border-bottom: 1px solid #D7D7D7;
}

.filteredoverview__feedbackandviewbar {
  margin-top: 20px;
}

.filteredoverview-action--resetfilters {
  display: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #656565;
  padding: 4px 8px;
  margin-left: 2px;
  text-decoration: underline;
  cursor: pointer;
}
html.filteredoverview--filtersactive .filteredoverview-action--resetfilters {
  display: block;
}