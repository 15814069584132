.pageheader__filterbar,
.pageheader__expandedfilters,
.comparisontable,
.huishoudboekjes__mobile-filters {
  --tooltip-background: var(--color-purple);
  --tooltip-font: 15px/20px var(--rtd-text-fontfamily);
}
.pageheader__filterbar .tippy-box.tippy-box,
.pageheader__expandedfilters .tippy-box.tippy-box,
.comparisontable .tippy-box.tippy-box,
.huishoudboekjes__mobile-filters .tippy-box.tippy-box {
  background-color: var(--tooltip-background);
  color: #FFFFFF;
  border-radius: 5px;
  font: var(--tooltip-font);
  --rtd-text-font: var(--tooltip-font);
  max-width: 320px;
  padding: 10px 13px 13px 13px;
  filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.26));
}
.pageheader__filterbar .tippy-content,
.pageheader__expandedfilters .tippy-content,
.comparisontable .tippy-content,
.huishoudboekjes__mobile-filters .tippy-content {
  word-break: break-word; /* in case of very long words/urls or stray non-breaking spaces make sure the line still wraps instead of overflowing the tooltip */
}
.pageheader__filterbar .tippy-content h2,
.pageheader__expandedfilters .tippy-content h2,
.comparisontable .tippy-content h2,
.huishoudboekjes__mobile-filters .tippy-content h2 {
  font-size: 20px;
  color: #FFFFFF;
}
.pageheader__filterbar .tippy-box[data-placement^=top] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before,
.pageheader__expandedfilters .tippy-box[data-placement^=top] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before,
.comparisontable .tippy-box[data-placement^=top] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before,
.huishoudboekjes__mobile-filters .tippy-box[data-placement^=top] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before {
  border-top-color: var(--tooltip-background);
  border-left-width: 14px;
  border-right-width: 14px;
  border-top-width: 14px;
  transform: none;
  bottom: -14px;
  margin-left: -7px;
}
.pageheader__filterbar .tippy-box[data-placement^=bottom] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before,
.pageheader__expandedfilters .tippy-box[data-placement^=bottom] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before,
.comparisontable .tippy-box[data-placement^=bottom] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before,
.huishoudboekjes__mobile-filters .tippy-box[data-placement^=bottom] .tippy-arrow.tippy-arrow.tippy-arrow.tippy-arrow::before {
  border-bottom-color: var(--tooltip-background);
  border-left-width: 14px;
  border-right-width: 14px;
  border-bottom-width: 14px;
  transform: none;
  top: -14px;
  margin-left: -7px;
}