/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
html.filteredoverview--interacted .pubcategories__header, html.filteredoverview--interacted .pubcategories,
html.filteredoverview--filtersactive .pubcategories__header,
html.filteredoverview--filtersactive .pubcategories {
  display: none;
}

html.page--publicationcategories:not(.filteredoverview--filtersactive) .filteredoverview__feedbackandviewbar__content {
  display: none;
}

.pubcategories {
  list-style: none;
}

.pubcategories li {
  margin: 0;
  padding: 0;
}

.pubcategories__header {
  margin-bottom: 35px;
}

.pubcategories__header .fa {
  margin-left: 5px;
  font-size: 22px;
}

@media (min-width: 480px) {
  .pubcategories {
    display: grid;
  }
  .pubcategories::after {
    display: block;
    content: "";
  }
  .pubcategories__item {
    display: flex;
    flex-direction: column;
    text-align: left;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
    /*
      border-top: 1px solid #EEEEEE;
      border-right: 1px solid #D2D2D2;
      border-bottom: 1px solid #A2A2A2;
      border-left: 1px solid #CCCCCC;
      border-radius: 8px;
      box-shadow: -1px 2px 3px rgba(0,0,0,0.15);
    */
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    padding: 2px 2px 2px 2px;
    margin: 0;
    cursor: pointer;
  }
  a.pubcategories__item {
    display: block;
    text-decoration: none;
  }
  .pubcategories__item:focus,
  .pubcategories__item:hover {
    border-color: #858585;
    background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
  }
  .pubcategories__item__image {
    border-radius: 6px 6px 0 0;
    width: 100%;
    overflow: hidden;
    padding-top: 40%;
    background-color: #AAAAAA;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .pubcategories__item__title {
    display: flex;
    font: 600 22px/24px TypoPRO Dosis, Arial;
    color: var(--color-purple);
    padding: 18px;
  }
  .pubcategories__item__title::before {
    font: var(--fontawesome-solid);
    content: var(--fa-chevron-right);
    font-size: 22px;
    margin-right: 10px;
  }
}
@media (min-width: 768px) {
  .pubcategories {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 20px;
    gap: 20px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .pubcategories {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 15px;
    gap: 15px;
  }
}
@media (max-width: 479px) {
  .pubcategories {
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
    /*
      border-top: 1px solid #EEEEEE;
      border-right: 1px solid #D2D2D2;
      border-bottom: 1px solid #A2A2A2;
      border-left: 1px solid #CCCCCC;
      border-radius: 8px;
      box-shadow: -1px 2px 3px rgba(0,0,0,0.15);
    */
    color: #333333;
    font: normal 15px "Arial";
    position: relative;
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
  }
  .pubcategories__item {
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    text-decoration: none;
    min-height: 39px;
    border-bottom: 1px solid #CFCFCF;
    padding-left: 11px;
    padding-right: 8px;
  }
  .pubcategories__item__image {
    display: none;
  }
  .pubcategories__item__title {
    display: flex;
    align-items: center;
    width: 100%;
    font: normal 18px "TypoPRO Dosis";
  }
  .pubcategories__item__title::after {
    display: inline-block;
    font: normal normal normal 22px/1 "Font Awesome 5 Pro";
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: "\f054";
    margin-left: auto;
    color: #CFCFCF;
  }
}