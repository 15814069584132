.page--publications {
  /*
  .filteredoverview__teaser--aboveresults
  {
    opacity: 1;
    pointer-events: auto;
  }
  */
}
.page--publications .filteredoverview__teaser {
  display: none;
  position: -webkit-sticky;
  position: sticky;
  margin-top: -35px;
  z-index: var(--filteredoverview-teaserbar-zindex);
  bottom: 11px;
  width: 100%;
  opacity: 0;
  transition: opacity var(--button-transition-duration);
  pointer-events: none;
  cursor: pointer;
}
.page--publications a.filteredoverview__teaser {
  text-decoration: none;
}
.page--publications .filteredoverview__teaser__content {
  height: 71px;
  border-radius: 8px;
  background: var(--color-theme-background);
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.32);
  position: relative;
  height: 70px;
  display: flex;
  align-items: center;
}
.page--publications .filteredoverview__teaser__content:hover {
  background: linear-gradient(180deg, #0B923E 0%, #0B923E 100%);
}
.page--publications html.filteredoverview--showfilters .filteredoverview__teaser {
  display: block;
  opacity: 1;
  pointer-events: auto;
}
.page--publications .filteredoverview__teaser__label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: #FFFFFF;
  transition: background-color var(--button-transition-duration);
}
.page--publications .filteredoverview__teaser__label:focus {
  box-shadow: 0 0 0 2px #000;
  outline: 0;
}
@supports (outline: -webkit-focus-ring-color auto 5px) {
  .page--publications .filteredoverview__teaser__label:focus {
    box-shadow: none;
    outline: -webkit-focus-ring-color auto 5px;
  }
}
.page--publications .filteredoverview__teaser__label:active:focus {
  box-shadow: none;
  outline: none;
}
.page--publications .filteredoverview__teaser__label::after {
  margin-left: 18px;
  font: var(--fontawesome-solid);
  content: var(--fa-arrow-circle-down);
  content: var(--fa-arrow-circle-down)/"";
}
.page--publications .filteredoverview__teaser__content {
  height: clamp(50px, 10vw, 70px);
}
.page--publications .filteredoverview__teaser__label {
  font: 18px var(--rtd-heading-fontfamily);
  font: clamp(18px, 3.5vw, 24px) var(--rtd-heading-fontfamily);
}
.page--publications .filteredoverview__teaser__label::after {
  font-size: 27px;
  font-size: clamp(26px, 4.5vw, 32px);
}