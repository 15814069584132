.widgetsgrid--cssbased .widgetsgrid__inner > *:first-child
{
  margin-top:  0;
}

/*.widgetsgrid:not(.widgetsgrid--cssbased) .widgetsgrid__inner .widget*/
.widgetsgrid__inner[data-dompack-masonry-active="true"] .widget
{
  margin-top: 0 !important; /* JS will now handle positioning */
}


@media (max-width: 639px)
{
  .widgetsgrid--transparent
  {
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }

    .widgetsgrid--cssbased .widgetsgrid__inner > * + *
  , [data-dompack-masonry-active="false"] > * + *
  {
    margin-top: 30px;
  }
}

@media (min-width: 640px)
{
  .widgetsgrid--transparent
  {
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
/*
  .widgetsgrid--cssbased:not(.widgetsgrid--equalize) .widgetsgrid__inner
  {
    display: flex;
    flex-wrap: wrap;
  }

  .widgetsgrid--cssbased .widgetsgrid__inner > *
  {
    flex: 0 0 calc(50% - 15px);
  }

  .widgetsgrid--cssbased .widgetsgrid__inner > * + *
  {
    margin-top:  0;
  }
*/
}




/*********************************************

Widgets grid
(in which JS will manage the widget positions)

*********************************************/

.widgetsgrid .widgetsgrid__inner
{
  position: relative;
}

.widgetsgrid .widget
{
  box-shadow: 0px 1px 2px rgba(0,0,0,0.40);
}

.widgetsgrid.no-widgets
{
  /* make the element be like display:none; for layout purposes,
     while in the background having it still reflown so the masonry
     script can measure blocks

     !important so not having problems with specificy (and accidental overriding)
  */
  position: absolute !important;
  height: 0 !important;
  overflow: hidden !important;
  visibility: hidden !important;
  margin: 0 !important;
}


/*********************************************

Solid (green or purple) widgets grid

*********************************************/

/*
Media query specific settings/breakpoints can be found in:
wijzeringeldzaken/css/layout.scss:
*/
.widgetsgrid--solid
{
  position: relative;
  padding: var(--widgetsgrid-solid-padding);
}

.widgetsgrid--green
{
  background-color: #0db24c;
  /*background-color: var(--color-green-large);*/
}

.widgetsgrid--purple
{
  background-color: var(--color-purple);
}

/* Trianble indent in the widgetsgrid background */
.widgetsgrid--solid::before
{
  content:  "";

  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -27px;
  width: 0;
  height: 0;
  border-top: 14px solid #fff;
  border-right: 27px solid transparent;
  border-left: 27px solid transparent;
}

.widgetsgrid--solid .widget
{
  background-color: #FFFFFF;
  border-radius: 10px;

  box-shadow: 0px 1px 3px rgba(0,0,0,0.60);
  color: #333333;
}


/* if not in the actual grid, but directly under it (still in the green), give it a fancy top border */
.widgetsgrid--solid > .widget
{
  width: 100%;
  margin-top: 20px;
  padding-top: 23px;
  background: transparent url("../web/img/green-border.png") repeat-x scroll top left;
}




.widgetgrouptitle h2
{
  text-align: center;
  margin-top: 25px;

  margin-bottom: 25px;
}

.groupline
{
  margin-top: 25px;
  position: relative;
}

.widgetgrouptitle + .groupline .hr
{
  margin-top: 15px;
}
