/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.widgetsgrid--tenyears .widget {
  border: 2px solid #642382;
}

.widgetsgrid--tenyears .widget:nth-child(2n) {
  transform: rotateZ(2deg);
}

.widgetsgrid--tenyears .widget:nth-child(2n+1) {
  transform: rotateZ(-2deg);
}

.widgetsgrid--tenyears .title {
  background-color: #642382;
  color: #FFFFFF;
}

.widgetsgrid--tenyears .widget.default .widget_body {
  padding-top: 0;
}

.widgetsgrid--tenyears .widget.default > .widget_body > .title {
  margin-left: -18px;
  margin-right: -18px;
  padding: 10px 18px;
  font-size: 16px;
  line-height: 20px;
}

.widgetsgrid--tenyears .widget.default img.widgetimage {
  margin: 0;
  padding: 0;
  border-radius: 0;
}