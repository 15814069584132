/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/*
NOTE: De laatste gebruikers zijn de nieuws (/pers) pagina's
Publicaties en huishoudboekjes gebruiken dit niet meer.
*/
#pagesearchtabs > #summaryfilter {
  padding: 15px var(--sidepadding);
  background-color: var(--color-panel-background);
  display: none;
}

#pagesearchtabs.search > #summaryfilter {
  display: block;
}

html.page--publications #pagesearchtabs.search #summaryfilter {
  display: flex;
}

/* pagetabs */
#pagesearchtabs {
  display: none;
}

html.layout_tablet #pagesearchtabs,
html.layout_mobile #pagesearchtabs {
  display: block;
  padding: 0;
}

#pagesearchtabs > ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ddd;
  display: block;
  height: 34px;
  white-space: nowrap;
  padding-left: var(--sidepadding);
}

#pagesearchtabs > ul > li {
  display: inline-block;
}

#pagesearchtabs > ul > li button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  font: inherit;
  height: 34px;
  padding: 5px 12px;
  margin: 1px 3px -1px;
  cursor: pointer;
  display: inline-block;
  color: var(--color-green-small);
  line-height: 22px;
  border: 1px solid transparent;
  border-radius: 5px 5px 0 0;
}

#pagesearchtabs > ul > li.active button,
#pagesearchtabs > ul > li:hover button {
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom: 1px solid #fff;
}