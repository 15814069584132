html
{
  /************************************************************
   *
   *  Page components / Navigation path (crumpath)
   */

/*
  --component-navpath-color:          #656565;
  --component-navpath-link-color:     #656565;
  --component-navpath-font:           15px Arial;
  --component-navpath-gap-v:          4px;
  --component-navpath-prefix-margin:  10px; / * extra space between prefix and items * /

  --component-navpath-item-padding-h: 5px;
  --component-navpath-item-padding-v: 5px;
*/
}

html
{
  /************************************************************
   *
   *  Page components / Pagination
   */

  --pagination-border: 1px solid var(--common-item-divider-line-color);

  --pagination-iconfont:                  var(--fontawesome-solid);

  --pagination-prevnext-border:           2px solid transparent;
  --pagination-prevnext-spacing:          10px;
  --pagination-prevnext-background:       var(--color-theme); /* #ECE4EC; */
  --pagination-prevnext-icon-color:       #FFFFFF;

  --pagination-previous-icon-content:     var(--fa-arrow-left);
  --pagination-previous-icon-size:        16px;
  --pagination-next-icon-content:         var(--fa-arrow-right);
  --pagination-next-icon-size:            16px;
  --pagination-ellipsis-icon-content:     var(--fa-ellipsis-h); /* "…" */
  --pagination-ellipsis-icon-size:        26px;
  --pagination-ellipsis-icon-font:        var(--fontawesome-light);

  --pagination-prevnext-hover-background: #FFFFFF;
  --pagination-prevnext-hover-border:     2px solid var(--color-theme);
  --pagination-prevnext-hover-icon-color: var(--color-theme);

  --pagination-prevnext-disabled-background: #EEEEEE;
  --pagination-prevnext-disabled-border:  2px solid transparent;
/*  --pagination-prevnext-hover-icon-color: var(--color-theme);*/

  --pagination-font:                 20px/32px var(--rtd-text-fontfamily);
  --pagination-page-link-color:      #000000;
  --pagination-page-border:          2px solid transparent;
  --pagination-page-size:            54px;
  --pagination-page-radius:           7px;
  --pagination-page-spacing:          5px;

  --pagination-page-current-background: #F0F0F0;
  --pagination-page-current-border:  2px solid transparent;
  --pagination-page-current-color:   var(--color-theme);

  --pagination-page-hover-background: var(--color-purple3);
  --pagination-page-hover-border:     2px solid var(--color-theme);



  /************************************************************
   *
   *  Filteredoverview / Teaser bar
   */

  --color-textblack: #333333;


  --filteredoverview-feedbackbar-color:     var(--color-textblack);
  --filteredoverview-feedbackbar-font:      18px/24px var(--rtd-heading-fontfamily);


  /* Teaserbar in page */
  --filteredoverview-teaserbar-background:   var(--color-theme-text);
  --filteredoverview-teaserbar-border:       0;
/*  --filteredoverview-teaserbar-borderradius: 0;*/

  --filteredoverview-teaserbar-borderradius: 8px 8px 0 0; /*var(--custom-radius) var(--custom-radius) 0 0;*/
/*  var(--formcontrol-buttonbar-item-borderradius);*/

  --filteredoverview-teaserbar-color:        #FFFFFF;
  --filteredoverview-teaserbar-height:       64px;

  --filteredoverview-teaserbar-hover-background: var(--color-theme-text-hover);
  --filteredoverview-teaserbar-hover-color: #FFFFFF;
  --filteredoverview-teaserbar-font: 600 18px/30px var(--rtd-text-fontfamily);
}

.page--huishoudboekjes
{
  --filteredoverview-teaserbar-height: 48px;
  --filteredoverview-teaserbar-font: 18px/30px var(--rtd-heading-fontfamily);
}



.filteredoverview__teaser__button
{
/*  text-align: center;*/
  justify-content: center;
}

.filteredoverview__teaser__label:after
{
  content: ">";
  margin-left: 15px;
  font: var(--fontawesome-regular);
  content: var(--fa-angle-right);
  font-size: 23px;
  margin-top: 2px;
}
