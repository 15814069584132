@charset "UTF-8";
/*
.multiselect__summary.multiselect__summary.multiselect__summary
{
  margin-top: 7px;
  margin-bottom: 0px;
}
*/
.multiselect__summaryitem {
  display: flex;
  align-items: baseline;
  font: 15px/18px var(--rtd-text-fontfamily);
  padding-top: 1px;
  padding-bottom: 1px;
  cursor: pointer;
  /*
    border-radius: var(--formcontrols-radius);

    .multiselect__summaryitem__badge
    {
      border: 1px solid transparent;
    }
  */
}

/*
.multiselect__summaryitem:hover
{
  background: #F0F0F0;

  .multiselect__summaryitem__badge
  {
    border: 1px solid #BBBBBB;
  }
}
*/
.multiselect__summaryitem:last-child {
  margin-bottom: -8px; /* compensate for the extra white-space */
}

.multiselect__summaryitem__remove {
  /* override button styling */
  text-align: left;
  background: none;
  border: 0;
  margin-right: 3px;
  padding: 7px;
  margin-left: -2px;
  border-radius: 3px;
  cursor: pointer;
  transition: scale var(--button-transition-duration);
}
.multiselect__summaryitem__remove:hover {
  background: rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}

.multiselect__summaryitem__remove::before {
  --fa-trash: "";
  --fa-trash-alt: "";
  --siteicon-trash: var(--fa-trash-alt);
  flex: none;
  font: var(--fontawesome-regular);
  font-size: 16px;
  content: var(--siteicon-trash);
  color: var(--color-theme);
}

.multiselect__summaryitem__badge {
  margin-left: 5px;
}