/*

NOTES:

- margins on each successive <p> or embeddedobject is done
  by giving each paragraph, embeddedobject etc a margin-bottom in richdocuments.css
*/

/* print link */
.embeddedobject .printlink
{
  color: #ccc;
}
@media print
{
  .embeddedobject .printlink
  {
    display: block !important;
  }
}


/*
Embeddedobject title with arrow
*/

/* FIXME: obsolete ?? */
  .embeddedobject.companylist > h3
, .embeddedobject.ctablocks > h3
, .embeddedobject.ctalinks > h3
, .embeddedobject.downloads > h3
{
  font: 600 22px/28px 'TypoPRO Dosis', Arial; /* FIXME: handle this in rtd-shared.css */
  padding-right: 28px;
  position: relative;
  /*display: inline-block;*/
}

/* FIXME: obsolete ?? */
  .embeddedobject.companylist > h3 > img
, .embeddedobject.ctablocks > h3 > img
, .embeddedobject.ctalinks > h3 > img
, .embeddedobject.downloads > h3 > img
{
  position: absolute;
  display: block;
  width: 18px;
  height: 11px;
  top: 13px;
  right: 0;
}



/*
blocksview, used in
  - .ctablocks
  - .companylist
  - .p3dorganizations
*/
  .embeddedobject.ctablocks
, .embeddedobject.companylist
{
  position: relative;
}


/*ul.blocks*/
@media (min-width: 900px)
{
  main.layout_tall .companylist__blocks
  {
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    grid-gap: 15px;
    gap: 15px;
  }
}

@media (min-width: 480px) and (max-width: 899px)
{
  main.layout_tall .companylist__blocks
  {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 15px;
    gap: 15px;
  }
}


@media (min-width: 750px)
{
  main.contentwithin_wide .companylist__blocks
  {
    display: grid;
    grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
    grid-gap: 15px;
    gap: 15px;
  }
}

@media (min-width: 480px) and (max-width: 749px)
{
  main.contentwithin_wide .companylist__blocks
  {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 15px;
    gap: 15px;
  }
}


/* ctablocks has a big drop shadow */
.embeddedobject.ctablocks .block
{
  padding: 16px 40px;
  box-shadow: -1px -3px 6px rgba(0,0,0,0.25);
}

  .embeddedobject.companylist .block
, .embeddedobject.p3dorganizations .block
{
  padding: 15px; /* anders past het allemaal niet */
  box-shadow: 0px 0px 3px rgba(0,0,0,0.25);

  background: none;
  background-color: #FFFFFF;
  color: #333333;
}

.embeddedobject.companylist .block img
{
  display: block;
  max-width: 100%;
}
  .embeddedobject.companylist .block strong
, .embeddedobject.p3dorganizations .block .title
{
  position: absolute;
  left: 15px;
  bottom: 15px;
  line-height: 20px;

  display: block;
  font-weight: normal;
/*  margin-top: 20px;*/
}




.widget--iframe iframe
{
  width:  100%; /* IE & Edge */
  width:  -webkit-fill-available; /* Chrome 22+ & SF6.1+ */
  width:  -moz-available; /* FF3+ */

  height: 1000px;
  border: 0;
}




.embeddedobject.video
{
  display: block;
  position: relative;

  background-color: #000;
  text-align: center;
  vertical-align: middle;
  overflow: hidden;
  /*border: 1px solid #000;*/
}

.embeddedobject.video:after
{
  content: "";
  padding-top: 56.25%;
  display: block;
}

.embeddedobject.video img
{
  width: 100%;
  /* center image: */
  position: absolute;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.embeddedobject.video button
{
  text-decoration: none;
  cursor: pointer;
}


.embeddedobject.video .videotitlebar
{
  display: none;
  /*
  position: absolute;
  display: inline-block;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: .85;
  width: 100%;
  height: 30px;
  line-height: 28px;
  text-align: left;
  padding-left: 40px;
  vertical-align: middle;
  background-color: #000;
  */
}

.embeddedobject.video .videotitlebar span
{
  color: #fff;
  margin-left: -25px;
}

.embeddedobject.video iframe
{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}



/* Style WBVDM embedded object version */
.wbdvmtool
{
  position:relative;
  min-height:60px;
}

/* Webbridge tools kwamen onder widgets terecht. beperk de breeedte zodat het wel past anders cleart de iframe */
.embobj--webbridgetool
{
  max-width:580px;
}
