.liveblog__entries {
  position: relative;
  margin-left: 6px;
  margin-top: 20px;
  border-left: 1px solid rgba(13, 178, 76, 0.5);
}

.liveblog__entries::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: -7px;
  width: 13px;
  border-bottom: 1px solid #0DB24C;
}

.liveblog__entry {
  position: relative;
  margin-bottom: 30px;
}

.liveblog__entry::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: -7px;
  border-radius: 50%;
  background-color: #0DB24C;
  width: 13px;
  height: 13px;
}

.liveblog__entrycontent {
  padding-left: 15px;
}

.liveblog__entrytime {
  position: relative;
  top: -3px;
  padding-left: 15px;
  margin-bottom: 10px;
  color: #9E9E9E;
  font: 600 14px/20px "TypoPRO Dosis", Arial;
}