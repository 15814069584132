/*
@mixin wh-form-hidenativecontrol
{
  // display: none or visibility: hidden; will prevent focus!!
  position: absolute !important; // to not mess with flex containers
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  opacity: 0;

  margin: 0;
}

@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
/*.page--p3dtool*/
.effecttool__group {
  --button-color: var(--color-purple);
  --button-height: 40px;
  --button-font: 18px var(--button-fontfamily);
  --button-borderradius: 20px;
  --rtd-spacing-widget-before: 25px; /* instead of 35px */
}

/*.page--p3dtool*/
.effecttool__group .widget-button {
  --rtd-spacing-widget-before: 10px;
}
.effecttool__group .widget-button > .defaultbutton::before {
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  font-size: 13px;
  margin-right: 8px;
}
@media (max-width: 500px) {
  .effecttool__group .widget-button > a {
    display: flex;
  }
}

div.effecttool__group { /* Firefox */
  /* Chrome */
}
div.effecttool__group details summary {
  cursor: pointer;
}
div.effecttool__group details summary {
  list-style-type: none;
}
div.effecttool__group details summary::-webkit-details-marker {
  display: none;
}
div.effecttool__group details summary::marker {
  display: none;
}

.effecttool__groups {
  counter-reset: count;
}

/*
details.effecttool__group
{
  > summary { cursor: pointer; }
  > summary { list-style-type: none; } / * Firefox * /
  > summary::-webkit-details-marker { display: none; } / * Chrome * /
  > summary::marker { display: none; } // ??? is this for future marker support?
}
*/
/*
// Line at the bottom of a group
div.effecttool__group::after // only if the each effectgroup is it's own accordeon (so not if the effecttool__group is <details> meaning it's an item in an accordeon)
{
  display: block;
  content: "";

  margin-top: 24px;

  @include layout--default;
  width: -webkit-fill-available; // Chrome 22+, Safari 6.1+
  width: -moz-available; // Firefox 3+

  //border-bottom: 1px solid var(--widget-accordion-line-color);
  border-bottom: 1px solid var(--color-green);
}
*/
.effecttool__effect summary > h2,
.effecttool__group summary > .effecttool__group__title,
.effecttool__group__title {
  display: flex;
  align-items: center;
  color: var(--color-green);
  font: var(--p3dtool-results-heading-font);
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

div.effecttool__group .effecttool__group__title {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--color-grey-border);
}

.effecttool__effect summary > h2 {
  justify-content: space-between;
}

.effecttool__group summary > h2:hover,
.effecttool__group summary > .effecttool__group__title:hover {
  background-color: #F5FFF5;
}

.effecttool__group__header > .effecttool__group__title::before {
  flex: none;
  content: counter(count);
  counter-increment: count;
  align-self: baseline;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: var(--color-green);
  color: #FFFFFF;
  border-radius: 0 0 2px 2px;
  margin-right: 10px;
}

.effecttool__group__header > .effecttool__group__title::before {
  border-radius: 50%;
  font-size: 19px;
}

.effecttool__groups summary > h2 > .h2text {
  flex: 1 0 0px;
}

.effecttool__groups summary .effecttool__group__title::after {
  color: var(--color-purple);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-down);
  font-size: 18px;
  margin-left: auto;
  padding-left: 20px;
  margin-right: 20px;
}

@media (max-width: 750px) {
  .effecttool__groups summary .effecttool__group__title::after {
    margin-right: 5px;
  }
}
.effecttool__groups details[open] summary > h2::after,
details.effecttool__group[open] summary > .effecttool__group__title::after {
  content: var(--fa-chevron-up);
}

.effecttool__groups details > *:first-child + * {
  margin-top: 10px;
}

.effecttool__effect__description > *:first-child {
  margin-top: 0;
}

.effecttool__effect__description > *:last-child {
  margin-bottom: 0;
}