html {
  --buttonbar-item-border: 2px solid rgb(--color-green);
}

/*
2017 ?

2023
- focus styling
  - replaced border-radius + overflow: hidden; width radius on first and last item
- divider done using ::before on checked item following a checked item (unless focus active)
- using :focus-visible instead of :focus
- dropped -webkit prefix for flex
- use of CSS variables



.. was comp-togglebuttonbar

- We use min-height on items because the buttonbar may be multiple rows
  (with flex-wrap or grid layout)

*/
.togglebuttonbar__items {
  width: 100%;
  display: flex;
  column-gap: var(--formcontrol-buttonbar-item-spacing);
  user-select: none;
}

.togglebuttonbar__items input[type=checkbox],
.togglebuttonbar__items input[type=radio] {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute;
  width: 0;
  height: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  /* Work around "ARC Toolkit"'s "Insufficient normal text contrast" error.
     The contrast actually isn't relevant, due it not being visible.
     (the label is the visual/interactable part of the checkbox)
  */
  background: #FFFFFF;
  color: #000000;
}

.togglebuttonbar__items input + label {
  border: var(--formcontrol-buttonbar-item-border);
  font: var(--formcontrol-buttonbar-item-font);
}

.togglebuttonbar__items label {
  background: var(--formcontrol-buttonbar-item-background);
  color: var(--formcontrol-buttonbar-item-color);
  border-radius: var(--formcontrol-buttonbar-item-borderradius);
  min-height: var(--formcontrol-buttonbar-item-height);
  flex: 1 1 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.togglebuttonbar__items input + label:hover {
  background: var(--formcontrol-buttonbar-item-hover-background);
}

.togglebuttonbar__items input:checked + label {
  background: var(--formcontrol-buttonbar-item-selected-background);
  color: var(--formcontrol-buttonbar-item-selected-color);
}

.togglebuttonbar__items input + label {
  position: relative;
}

.togglebuttonbar__items input + label::before {
  position: absolute;
  left: 15px;
  content: "\f00c";
  font: var(--fontawesome-solid);
  font-size: 15px;
  /*
  color: #FFFFFF;
  opacity: 0;
  transition: opacity 0.25s;
  */
  color: rgba(0, 0, 0, 0);
  transition: color var(--button-transition-duration);
}

.togglebuttonbar__items input + label:hover::before {
  color: rgba(0, 0, 0, 0.15);
}

.togglebuttonbar__items input:checked + label::before {
  color: inherit;
}

.filterpanel--togglebuttonbar.filterpanel--singleselect label {
  border-radius: 0;
}
.filterpanel--togglebuttonbar.filterpanel--singleselect label ~ label {
  border-left: 0;
}
.filterpanel--togglebuttonbar.filterpanel--singleselect .togglebuttonbar__items > input:first-child + label {
  border-radius: var(--formcontrol-buttonbar-item-borderradius) 0 0 var(--formcontrol-buttonbar-item-borderradius);
}
.filterpanel--togglebuttonbar.filterpanel--singleselect .togglebuttonbar__items > label:last-child {
  border-radius: 0 var(--formcontrol-buttonbar-item-borderradius) var(--formcontrol-buttonbar-item-borderradius) 0;
}

.togglebuttonbar__items input:focus-visible + label {
  z-index: 2;
  outline: 3px solid #AAA;
  outline-offset: var(--actionbutton-outline-offset);
}

/*
Add the divider as element outside of the label,
so it doesn't get included
*/
.comp-togglebutton ~ .comp-togglebutton::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0;
  bottom: 0;
  border-left: 1px solid var(--formcontrol-buttonbar-divider-color);
}

input:checked + .comp-togglebutton + input:checked:not(:focus-visible) + .comp-togglebutton.selected::before {
  border-left: 1px solid var(--formcontrol-buttonbar-divider-selecteditems-color);
}