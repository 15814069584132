.checkboxlist__option,
.radiolist__option {
  display: flex;
  align-items: baseline;
  display: grid;
  grid-template-columns: min-content 1fr min-content;
  color: var(--rtd-text-color);
  font: var(--tagpanel-option-font);
}

.radiolist__option label,
.checkboxlist__option label {
  cursor: pointer;
}

.checkboxlist__option > input + label,
.radiolist__option > input + label {
  padding-left: var(--tagpanel-checkradio-spacing);
  padding-top: var(--tagpanel-option-pad-top);
  padding-bottom: var(--tagpanel-option-pad-bottom);
}

.checkboxlist__option + .checkboxlist__option,
.radiolist__option + .radiolist__option {
  margin-top: var(--tagpanel-option-spacing);
}

.optionlist__item .infobutton {
  flex: none;
  margin-left: 10px;
}

.badge,
.multiselect__summaryitem__badge {
  flex: none;
  display: flex;
  align-items: center;
  margin-left: auto;
  background-color: #E6E6E6;
  padding: 0 10px;
  height: 20px;
  border-radius: 10px;
  text-align: right;
  font: 12px var(--rtd-text-fontfamily);
  color: #333333;
}

input:checked ~ .badge {
  background-color: var(--color-theme);
  color: #FFFFFF;
}