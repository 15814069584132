/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/*
    Trigger - Design name
- >= 1200px - Desktop -> show 5 items side-by-side
- >=  960px - Desktop -> show 5 items side-by-side, allow flex items to shrink
- >=  768px - Tablet  -> scroll
-  <  768px - Mobile  -> stacked
*/
a.themecarrousel__item {
  text-decoration: none;
}

.themecarrousel__title {
  margin-bottom: 15px;
}

.themecarrousel__showall {
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 639px) {
  .widget--themecarrousel {
    background-color: var(--color-panel-background);
    padding-top: 25px;
    padding-bottom: 25px;
    width: 100%;
  }
  .themecarrousel__title,
  .themecarrousel__contentwrapper {
    margin-left: var(--sidepadding);
    margin-right: var(--sidepadding);
  }
  .themecarrousel__title {
    color: var(--color-purple);
    font: bold 24px/26px "TypoPRO Dosis", "Dosis", Arial;
  }
  .themecarrousel__contentwrapper {
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
    padding-top: 15px;
    padding-bottom: 8px;
  }
  .themecarrousel__item:hover,
  .themecarrousel__showall:hover {
    background-color: #F1F1F1;
  }
  .themecarrousel__item,
  .themecarrousel__showall {
    display: flex;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .themecarrousel__item__iconarea,
  .themecarrousel__showall::before {
    width: 36px;
    margin-right: 9px;
  }
  .themecarrousel__item__icon {
    display: block;
    width: 36px;
    height: 36px;
    object-fit: contain;
    flex: none;
  }
  .themecarrousel__showall::before {
    line-height: 36px !important;
    width: 36px;
    height: 36px;
    flex: none;
  }
  .themecarrousel__item__title {
    flex: 1 0 auto;
    color: var(--color-purple);
    font: bold 20px/24px "TypoPRO Dosis", "Dosis", Arial;
  }
  .widget--themecarrousel hr {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 6px;
    margin-bottom: 6px;
    border: 0;
    height: 1px;
    background-color: #D7D7D7;
  }
  .themecarrousel__showall {
    color: var(--color-purple);
    font: bold 20px/24px "TypoPRO Dosis", "Dosis", Arial;
    padding-left: 12px;
    padding-right: 12px;
  }
  .themecarrousel__showall::before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 400;
    content: "\f054";
    font-size: 20px;
    color: var(--color-green);
    text-align: center;
  }
}
@media (min-width: 640px) {
  .widget--themecarrousel {
    width: 100%;
    background-color: #F5F5F5;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .themecarrousel-scrollbar-container {
    width: 100%;
    max-width: min(100% - var(--sidepadding) * 2, var(--pagegrid-contentarea-xl-width));
    margin-left: auto;
    margin-right: auto;
  }
  .themecarrousel__title {
    color: var(--color-purple);
    font: bold 36px/40px "TypoPRO Dosis", "Dosis", Arial;
    text-align: center;
  }
  .themecarrousel__items {
    display: flex;
    /*
    // Center items disabled for now, because did shrink-to-fit
    // making the "Readspeaker" button not align with the 5th item
    // anymore in desktop layout.

    max-width: max-content;
    margin-left: auto;
    margin-right: auto;
    */
  }
  .themecarrousel__item {
    flex: 0 0 218px;
    min-height: 218px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.19);
  }
  .themecarrousel__item:focus,
  .themecarrousel__item:hover {
    border-color: #858585;
    background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
  }
  .themecarrousel__item + .themecarrousel__item {
    margin-left: 21px;
  }
  .themecarrousel__item__iconarea {
    flex: none;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .themecarrousel__item__icon {
    display: block;
    width: 100px;
    height: 100px;
  }
  .themecarrousel__item__title {
    flex: 1 0 auto;
    color: var(--color-purple);
    font: bold 22px/24px "TypoPRO Dosis", "Dosis", Arial;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
  }
  .widget--themecarrousel hr {
    display: none;
  }
  .themecarrousel__showall {
    margin-top: 39px;
    display: flex;
    align-items: center;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    height: 60px;
    border: 2px solid var(--color-purple);
    border-radius: 8px;
    background-color: #FFFFFF;
    color: var(--color-purple);
    font: bold 22px/24px "TypoPRO Dosis", "Dosis", Arial;
    padding-left: 40px;
    padding-right: 40px;
  }
  .themecarrousel__showall:hover {
    background-color: var(--color-purple);
    color: #FFFFFF;
  }
}
@media (min-width: 640px) and (max-width: 999px) {
  .themecarrousel__title {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
    max-width: 1175px;
  }
  .themecarrousel__items {
    padding-left: 15px;
  }
  .themecarrousel__items::after {
    content: "";
    flex: none;
    width: 15px;
  }
}
@media (min-width: 1000px) {
  .themecarrousel__title,
  .themecarrousel__itemswrapper {
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-xl-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
  }
  .themecarrousel__item {
    flex: 0 1 218px;
    min-width: 190px;
  }
}
.themecarrousel-scrollbar-container {
  margin-top: 25px;
  margin-bottom: 22px;
}

/*
.themecarrousel__scrollbar
{
  height: 1px;
  width: 696px;
  border: 1px solid #BEBEBE;
}

.themecarrousel__scrollbar__knob
{
  height: 7px;
  width: 374px;
  border-radius: 4px;
  background-color: #BEA3C5;
}
*/