.filtersdialog {
  --sidebar-closebutton-color: #333333;
  --sidebar-closebutton-size: 22px;
}
.filtersdialog .dialog__header__title {
  --rtd-heading-color: var(--color-green);
}
.filtersdialog .site-dialog__footer {
  margin-top: 30px;
}
.filtersdialog .dialog__content {
  height: -webkit-fill-available;
  grid-template-rows: 1fr min-content;
}

.multiselect__panel__ok {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color var(--button-transition-duration);
  cursor: pointer;
  background-color: #13A538;
  color: #FFFFFF;
  border: 0;
  outline-offset: var(--actionbutton-outline-offset);
}
.multiselect__panel__ok:hover {
  background: var(--color-green);
  color: #FFFFFF;
  text-decoration: none;
}
.multiselect__panel__ok .wh-form__button--disabled {
  background: var(--button_disabled_backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.multiselect__panel__clear {
  text-underline-offset: 3px;
  padding: 3px 8px;
}

.multiselect__summary {
  margin-top: 0;
  background: rgba(0, 0, 0, 0.04);
  padding: 8px var(--textcontrols_padright) 15px var(--textcontrols_padleft);
  border-radius: 0 0 var(--custom-radius) var(--custom-radius);
}

.multiselect__summaryitem__remove {
  margin-left: -7px;
  padding: 5px;
}

.multiselect__summaryitem__remove:hover + .multiselect__summaryitem__title {
  color: var(--color-green-small);
}

.page--huishoudboekjes {
  --filteredoverview-teaserbar-background: var(--color-green);
}
.page--huishoudboekjes .pageheader--theme {
  background: var(--pageheader-filterbar-background);
}
.page--huishoudboekjes.comparisontable-active .pageheader--theme {
  background: var(--comparisontable-header-background);
}
.page--huishoudboekjes .pageheader__text {
  margin-left: 0;
  width: 100%;
  max-width: none;
}

.huishoudboekjes__filterbar,
.huishoudboekjes__additionalfilters,
.huishoudboekjes__mobile-filters {
  --custom-radius: 8px;
  --button-borderradius: var(--custom-radius);
  --spacing-vertical: 15px;
  --tagpanel-heading-color: var(--color-green);
  --tagpanel-heading-font: 500 20px var(--rtd-heading-fontfamily);
  --filterpage-filtergroup-padleft: 18px;
  --filterpage-filtergroup-padright: 20px;
  --filterpage-filtergroup-padding: 0;
  --filterpage-filtergroup-spacing: 40px;
  --formcontrol-checkradio-size: 24px; /* instead of 25px */
  --formcontrol-buttonbar-border-radius: 5px;
  /*
    --formcontrol-buttonbar-divider-color:               #CCCCCC;
    --formcontrol-buttonbar-divider-selecteditems-color: #FFFFFF;

    --formcontrol-buttonbar-item-background:          #FFFFFF;
    --formcontrol-buttonbar-item-color:               #000000;

  */
  --formcontrol-buttonbar-item-font: 500 16px var(--rtd-heading-fontfamily);
  --formcontrol-buttonbar-item-height: 42px;
  --formcontrol-buttonbar-item-border: 2px solid var(--color-green);
  --formcontrol-buttonbar-item-spacing: 10px;
  --formcontrol-buttonbar-item-borderradius: var(--custom-radius);
  --formcontrol-buttonbar-item-background: #FFFFFF;
  --formcontrol-buttonbar-item-hover-background: var(--color-greentint);
  --formcontrol-buttonbar-item-selected-background: var(--color-green);
  --formcontrol-buttonbar-item-selected-color: #FFFFFF;
  --formcontrols-height: 42px;
}
.huishoudboekjes__filterbar input[type=checkbox],
.huishoudboekjes__additionalfilters input[type=checkbox],
.huishoudboekjes__mobile-filters input[type=checkbox] {
  margin-right: 0;
}
.huishoudboekjes__filterbar .togglebuttonbar__items,
.huishoudboekjes__additionalfilters .togglebuttonbar__items,
.huishoudboekjes__mobile-filters .togglebuttonbar__items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: var(--spacing-vertical);
  column-gap: 15px;
}
.huishoudboekjes__filterbar .filterpanel--togglebuttonbar.filterpanel--singleselect .togglebuttonbar__items,
.huishoudboekjes__additionalfilters .filterpanel--togglebuttonbar.filterpanel--singleselect .togglebuttonbar__items,
.huishoudboekjes__mobile-filters .filterpanel--togglebuttonbar.filterpanel--singleselect .togglebuttonbar__items {
  display: flex;
  column-gap: 0;
  row-gap: 0;
}

.huishoudboekjes__filterbar .header-filterbar__togglefilters {
  width: 100%;
}
.huishoudboekjes__filterbar .header-filterbar__togglefilters {
  margin-top: var(--spacing-vertical);
  height: var(--formcontrols-height);
}

.huishoudboekjes__additionalfilters {
  --filterpage-filtergroup-padding: 0 0 15px 0;
  display: flex;
  column-gap: 20px;
}
.huishoudboekjes__additionalfilters > * {
  flex: 1 0 0px;
}
.huishoudboekjes__additionalfilters__additionalfeatures {
  --tagpanel-option-pad-top: 3px;
  --tagpanel-option-pad-top: 3px;
  --formcontrol-checkradio-size: 22px; /* 24px, instead of 25px */
  margin-top: 20px;
}
.huishoudboekjes__additionalfilters__additionalfeatures .filterpanel__title {
  font: 500 17px var(--rtd-heading-fontfamily);
}
@media (max-width: 767px) {
  .huishoudboekjes__filterbar {
    width: 100%;
  }
  .huishoudboekjes__filterbar .header-filterbar__togglefilters {
    --button-color: var(--color-green);
    --button-color-hover: var(--color-green);
    border-color: #DADADA;
    border-width: 2px;
    font-size: 18px;
    justify-content: center;
  }
  .huishoudboekjes__filterbar .header-filterbar__togglefilters:hover {
    border-color: transparent;
  }
  .huishoudboekjes__filterbar__col2 {
    margin-top: calc(var(--spacing-vertical) + 5px);
  }
  .filterpanel--group__column + .filterpanel--group__column {
    margin-top: 20px;
  }
  .filterpanel--group__column > * {
    margin-top: 20px;
  }
  .filterpanel--group__column > .filterpanel--radiolist {
    margin-top: 0;
  }
}
@media (min-width: 768px) {
  .huishoudboekjes__filterbar {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  }
  .huishoudboekjes__filterbar .header-filterbar__togglefilters {
    grid-column: 2;
    grid-row: 3;
    --button-color: var(--color-purple);
    --button-color-hover: var(--color-purple);
    border-width: 2px;
  }
  .filterpanel--hhmogelijkheden > .filterpanel__title {
    padding-bottom: 10px;
    border-bottom: 1px solid #BDBDBD;
    margin-bottom: 15px;
  }
  .huishoudboekjes__additionalfilters .filterpanel--group .filterpanel--multiselect .filterpanel__title {
    padding-bottom: 5px;
    border: 0;
    margin: 0;
    font-size: 17px;
  }
  .filterpanel--group__columns {
    display: flex;
    column-gap: 20px;
  }
  .filterpanel--group__column {
    flex: 1 0 0px;
  }
  .filterpanel--group__column:first-child {
    padding-right: 10px;
  }
  .filterpanel--group__column + .filterpanel--group__column {
    flex: 1 0 10px;
  }
  .huishoudboekjes__additionalfilters .filterpanel + .filterpanel {
    margin-top: 15px;
  }
  .filterpanel--group__column > .filterpanel--multiselect {
    margin-top: -4px;
  }
}
.huishoudboekjes__filterbar h2 {
  grid-column: 1/-1;
}
.huishoudboekjes__filterbar [data-categoryid=betaling] {
  grid-column: 1;
  grid-row: 2;
}
.huishoudboekjes__filterbar [data-categoryid=gebruiktags] {
  margin-top: var(--spacing-vertical);
  grid-column: 1;
  grid-row: 3;
}
.huishoudboekjes__filterbar .huishoudboekjes__filterbar__col2 {
  grid-column: 2;
  grid-row: 2/span 2;
  align-self: start;
}

.huishoudboekjes__filterbar__heading {
  display: flex;
  grid-column: 1/-1;
  align-items: baseline;
  align-items: top;
}

.huishoudboekjes__mobile-filters .filterpanel--pulldown, .huishoudboekjes__mobile-filters .filterpanel--togglebuttonbar,
.huishoudboekjes__mobile-filters .filterpanel--multiselect {
  margin-left: 15px;
  margin-right: 15px;
}
.huishoudboekjes__mobile-filters .filterpanel--radiolist {
  padding-left: 15px;
  padding-right: 15px;
}
.huishoudboekjes__mobile-filters .filterpanel + .filterpanel {
  margin-top: 20px;
}
.huishoudboekjes__mobile-filters .filterpanel[data-categoryid=beschikbaarvoortags] {
  margin-top: 28px;
}
.huishoudboekjes__mobile-filters .filterpanel[data-categoryid=mogelijkhedentags] .filterpanel__title {
  margin-bottom: 15px;
}

.huishoudboekjes__filterbar h2,
.huishoudboekjes__mobile-filters h2,
.huishoudboekjes__additionalfilters h2 {
  color: var(--color-green);
  font-weight: 500;
  margin-bottom: 10px;
}
.huishoudboekjes__filterbar .filterpanel--pulldown,
.huishoudboekjes__mobile-filters .filterpanel--pulldown,
.huishoudboekjes__additionalfilters .filterpanel--pulldown {
  --formcontrols-height: 34px; /* 42px - 2 * 2px(border) - 2 * 2px(padding vertical) */
  --mypulldown-border-width: 2px;
  border: var(--mypulldown-border-width) solid var(--color-green);
  border-radius: var(--custom-radius);
  background: #FFFFFF;
  padding: 2px 0;
  position: relative;
}
.huishoudboekjes__filterbar .filterpanel--pulldown:has(select:focus-visible),
.huishoudboekjes__mobile-filters .filterpanel--pulldown:has(select:focus-visible),
.huishoudboekjes__additionalfilters .filterpanel--pulldown:has(select:focus-visible) {
  outline: 2px solid var(--color-green);
  outline-offset: 0;
  --mypulldown-border-width: 2px;
}
.huishoudboekjes__filterbar .filterpanel--pulldown select,
.huishoudboekjes__mobile-filters .filterpanel--pulldown select,
.huishoudboekjes__additionalfilters .filterpanel--pulldown select {
  border: 0 !important;
  padding-top: 4px;
  width: 100%;
  cursor: pointer;
}
.huishoudboekjes__filterbar .filterpanel--pulldown select:focus,
.huishoudboekjes__mobile-filters .filterpanel--pulldown select:focus,
.huishoudboekjes__additionalfilters .filterpanel--pulldown select:focus {
  outline: 0;
  box-shadow: none !important;
}
.huishoudboekjes__filterbar .filterpanel--pulldown .filterpanel__title,
.huishoudboekjes__mobile-filters .filterpanel--pulldown .filterpanel__title,
.huishoudboekjes__additionalfilters .filterpanel--pulldown .filterpanel__title {
  position: absolute;
  left: var(--textcontrols_padleft);
  margin: 0;
  top: -4px;
  padding: 2px 0;
  color: var(--color-green);
  font: bold 14px Arial;
  display: grid;
}
.huishoudboekjes__filterbar .filterpanel--pulldown .filterpanel__title::before,
.huishoudboekjes__mobile-filters .filterpanel--pulldown .filterpanel__title::before,
.huishoudboekjes__additionalfilters .filterpanel--pulldown .filterpanel__title::before {
  content: "";
  margin-top: -2px;
  height: 4px;
  grid-column: 1;
  grid-row: 1;
  background: var(--pageheader-filterbar-background);
  z-index: 1;
}
.huishoudboekjes__filterbar .filterpanel--pulldown .filterpanel__title span,
.huishoudboekjes__mobile-filters .filterpanel--pulldown .filterpanel__title span,
.huishoudboekjes__additionalfilters .filterpanel--pulldown .filterpanel__title span {
  grid-column: 1;
  grid-row: 1;
  z-index: 2;
  padding: 0 5px;
  display: block;
  transform: translateY(-50%);
}
.huishoudboekjes__filterbar .header-filterbar__togglefilters__icon,
.huishoudboekjes__mobile-filters .header-filterbar__togglefilters__icon,
.huishoudboekjes__additionalfilters .header-filterbar__togglefilters__icon {
  order: -1;
  margin: 0 10px 0 0 !important;
}