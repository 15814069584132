/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/** @short The active menu selection will look like a tab from which the dropdown extends.
 *  @long Use this mixin on the .spc-menubar container
 *  !! use this AFTER the @include menu-panel-pulldown.
 */
/** @short Dropdown appears slightly below the menubar item with an arrow pointing to the menu item it belongs to
 * (FIXME: test)
*/
/** @short Dropdown appears slightly below the menubar item with the top of the dropdown having kind of a half arrow pointing to the menu item the dropdown belongs to
 * (FIXME: test)
*/
html.site--wig2024 {
  --menubar-level1-font: bold 19px/26px "TypoPRO Dosis Menu", "Dosis", Arial;
  --menubar-level2-font: bold 18px/20px "TypoPRO Dosis Menu", "Dosis", Arial;
}

html.site--wig2024.site--p3d {
  --menubar-level1-font: bold 18px/26px "TypoPRO Dosis Menu", "Dosis", Arial;
  --menubar-level1-padding-left: 11px;
  --menubar-level1-padding-right: 11px;
}

@media (min-width: 880px) {
  html.site--wig2024 .siteheader__menu.spc-menubar {
    align-self: center;
    --menubar-bar-toggler-margin: 10px;
  }
  html.site--wig2024 .siteheader__menu.spc-menubar .spc-menubar__pulldown > ul {
    border-radius: 0 var(--menubar-pulldown-border-radius) var(--menubar-pulldown-border-radius) var(--menubar-pulldown-border-radius);
  }
  html.site--wig2024 .siteheader__menu.spc-menubar > ul > li > .spc-menubar__item {
    border-radius: var(--menubar-bar-item-border-radius);
  }
  html.site--wig2024 .siteheader__menu.spc-menubar > ul > li.spc-menubar__item--hassubitems > .spc-menubar__item {
    border-radius: 5px;
  }
  html.site--wig2024 .siteheader__menu.spc-menubar > ul > li > .spc-menubar__item:focus-within, html.site--wig2024 .siteheader__menu.spc-menubar > ul > li > .spc-menubar__item:hover {
    background: var(--menubar-bar-item-hover-background);
  }
  html.site--wig2024 .siteheader__menu.spc-menubar > ul > li.spc-menubar__item--expand > .spc-menubar__item {
    color: var(--menubar-level2-color);
    background: var(--menubar-pulldown-bgcolor);
    border-radius: 5px 5px 0 0;
    --menubar-bar-item-hover-color: var(--menubar-level2-color);
  }
  html.site--wig2024 .siteheader__menu.spc-menubar > ul > li.spc-menubar__item--hassubitems > .spc-menubar__item:hover {
    box-shadow: var(--menubar-pulldown-box-shadow);
  }
  html.site--wig2024 .siteheader__menutoggle {
    display: none;
  }
  html.site--wig2024 .siteheader__menuwrappermobile {
    display: contents;
  }
  html.site--wig2024 .spc-menubar__item {
    position: relative;
  }
  html.site--wig2024 .spc-menubar__item__title {
    min-height: 35px;
  }
  html.site--wig2024.site--p3d .spc-menubar__spacer {
    display: none;
  }
  html.site--wig2024 .spc-menubar__spacer {
    display: flex;
    align-items: center; /* vertical */
    justify-content: center; /* horizontal */
    flex: 1 1 65px;
  }
  html.site--wig2024 .spc-menubar__spacer::before {
    content: "";
    border-left: 1px solid #64C47C;
    height: 33px;
  }
  html.site--wig2024 ul.spc-menubar--toplevel > li:first-child {
    margin-left: calc(-1 * var(--menubar-level1-padding-left));
  }
  html.site--wig2024 .spc-menubar__levelpanel[data-menulevel="2"] {
    position: absolute;
    animation: var(--menubar-pulldown-anim-close);
    animation-fill-mode: forwards;
    /*
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.15s;
    */
  }
  html.site--wig2024 .spc-menubar__levelpanel[data-menulevel="2"] > ul {
    position: relative;
    width: max-content;
    min-width: var(--menubar-pulldown-minwidth);
    max-width: var(--menubar-pulldown-maxwidth);
    padding: var(--menubar-pulldown-padding);
    background-color: var(--menubar-pulldown-bgcolor);
    border: var(--menubar-pulldown-border);
    border-radius: var(--menubar-pulldown-border-radius);
    box-shadow: var(--menubar-pulldown-box-shadow);
  }
  html.site--wig2024 .spc-menubar__levelpanel[data-menulevel="2"] > ul::before {
    border-left-color: var(--menubar-pulldown-bgcolor);
  }
  html.site--wig2024 .spc-menubar__level2 .spc-menubar__item__title {
    display: flex;
    align-items: center;
  }
  html.site--wig2024 .spc-menubar__level2 .spc-menubar__item__title::before {
    flex: none;
    display: block;
    content: var(--fa-chevron-right);
    font: var(--fontawesome-solid);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-size: 11px;
    margin-right: 8px;
  }
}
@media (max-width: 879px) {
  html.site--wig2024 {
    --menubar-pulldown-border-radius: 0;
    --menubar-bar-toggler-shifty: 0;
    --menubar-level1-padding-right: 15px;
    --menubar-level1-openbyhover: false;
    --menubar-level2-color: #FFFFFF;
    --menubar-level2-hover-background: rgba(0,0,0, 0.5);
    --menubar-level2-font: 17px/20px var(--rtd-heading-fontfamily);
    --menubar-pulldown-itemhover-bgcolor: rgba(0,0,0,0.2);
    --menubar-bar-item-hover-background: rgba(0,0,0,0.2);
    --menubar-bar-item-hover-color: #FFFFFF;
    /*
        .siteheader-menubar
        {
          transition: background-color 0.10s;
        }
    */
    /*
        ul.spc-menubar--toplevel.spc-menubar--toplevel > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__toggle:hover::after
        {
          background-color: rgba(0,0,0,0.3);
          // outline: 1px solid #000;
        }
    */
  }
  html.site--wig2024 .siteheader-menubar:not(:has(.siteheader__menuwrappermobile[hidden])) {
    background-color: var(--color-green-background-highcontrast);
  }
  html.site--wig2024 .sidebar-themes .sidebar__menu--level2 {
    --sidebar-menu-level2-padding: 7px 10px 7px 45px;
  }
  html.site--wig2024 .sidebar-themes .sidebar__menu--level2 .sidemainmenu__item__toggle {
    left: 22px;
  }
  html.site--wig2024 .siteheader-menubar__content {
    padding-left: 0;
    padding-right: 0;
    padding-left: var(--menubar-level1-padding-left);
    padding-right: var(--menubar-level1-padding-right);
  }
  html.site--wig2024 .siteheader__menutoggle {
    background: transparent;
    border: 0;
    margin: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: var(--menubar-bar-item-color);
    font: var(--menubar-level1-font);
  }
  html.site--wig2024 .siteheader__menutoggle::after {
    margin-left: 10px;
    content: var(--fa-chevron-down);
    font: var(--fontawesome-regular);
  }
  html.site--wig2024 .siteheader__menutoggle[aria-expanded=true]::after {
    content: var(--fa-chevron-up);
  }
  html.site--wig2024 .siteheader__menu {
    position: absolute;
    left: 0;
    top: 100%;
  }
  html.site--wig2024.siteheader-mobile-menu-opened body {
    overflow: hidden;
  }
  html.site--wig2024.siteheader-mobile-menu-opened .siteheader-menubar {
    position: fixed;
    top: 0;
    width: 100%;
    transform: none;
  }
  html.site--wig2024 .spc-menubar--toplevel {
    flex-direction: column;
    position: absolute;
    top: 0;
    height: calc(100dvh - var(--header-menubar-height));
    background: var(--color-green-background-highcontrast);
    width: 100vw;
    width: 100dvw;
    overflow: auto;
    overscroll-behavior: contain;
  }
  html.site--wig2024 .spc-menubar__spacer {
    display: none !important;
  }
  html.site--wig2024 .spc-menubar__item__title .icon {
    width: 24px;
  }
  html.site--wig2024 ul.spc-menubar-branch--horizontal > li > .spc-menubar__item {
    position: relative;
  }
  html.site--wig2024 ul.spc-menubar-branch--horizontal > li > .spc-menubar__item::before {
    position: absolute;
    top: 0;
    left: 15px;
    right: 15px;
    display: block;
    content: "";
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
  html.site--wig2024 ul.spc-menubar-branch--horizontal > li > .spc-menubar__item:hover::before,
  html.site--wig2024 ul.spc-menubar-branch--horizontal > li + li > .spc-menubar__item:hover::before {
    left: 0;
    right: 0;
  }
  html.site--wig2024 .spc-menubar-branch--horizontal > li > .spc-menubar__item {
    margin-top: 0 !important;
    min-height: 32px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
  html.site--wig2024 .spc-menubar__item__title {
    width: 100%;
  }
  html.site--wig2024 .spc-menubar__item__label {
    flex: 1 1 auto;
  }
  html.site--wig2024 ul.spc-menubar--toplevel.spc-menubar--toplevel > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__toggle::after {
    margin-left: auto;
    width: 32px;
    height: 32px;
    background: rgba(255, 255, 255, 0.21);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.2s;
    /*
    margin-left: auto;
    width: 32px;
    height: 32px;
    background: rgba(255,255,255, 0.21);
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: background 0.2s;
    */
  }
  html.site--wig2024 ul.spc-menubar--toplevel.spc-menubar--toplevel > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__toggle::after:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
  html.site--wig2024 ul.spc-menubar__level2 li {
    padding-left: 15px;
  }
  html.site--wig2024 .spc-menubar__level2 .spc-menubar__item__title {
    display: flex;
    align-items: center;
  }
  html.site--wig2024 .spc-menubar__level2 .spc-menubar__item__title::before {
    flex: none;
    display: block;
    content: var(--fa-chevron-right);
    font: var(--fontawesome-regular);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    margin-right: 12px;
  }
}