/* Global page, HTML5 setup, style reset */
.effecttool_root p, .effecttool_root h1,
.effecttool_root h2,
.effecttool_root h3,
.effecttool_root h4,
.effecttool_root h5,
.effecttool_root h6,
.effecttool_root form {
  padding: 0;
  margin: 0;
}
.effecttool_root .wh-rtd p.align_right {
  text-align: right;
}

/*
#formcontainer
{
  display:flex;
  flex-direction:column;
}
*/
.effecttool__page:not(.effecttool__page--visible) {
  display: none;
}
html.dompack--debug-show_all_pages .effecttool__page:not(.effecttool__page--visible) {
  display: block;
}

.effecttool__results.effecttool__results--nomeasures .effecttool__results__header,
.effecttool__results.effecttool__results--nomeasures .effecttool__groups,
.effecttool__results.effecttool__results--nomeasures .effecttool__resultbuttons,
.effecttool__results:not(.effecttool__results--nomeasures) .effecttool__results__noresults {
  display: none;
}

.effecttool--isopening .effecttool__progressholder,
.effecttool--isresults .effecttool__progressholder {
  display: none;
}

.effecttool__progressholder__text {
  display: none;
}

.effecttool__progressholder__bar {
  position: relative;
  margin: 20px 0 2px;
  background-color: #84c051;
  width: 100%;
  height: 1px;
  max-width: 350px;
}

.effecttool__progressholder__bar__inner {
  position: absolute;
  top: -2px;
  bottom: -2px;
  background-color: #84c051;
  border-radius: 5px;
  width: 0%;
  transition: width 300ms;
}