/*
@mixin wh-form-hidenativecontrol
{
  // display: none or visibility: hidden; will prevent focus!!
  position: absolute !important; // to not mess with flex containers
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  opacity: 0;

  margin: 0;
}

@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
.effecttool__root {
  --tool-question-sidepadding: 27px;
  --toolbutton-icon-margin-prev: 15px;
  --toolbutton-icon-margin-next: 25px;
  --tool-introheading-font-mobile: 24px/33px var(--rtd-heading-fontfamily);
  --tool-introheading2-font-mobile: bold 24px/33px var(--rtd-heading-fontfamily);
  --tool-introtext-font: 18px/28px var(--rtd-heading-fontfamily);
  --tool-questionheading-font: bold 26px/33px var(--rtd-heading-fontfamily);
  --tool-questiontext-font: normal 16px/24px Arial, Sans-Serif;
  --tool-questiontext-color: #333333;
  --tool-optionlabel-color: #333333;
  --tool-optionlabel-spacing: 15px;
  --tool-reminder-label-color: #333333;
  --tool-reminder-label-font: var(--tool-questiontext-font);
  --tool-effectgroup-title-color: var(--color-green);
  --tool-effectgroup-title-font: var(--rtd-h2-font);
}
@media (max-width: 750px) {
  .effecttool__root {
    --tool-question-sidepadding: 18px;
    --toolbutton-icon-margin-prev: 13px;
    --toolbutton-icon-margin-next: 20px;
    --tool-introtext-font: 16px/24px var(--rtd-heading-fontfamily);
    --tool-questionheading-font: bold 21px/24px var(--rtd-heading-fontfamily);
    --tool-questiontext-font: normal 15px/23px Arial, Sans-Serif;
  }
}

.effecttool__root {
  --widget-download-title-font: 17px/20px var(--fontfamily-heading);
  --widget-download-icon-fontsize: 20px;
  --widget-icon-space: 20px;
}
.effecttool__root .widget-downloadsandlinks .widgetdl__item__icon.fa-chevron-right {
  font: var(--fontawesome-regular);
  font-size: 17px;
}
.effecttool__root .widget-downloadsandlinks .widgetdl__item__icon.fa-external-link-alt {
  font-size: 14px;
}

.effecttool__root {
  /*
          #effecttool__pages
            .effecttool__question.effecttool__page--visible.effecttool__page.page.question
              .contents
                .effecttool__questiontext.questiontitle
                .effecttool__questioncontainer
                  .effecttool__radiolist.answers.radiolist
                    .effecttool__radiolist__answer.answer
                .effecttool__navbuttons buttons
                  .effecttool__navbuttons__spacer
                  .effecttool__button effecttool__navbutton effecttool__nextbutton
                    ::before
                    .effecttool__navbutton__title
  */
  /****************/
  /*
  WBDVM progressbar:

  <div class="wbdvmtool__progressholder">
    <div class="wbdvmtool__progressholder__text">Nog maximaal 5 vragen</div>
    <div id="questionsleftbar">
      <div id="questionsleftprogress" style="width: 28.5714%;">
      </div>
    </div>
  </div>

  ToolPlatform Progressbar

  <div id="questionscounter" class="effecttool__progressholder">
    <div id="questionsleft" class="effecttool__progressholder__text"></div>
    <div id="questionsleftbar" class="effecttool__progressholder__bar"><div id="questionsleftprogress" class="effecttool__progressholder__bar__knob"></div></div>
  </div>
  */
  /*
  .effectgroups
    .effectgroups__group
      .effecttool__effect
  */
  /*
  .effecttool__effect
  {
    @include layout--default;
  }
  */
}
.effecttool__root input[type=text],
.effecttool__root input[type=url],
.effecttool__root input[type=email],
.effecttool__root input[type=password],
.effecttool__root input[type=number],
.effecttool__root input[type=date],
.effecttool__root input[type=search],
.effecttool__root textarea {
  border-color: #25A22F; /* - WIG Green-AA */
}
.effecttool__root .effecttool__root {
  padding-bottom: 50px;
}
.effecttool__root #questionscounter#questionscounter .effecttool__progressholder__bar {
  max-width: none;
  box-sizing: border-box;
  height: 24px;
  padding: 0 1px;
  border: 2px solid #13A538;
  border-radius: 10px;
  background-color: #F2F2F2;
  margin-bottom: 24px;
}
.effecttool__root #questionscounter#questionscounter .effecttool__progressholder__bar__inner {
  padding-right: 5%;
  height: 18px;
  border-radius: 9px;
  background: linear-gradient(180deg, #51D161 0%, #0B923E 100%);
  top: 1px;
  bottom: 1px;
}
.effecttool__root .effecttool__question__contents {
  border: 2px solid var(--color-green);
  border-radius: 11px;
  padding: 25px 0 0 0;
}
.effecttool__root .effecttool__questiontext, .effecttool__root .effecttool__questioncontainer,
.effecttool__root .effecttool__navbuttons buttons {
  padding: 0 var(--tool-question-sidepadding);
}
.effecttool__root .effecttool__questiontext + .effecttool__questioncontainer {
  margin-top: 25px;
}
.effecttool__root .effecttool__questiontext {
  color: var(--p3dtool-questiontext-color);
}
.effecttool__root .effecttool__questiontext > *:first-child {
  margin-top: 0;
}
.effecttool__root .effecttool__navbuttons {
  margin-top: 40px;
  height: auto;
  display: flex;
  background-color: rgba(19, 165, 56, 0.08);
  padding: 13px var(--tool-question-sidepadding);
}
.effecttool__root .effecttool__group__title {
  color: var(--tool-effectgroup-title-color);
  font: var(--tool-effectgroup-title-font);
  margin-bottom: 15px;
}
.effecttool__root .effecttool__questioncontainer {
  font: var(--tool-questiontext-font);
}
.effecttool__root .effecttool__questiontext.effecttool__questiontext > * {
  font: var(--tool-questiontext-font);
}
.effecttool__root .effecttool__questiontext.effecttool__questiontext > h2 {
  font: var(--tool-questionheading-font);
  margin-bottom: 17px;
}
.effecttool__root .effecttool__questiontext > *:last-child {
  margin-bottom: 0;
}
.effecttool__root input[type=number].date-day {
  width: 60px;
  margin-right: 12px;
}
.effecttool__root input[type=number].date-month {
  width: 60px;
  margin-right: 12px;
}
.effecttool__root input[type=number].date-year {
  width: 80px;
}
.effecttool__root .effecttool__questioncontainer .date-day,
.effecttool__root .effecttool__questioncontainer .date-month,
.effecttool__root .effecttool__questioncontainer .date-year {
  --textcontrols_padleft: 13px;
}
.effecttool__root .effecttool__questioncontainer .date-month {
  --textcontrols_padright: 10px;
}
.effecttool__root .effecttool__errormessage {
  margin-bottom: 10px;
  color: #AA0000;
}
.effecttool__root .effecttool__radiolist__answer, .effecttool__root .effecttool__checkboxlist__answer {
  display: flex;
  align-items: baseline;
  color: var(--tool-optionlabel-color);
  cursor: pointer;
  padding: 7px 0;
}
.effecttool__root .effecttool__radiolist__answer input,
.effecttool__root .effecttool__checkboxlist__answer input {
  margin: 0;
}
.effecttool__root .effecttool__radiolist__answer + .effecttool__radiolist__answer,
.effecttool__root .effecttool__checkboxlist__answer + .effecttool__checkboxlist__answer {
  margin-top: 0;
}
.effecttool__root .effecttool__radiolist__optionlabel {
  display: block; /* otherwise the padding-left works as text-indent */
  padding-left: var(--tool-optionlabel-spacing);
}
.effecttool__root .effecttool__prevbutton, .effecttool__root .effecttool__nextbutton,
.effecttool__root .effecttool__dialog--p3d-reminder .wh-form__button.wh-form__button--submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.effecttool__root .effecttool__prevbutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  text-align: left;
  color: var(--color-green);
  font: var(--button-font);
  outline-offset: var(--actionbutton-outline-offset);
  cursor: pointer;
  text-underline-offset: 3px;
  font-weight: 400;
}
.effecttool__root .effecttool__prevbutton:hover {
  text-decoration: underline;
}
.effecttool__root .effecttool__prevbutton[disabled] {
  border-color: #A8A8A8;
  color: #A8A8A8;
}
.effecttool__root .effecttool__prevbutton::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-left);
  font-size: 14px;
  margin-right: var(--toolbutton-icon-margin-prev);
  width: auto;
  height: auto;
}
.effecttool__root .effecttool__nextbutton, .effecttool__root .effecttool__dialog--p3d-reminder .wh-form__button.wh-form__button--submit,
.effecttool__root .p3dtool2__givenanswers__restarttool,
.effecttool__root .effecttool__reminder__download.effecttool__button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  background-color: var(--color-green);
  border-radius: 5px;
  text-align: left;
  color: #FFFFFF;
  font: var(--button-font);
  outline-offset: var(--actionbutton-outline-offset);
  cursor: pointer;
  padding-left: 19px;
  padding-right: 14px;
}
.effecttool__root .effecttool__nextbutton[disabled], .effecttool__root .effecttool__dialog--p3d-reminder .wh-form__button.wh-form__button--submit[disabled],
.effecttool__root .p3dtool2__givenanswers__restarttool[disabled],
.effecttool__root .effecttool__reminder__download.effecttool__button[disabled] {
  background-color: #A8A8A8;
}
.effecttool__root a.effecttool__reminder__download.effecttool__button {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.effecttool__root .effecttool__nextbutton::before {
  display: none;
}
.effecttool__root .effecttool__nextbutton::after,
.effecttool__root .effecttool__dialog--p3d-reminder .wh-form__button.wh-form__button--submit::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 14px;
  margin-left: var(--toolbutton-icon-margin-next);
}
.effecttool__root .p3dtool2__givenanswers__restarttool {
  padding-right: 30px;
  margin-top: 25px;
}
.effecttool__root .p3dtool2__givenanswers__restarttool::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f01e";
  font-size: 16px;
  margin-right: 20px;
}

/****************/