.p3dtool2__results__sharetool {
  --component-sharepage-button-size: 36px;
  --component-sharepage-button-symbolsize: 20px;
  --component-sharepage-button-spacing: 11px;
}

.p3dtool2__results__sharetool__buttonbar__sharelabel {
  font: 16px/20px var(--rtd-heading-fontfamily);
  color: var(--color-green);
}

@media (max-width: 550px) {
  .p3dtool2__results__sharetool {
    --component-sharepage-button-size: 28px;
    --component-sharepage-button-symbolsize: 17px;
    --component-sharepage-button-spacing: 8px;
  }
  .p3dtool2__startpage__share {
    font: 20px/26px var(--rtd-heading-fontfamily);
    color: var(--color-green);
  }
  .p3dtool2__startpage__share .sharecomponent__buttonbar {
    margin-top: 17px;
  }
}