.page--search h1 {
  margin-bottom: 15px;
}

.searchpage__searchbar {
  margin-bottom: 30px;
}

.button_search::after {
  flex: none;
}

/* Zoekveld op site zoekpagina */
#consiliosearch {
  height: 47px;
  border: 1px solid #929292;
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
}

#consiliosearch .wh-autocomplete-container {
  width: 100%;
}

#consiliosearch input {
  flex: 1 0 0px;
  background: transparent;
  color: #333333;
  font: 18px/26px Arial;
  padding-left: 15px;
  height: 100%;
  border: 0;
  border-radius: 0;
}

#consiliosearch button {
  margin: 0;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: var(--button-color);
  color: #FFFFFF;
  flex: none;
  border-radius: 0;
}
#consiliosearch button:hover {
  background-color: var(--button-color-hover);
}

#consiliosearch button::before {
  display: none;
}

#consiliosearch button::after {
  font: var(--fontawesome-solid);
  content: var(--fa-search);
  font-size: 20px;
  color: #FFFFFF;
  margin-left: 30px;
}