/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.themepanel__category__title {
  color: #FFFFFF;
}

.themepanel--footer .themepanel__category__title {
  color: #333333;
}

.themepanel__category__title .icon {
  margin-right: 8px;
  margin-left: -4px;
}

.themepanel--footer .icon {
  color: var(--color-green);
}

.themepanel__category ul,
.themepanel__category li {
  list-style: none;
  padding: 0;
  margin: 0;
}

.themepanel__category a {
  display: flex;
  align-items: baseline;
  color: inherit;
  text-decoration: none;
  font: 18px/32px "TypoPRO Dosis", "Dosis", Arial;
}

.themepanel__category a:hover {
  text-decoration: underline;
}

.themepanel__category a::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: var(--fa-chevron-right);
  font-size: 16px;
  margin-right: 10px;
}

/*
.siteheader-themepanelwrapper
{
  // because <body> is flex, absolute positioned top: auto; is 0 instead of the current flow position.
  // To have the .themepanel--header absolute positioned in the correct position,
  // whe use this wrapper as positioning container.
  position: relative;
}
*/
.themepanel--header {
  position: absolute;
  width: 100%;
  background-color: #0A8539;
  background-color: var(--color-green-highcontrast, #0A8539);
  color: #FFFFFF;
  border-top: 2px solid #0DB24C;
}

.themepanel--footer {
  background-color: #F5F5F5;
  color: #333333;
}

.themepanel__content {
  position: relative;
}

.themepanel__content__closebutton {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  position: absolute;
  right: 25px;
  top: 25px;
}

.themepanel__content__closebutton::before {
  background: none;
  width: auto;
  height: auto;
  margin-right: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00d";
  font-size: 20px;
}

.themepanel__content__closebutton::after {
  display: none;
}

.themepanel__content__column--first {
  margin-top: 0 !important;
}

@media (max-width: 599px) {
  .themepanel__content {
    padding: 18px 16px 45px 16px;
  }
  .themepanel__category + .themepanel__category,
  .themepanel__content__column {
    margin-top: 30px;
  }
  .themepanel__content__column:first-child {
    margin-top: 0;
  }
  .themepanel__category__title {
    font: 600 22px/28px "TypoPRO Dosis", "Dosis", Arial;
    margin-bottom: 3px;
  }
  .themepanel__category__title .icon {
    font-size: 25px;
  }
}
@media (min-width: 600px) {
  .themepanel__content {
    padding-top: 25px;
    padding-bottom: 40px;
    width: 100%;
    max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    margin-left: auto;
    margin-right: auto;
  }
  .themepanel__category__title {
    font: 500 24px/31px "TypoPRO Dosis", "Dosis", Arial;
    margin-bottom: 3px;
  }
  .themepanel--footer {
    padding-top: 40px;
    padding-bottom: 45px;
  }
}
@media (min-width: 600px) and (max-width: 899px) {
  .themepanel__content {
    columns: 2;
  }
  .themepanel__category {
    break-inside: avoid-column;
  }
  .themepanel__category + .themepanel__category,
  .themepanel__content__column {
    margin-top: 35px;
  }
  .themepanel__content__column:first-child {
    margin-top: 0;
  }
}
@media (min-width: 900px) {
  .themepanel__content {
    display: flex;
    flex-wrap: wrap;
  }
  .themepanel__category + .themepanel__category {
    margin-top: 35px;
  }
  .themepanel__content__column {
    flex: 1 1 auto;
    max-width: 25%;
  }
}