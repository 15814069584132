/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.filteredoverview__expandedfilters,
dialog.filteredoverview-mobilefilters {
  --filterpage-filtergroup-title-margin: 15px;
  --tagpanel-heading-color: var(--color-purple);
  --tagpanel-heading-font: bold 20px var(--rtd-heading-fontfamily);
  --tagpanel-heading-margin: 5px;
  --tagpanel-option-pad-top: 4px;
  --tagpanel-option-pad-bottom: 4px;
  --tagpanel-option-spacing: 7px;
  --tagpanel-option-font: 15px/19px Arial;
  --tagpanel-checkradio-spacing: 10px;
}
@media (max-width: 767px) {
  .filteredoverview__expandedfilters,
  dialog.filteredoverview-mobilefilters {
    --filterpage-filtergroup-title-margin: 8px;
  }
}

.pageheader__filterbar {
  /*
    grid-column: 1;
    grid-row: 2;
  */
  padding-top: 24px;
  padding-bottom: 24px;
}

.pageheader__filterbar__content {
  width: 100%;
  max-width: calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.header-filterbar__querycontainer {
  position: relative;
}

.header-filterbar__submit {
  flex: none;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  margin: 0;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: var(--button-color);
  color: #FFFFFF;
  align-self: stretch;
}
.header-filterbar__submit:hover {
  background-color: var(--button-color-hover);
}

.header-filterbar__togglefilters.header-filterbar__togglefilters {
  width: auto;
  display: grid;
  color: var(--button-color);
  cursor: pointer;
}

.header-filterbar__togglefilters:focus-visible {
  outline: 3px solid #AAA;
  outline-offset: var(--actionbutton-outline-offset);
}

.header-filterbar__togglefilters > * {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  align-items: center;
}

.header-filterbar__togglefilters__open {
  visibility: visible;
}

.header-filterbar__togglefilters__close {
  visibility: hidden;
}

html.filteredoverview--showfilters .header-filterbar__togglefilters__open {
  visibility: hidden;
}
html.filteredoverview--showfilters .header-filterbar__togglefilters__close {
  visibility: visible;
}

.header-filterbar__togglefilters {
  flex: none;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid var(--button-color);
  color: var(--button-color);
}
.header-filterbar__togglefilters:hover {
  background-image: none;
  background-color: var(--button-color-hover);
  color: #FFFFFF;
}

.header-filterbar__togglefilters > * {
  align-items: center;
}

.header-filterbar__togglefilters__icon {
  position: relative;
  top: 2px;
}

@media (max-width: 549px) {
  .pageheader__filterbar__content {
    display: flex;
    flex-wrap: wrap;
  }
  .header-filterbar__querycontainer {
    display: contents;
  }
  .header-filterbar__query.header-filterbar__query {
    margin-bottom: 10px;
    flex: 1 0 100%;
    height: 44px;
    border: 1px solid #929292;
    background-color: #FFFFFF;
    font: 16px var(--rtd-heading-fontfamily);
    color: #6F6F6F;
  }
  .header-filterbar__query.header-filterbar__query .header-filterbar__submit {
    padding-left: 13px;
  }
  .header-filterbar__submit,
  .header-filterbar__togglefilters {
    flex: 1 0 auto;
    height: 44px;
  }
  .header-filterbar__submit {
    font: 500 18px var(--rtd-heading-fontfamily);
    justify-content: space-between;
  }
  .header-filterbar__submit .fa-search {
    font-size: 20px;
  }
  .header-filterbar__togglefilters {
    font: 500 18px var(--rtd-heading-fontfamily);
    height: 46px;
    cursor: pointer;
  }
  .header-filterbar__togglefilters .far {
    font-size: 20px;
  }
  .header-filterbar__togglefilters__label {
    margin-right: auto;
  }
  .header-filterbar__togglefilters__icon {
    font-size: 18px;
  }
  .header-filterbar__togglefilters {
    display: inline-flex;
    align-items: center;
    appearance: none;
    -webkit-appearance: none;
    padding: 0;
    border: 0;
    background-color: transparent;
    height: var(--button-height);
    padding: 5px 20px 5px 20px;
    font: var(--button-font);
    border-radius: var(--button-borderradius);
    cursor: pointer;
    background-color: #FFFFFF;
    border: 1px solid var(--button-color);
    color: var(--button-color);
    color: var(--color-green);
  }
  .header-filterbar__togglefilters:hover {
    background-image: none;
    background-color: var(--button-color-hover);
    color: #FFFFFF;
  }
  .pageheader__filterbar--default .header-filterbar__togglefilters {
    margin-left: 12px;
  }
  .header-filterbar__query.header-filterbar__query,
  .header-filterbar__submit,
  .header-filterbar__togglefilters {
    border-radius: var(--button-borderradius);
  }
}
@media (min-width: 550px) {
  .header-filterbar__querycontainer {
    flex: 1 0 auto;
    display: flex;
    flex-wrap: wrap;
    background-color: #FFFFFF;
    border: 1px solid #9E9E9E;
    border-radius: 4px;
  }
  .header-filterbar__querycontainer .wh-autocomplete-container {
    width: 100%;
  }
  .filteredoverview__form.suggestionsactive .header-filterbar__querycontainer {
    border-radius: 4px 4px 0 0;
  }
  .header-filterbar__query.header-filterbar__query {
    flex: 1 0 0px;
    padding-left: 15px;
    height: 46px;
    border: 0;
    background-color: transparent;
    font: 18px var(--rtd-text-fontfamily);
    color: #333333;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0px !important;
  }
  .header-filterbar__submit {
    padding-left: 17px;
    padding-right: 17px;
    font: var(--button-font);
    height: 46px;
    border-radius: 0;
    cursor: pointer;
  }
  .header-filterbar__submit .fa-search {
    margin-left: 52px;
  }
  .header-filterbar__togglefilters {
    font: var(--button-font);
    height: 46px;
  }
  .pageheader__filterbar--default .header-filterbar__togglefilters {
    margin-left: 20px;
  }
  .header-filterbar__togglefilters__icon {
    margin-left: 25px;
  }
}