.p3daanmelden__group + .p3daanmelden__group {
  margin-top: 30px;
}

.p3daanmelden__group h2,
.p3d-order-form__productlist h2,
.p3d-order-form__afleveradres h2,
.p3d-order-form__productsummarypage h2 {
  margin-top: 0;
  margin-bottom: 10px;
}
.p3daanmelden__group h3,
.p3d-order-form__productlist h3,
.p3d-order-form__afleveradres h3,
.p3d-order-form__productsummarypage h3 {
  margin-top: 0;
  margin-bottom: 10px;
}

#p3daanmeldform .wh-form__page--visible[data-wh-form-pagerole=thankyou] + .wh-form__navbuttons {
  display: none;
}

.p3dform__checklabel {
  font-weight: bold;
  min-width: 170px;
  padding-right: 10px;
}

#aanmeldformulier_register > p {
  margin-bottom: 10px;
}

#registrationform-organization_logo {
  position: relative;
  width: 100%;
}