.effecttool--isloaded .effecttool__loading {
  display: none;
}

.effecttool--isloaded .effecttool__loaded {
  display: block !important;
}

.effecttool__navbuttons {
  display: flex;
  height: 65px;
}

.effecttool__navbuttons__spacer {
  flex: 1 0 25px;
}

#conceptmode {
  display: none;
  text-align: center;
  margin-bottom: 5px;
  padding: 15px 0;
  color: #000000;
}

body.conceptmode #conceptmode {
  display: block;
}

#questionscontainer {
  position: relative;
  top: auto;
}

/*
.effecttool__page.effecttool__opening
.effecttool__page.effecttool__question
.effecttool__page.effecttool__results
*/
#questionscontainer .effecttool__page {
  z-index: 1;
  overflow: hidden;
  width: 100%;
}

#questionscontainer .effecttool__page .contents {
  padding: 21px 20px;
}

#questionscontainer.resultspage {
  margin-left: 0;
}

#results {
  border-color: transparent;
}

#questionscontainer .question .questioncontainer {
  margin: 17px 0 0 0;
}

#questionscontainer .question .questioncontainer .help {
  font-size: 12px;
  margin: 0 0 20px 0;
}

#questionscontainer .question .questioncontainer .answers {
  display: inline-block;
  /*padding: 4px 7px 4px 4px;*/
  border: 1px solid transparent;
}

#questionscontainer .question .questioncontainer .answers .answer {
  margin: 0 0 5px 0;
}

#questionscontainer .question .questioncontainer .answers label {
  cursor: pointer;
}

#questionscontainer .question .questioncontainer .answers.checkboxlist label {
  position: relative;
  top: 2px;
}

#questionscontainer .question .questioncontainer .error {
  color: #cc0000;
  display: none;
  padding: 0 0 15px 0;
}

#questionscontainer .question.error .questioncontainer .error {
  display: block;
}

#questionscontainer .question .pulldownanswer {
  margin-top: 40px;
}

#questionscontainer .question .questioncontainer input[type=text],
#questionscontainer .question .questioncontainer input[type=number],
#questionscontainer .question .questioncontainer input[type=email],
#questionscontainer .question .questioncontainer input[type=date] {
  border: 1px solid #000000;
  font: 14px Arial, Sans-Serif;
  padding: 5px;
}

#questionscontainer .question.error .questioncontainer input[type=text],
#questionscontainer .question.error .questioncontainer input[type=number],
#questionscontainer .question.error .questioncontainer input[type=email],
#questionscontainer .question.error .questioncontainer input[type=date] {
  border-color: #ff0000 !important;
}

#questionscontainer .question .questioncontainer input[type=number] {
  width: 150px;
}

#questionscontainer .question .questioncontainer input[type=text],
#questionscontainer .question .questioncontainer input[type=email],
#questionscontainer .question .questioncontainer textarea {
  width: 200px;
}

#questionscontainer .question .questioncontainer input[type=radio] {
  margin: 0;
  padding: 0;
}

#questionscontainer .question .questioncontainer input[type=number].date-day,
#questionscontainer .question .questioncontainer input[type=number].date-month {
  width: 50px;
}

#questionscontainer .question .questioncontainer input[type=number].date-year {
  width: 75px;
}