/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.fsslideshow__button > svg,
.fsslideshow__button > svg {
  fill: var(--color-green) !important;
}

.fsslideshow__button:hover > svg {
  fill: #FFFFFF !important;
}

.fsslideshow__title {
  color: var(--color-green) !important;
}