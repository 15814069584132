/*
@mixin wh-form-hidenativecontrol
{
  // display: none or visibility: hidden; will prevent focus!!
  position: absolute !important; // to not mess with flex containers
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  opacity: 0;

  margin: 0;
}

@mixin default-focus-effect
{
  outline: 2px dotted #212121;
  outline-offset: 2px;
  outline: -webkit-focus-ring-color auto 5px;
}
*/
/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
/*
NOTES:
- .effecttool__moreinfo is by default shown at the bottom of the question,
  but we want this change this into an infobutton
*/
/*
pageheader-default.scss

@media (max-width: 879px) {
    .siteheader-menubar + .body__content {
        margin-top: 15px;
    }
}

wijzeringeldzaken.css

main.rtdcontent > *:first-child
{
  margin-top: 15px;
}
*/
@media (max-width: 879px) {
  html.page--p3dtool .siteheader-menubar + .body__content {
    margin-top: 30px !important;
  }
  html.page--p3dtool.page--p3dtool-startpage .siteheader-menubar + .body__content, html.page--p3dtool.p3dtool2--results .siteheader-menubar + .body__content {
    margin-top: 0px !important;
  }
}
@media (min-width: 880px) {
  html.page--p3dtool:not(.page--p3dtool-startpage):not(.p3dtool2--results) .siteheader-menubar + .body__content {
    padding-top: 30px;
  }
}
html.page--p3dtool .effecttool__root {
  margin-top: 0 !important;
}

.effecttool__root input + label:empty {
  display: none;
}