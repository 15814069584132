/*@import "./config.scss";*/
@import url(/.publisher/sd/minfin_wig/shared/assets/fontawesome5/css/all.css);

@import url(/.minfin_wig/fonts/@typopro/web-open-sans/typopro-opensans.css);
@import url(/.minfin_wig/fonts/@typopro/web-dosis/typopro-dosis.css);



html
{
  --tool-text-fontfamily:         Arial, Sans-Serif;
  --tool-headingfontfamily:       Arial, Sans-Serif;

  --tool-emailresultlink-text-font: 14px/20px Arial;


  --tool-themecolor:              #25A22F;
  --tool-themecolor-highcontrast: #218729; /* for small text */
  --tool-themecolor-bright:       #25A22F; /* for the progressbar */
  --tool-themecolor-faded:        #F1F9F1; /*rgba(37, 162, 47, 0.2);  progressbar background */

  --tool-border-color:            var(--tool-themecolor-bright);
  --tool-border-radius:           11px;

  --tool-themecolor-secondary:    #641C76; /* purple */

  /* Panels
     - Mail mij de uitslag
     - Bekijk ingevoerde antwoorden
  */
  --tool-panels-background:       #EBEADA; /*var(--tool-themecolor-faded);*/
  --tool-panels-border-color:     #28A532; /*var(--tool-border-color);*/

  --formcontrols-themecolor:      var(--tool-themecolor);
  --textcontrols-padleft-small:   15px; /* for small inputs such as day/month/year numeric fields */
  --textcontrols-padright-small:   5px; /* for small inputs such as day/month/year numeric fields */

  --tool-textfont-color:  #333333; /* default color for all non-heading text */

  --color-theme-light: #929292; /* for disabled, placeholder, controls border */



  --color-grey-border:     #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */

  --dialog-closebutton-size:              31px;
  --dialog-closebutton-background:        #FFFFFF;
  --dialog-closebutton-border:            2px solid #000000;
  --dialog-closebutton-hover-background:  var(--tool-themecolor);
  --dialog-closebutton-hover-border:      2px solid #000000;
  --dialog-closebutton-hover-color:       #FFFFFF;

  --dialog-background:       #FFFFFF;
  --dialog-border:           1px solid var(--color-grey-border);
  --dialog-border-radius:    8px;
  --dialog-box-shadow:       0 3px 10px rgba(0, 0, 0, 0.35);
  --dialog-padding:          25px;
}

/* .effecttool__root */
  .wbdvmtool
, .wbdvmtool-popup
{
  /* Terug, Volgende knoppen */
  --tool-button-color:                var(--tool-themecolor);
  --tool-button-font-desktop:         500 20px/26px var(--tool-headingfontfamily);
  --tool-button-font-mobile:          500 18px/26px var(--tool-headingfontfamily);

  /* Intro text above the tool (questionpage heading) */
  --tool-introheading-font:           36px/40px var(--tool-headingfontfamily);
  --tool-introheading-font-mobile:    24px/33px var(--tool-headingfontfamily);
  --tool-introheading2-font:          bold 36px/40px var(--tool-headingfontfamily);
  --tool-introheading2-font-mobile:   bold 24px/33px var(--tool-headingfontfamily);
  --tool-introtext-font:              18px/28px var(--tool-headingfontfamily);
  --tool-introtext-font-mobile:       16px/24px var(--tool-headingfontfamily);

  --tool-questionheading-color:       var(--tool-themecolor-secondary);
  --tool-questionheading-font:        bold   26px/33px var(--tool-headingfontfamily);
  --tool-questionheading-font-mobile: bold   24px/28px var(--tool-headingfontfamily);

  --tool-questiontext-font:           normal 16px/24px var(--tool-text-fontfamily);
  --tool-questiontext-color:          var(--tool-textfont-color);

  --tool-infopanel-font:              16px/24px Arial, Sans-Serif;

  --tool-results-h2:                  bold 20px/24px var(--tool-headingfontfamily);
  --tool-results-h2-mobile:           bold 19px/22px ;

  --tool-question-sidepadding: 27px;
  --tool-question-sidepadding-mobile: 15px;

  --tool-question-buttonbar-background: rgba(19,165,56,0.08);
  --tool-question-buttonbar-padding-v:  13px;


  --tool-effectgroup-divider-color:      var(--tool-themecolor);
  --tool-effectgroup-header-hover-color: #F5FFF5;


  --tool-optionlabel-textcolor: #333333;
  --tool-font-reminder-labels: var(--tool-questiontext-font);


  /************************************************************
   *
   *  Form controls
   */

  /* Normal height for single-line controls */
  --controls_height:                44px;
  --controls_height_mobile:         44px;
  --controls_radius:                 4px;
  --controls-textcolor-error:        #EE0000; /* lightest red which passes accessibility tests (4.53:1 contrast on white) */
  --controls_outline_color:          var(--color-theme-light);
  --controls_outline_color-disabled: #BFBFBF;
  --controls_outline_color-error:    #CD202C;
  --controls_outline_color-focus:    #222222;
  --controls-backgroundcolor-error:  #FFEAEA;

  --textcontrols_focus_bordercolor: #0067B4;
  --controls_text_disabledcolor:    #000;

  --textcontrols_placeholdercolor:     var(--color-theme-light);
  --textcontrols_textcolor:            #282828;

  --textcontrols_disabled_bgcolor:     rgba(255,255,255,0.6);
  --textcontrols_disabled_bordercolor: #C5C5C5;
  --textcontrols_disabled_placeholder: inherit;
  --textcontrols_disabled_textcolor:   #282828;

  --textcontrols_padleft:         15px;
  --textcontrols_padright:        15px;




  /************************************************************
   *
   *  Generic components
   */


  /* Page share bar */
  --component-sharepage-button-background:  linear-gradient(180deg, #FFFFFF 0%, #E7F7ED 100%);
  --component-sharepage-button-color:       #28A532;
  /*--component-sharepage-heading-font:       20px/26px var(--fontfamily-heading);*/
  --component-sharepage-button-symbolsize:  20px;
  --component-sharepage-button-size:        36px;


  /************************************************************
   *
   *  Tool specific settings
   */

  --tool-anchor-color:  var(--tool-themecolor-highcontrast);

  --tool-heading-color: #641C76;

  --tool-question-sidepadding: 27px;
  --tool-panel-whitespace-top: 25px;

  --tool-button-icon-margin-prev: 15px;
  --tool-button-icon-margin-next: 25px;

  --tool-moreinfo-padding-h: 15px;
  --tool-moreinfo-padding-v: 15px;

  --tool-results-panel-padding-h: 18px;
  --tool-results-panel-padding-v: 18px;

  --tool-text-font:               16px/23px Arial;

  --tool-effectgroup-icon-size:   60px;
  --tool-effectgroup-icon-margin: 20px;
  --tool-effectgroup-title-font:  bold 20px/24px var(--tool-headingfontfamily);
  --tool-effectgroup-title-color: var(--tool-themecolor);
  --tool-effectgroup-text-color:  #333333;
  --tool-effectgroup-text-font:   16px/23px Arial;

  --tool-tooltip-font:            var(--tool-effectgroup-text-font);
}


@media (max-width: 480px)
{
  --textcontrols_padleft:  10px;
  --textcontrols_padright: 10px;
}


/* mobile */
@media (max-width: 640px)
{
  .wbdvmtool
  {
    --component-sharepage-button-size:        27px;
    --component-sharepage-button-symbolsize:  16px;


    --tool-question-sidepadding: 18px;

    --tool-button-icon-margin-prev: 13px;
    --tool-button-icon-margin-next: 20px;

    --tool-effectgroup-icon-size:   40px;
    --tool-effectgroup-icon-margin:  6px;
    --tool-effectgroup-title-font:  bold 19px/22px "TypoPRO Dosis", Arial;
  }
}


  .wbdvmtool-rtdcontent * + p.normal
, .wbdvmtool-rtdcontent * + ul.unordered
, .wbdvmtool-rtdcontent * + ol.ordered
, .wbdvmtool-rtdcontent * + h4.heading4
{
  margin-top: 10px;
  margin-bottom: 0;
}

.wbdvmtool-rtdcontent * + h4.heading4
{
  margin-top: 15px;
}

.wbdvmtool-reminderbutton /* .wbdvmtool__button */
{
  margin-top: 15px;
}
